.edit-area .content-static-body {
    top: 113px;
}

#editor-toolbar {
    background-color: #fff;
    height: 40px;
    border-bottom: 1px solid $border-color;
    position: fixed;
    top: 73px;
    left: 0;
    right: 0;
    padding-left: 0.5rem;

    .tox-tinymce {
        border-radius: 0 !important;
    }

    .tox.tox-tinymce-inline .tox-editor-header {
        border: 0 !important;
        border-radius: 0 !important;
    }

    #editor-toolbar-placeholder {
        background-image: url('../../images/toolbar.png');
        background-repeat: no-repeat;
        background-position: 24px center;
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .tox {
        .tox-dialog {
            .tox-dialog__body-content {
                max-height: calc(100vh - 110px);
            }
        }

        .tox-collection__item--active {
            .tox-collection__item-label {
                p,
                h3,
                h4 {
                    color: #ffffff !important;
                    outline: none !important;
                }
            }
        }

        .tox-collection__item-label {
            h3,
            h4 {
                font-size: 14px !important;
                font-weight: 400 !important;
                color: #666 !important;
            }
        }

        .tox-checkbox {
            height: 32px;
            min-height: 32px;

            .tox-checkbox__label {
                font-size: 14px !important;
            }
        }
    }
}

.editor-right-side {
    .tooltip-text {
        font-size: 0.75rem;
        padding: 0.1rem 0.25rem;
        margin-left: 0.15rem;
        background-color: $gray-300;
    }

    .answer {
        font-weight: 600 !important;
        margin-bottom: 0 !important;
    }
}

#editPublicationForm {
    .mce-content-body {
        height: 120px;
        overflow: hidden;
        margin-top: 5px;
        margin-bottom: 0;
    }
}

#baseTextWindow {
    padding-right: 3px;

    > .extra-toolbar-space {
        margin-right: 39px; // offset for comment icon
    }
}

.mce-content-body {
    > *:last-child {
        margin-bottom: 0 !important;
    }

    .font-weight-bold {
        font-weight: 700 !important;
    }

    .toelichting {
        margin-bottom: 4px;
    }

    p,
    ul,
    ol {
        margin-bottom: 9px;
    }

    h3,
    h4,
    p {
        &.margin-bottom {
            padding-bottom: 3px;
            border-bottom: 10pt solid #ffecec;
        }

        &.margin-top {
            padding-top: 3px;
            border-top: 10pt solid #ffecec;
        }
    }

    .answer {
        font-weight: 700 !important;
        margin-bottom: 0;
    }

    &.mce-edit-focus {
        overflow-y: auto;
        max-height: unset !important;
    }
}

.textarea {
    .area-text-block {
        padding: 0.6rem 0.9rem;
        background-color: $white;
        border-color: #eaecef;
        border-radius: 4px;
        min-height: 4rem;
        transition: border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;

        &:not(.is-deleted) {
            margin-top: unset;
        }
    }

    .area-text-block-no-editor {
        .mce-content-body:not(.mce-edit-focus):hover {
            cursor: text;
        }

        &:hover {
            cursor: text;
            border-color: #ced4da;
        }
    }

    .basic-editor-text-block {
        margin-bottom: 0;
    }

    .mce-content-body {
        * {
            font-family: $font-family-base;
            font-size: $font-size-sm;
            line-height: 1.8;
        }
    }

    .mce-edit-focus {
        min-height: 42px;

        .answer {
            font-weight: 700 !important;
            margin-bottom: 0;
        }
    }
}

.area-block {
    .block-edit-buttons {
        display: none;
    }

    .block-edit-labels {
        display: inline-flex;
    }

    &:hover {
        .block-edit-buttons {
            display: inline-flex;
        }

        .block-edit-labels {
            display: none;
        }
    }
}

.diff-content {
    line-height: 1.8;

    & > ins,
    & > del {
        display: inline;
        padding: 0 !important;

        > p {
            display: inline;
        }
    }

    ins,
    del {
        * {
            padding: 2px;
            font-weight: 500;
            background-color: unset;
            color: unset;
        }
    }

    ins,
    ins > * {
        padding: 2px;
        font-weight: 500;
        background-color: #ecfdf3;
        color: #027a48;
    }

    del,
    del > * {
        padding: 2px;
        font-weight: 500;
        background-color: #f9ecef;
        color: #bb1047;
    }
}

.docrev-editor {
    border: 1px solid $input-border-color;
    padding: 1rem;
    border-radius: $input-border-radius;
    color: $input-color;

    div.DraftEditor-root {
        min-height: 100px;
        max-height: 330px;
        overflow-y: auto;
    }

    .public-DraftStyleDefault-block {
        margin: 0 0 1em 0;
    }

    div[data-block='true']:last-of-type {
        .public-DraftStyleDefault-block {
            margin-bottom: 0;
        }
    }

    * {
        letter-spacing: 0;
    }

    div.DraftEditor-editorContainer,
    div.public-DraftEditor-content {
        height: 100%;
    }

    &.has-focus {
        border-color: darken($lightBlueDenseAlt, 10%);
    }

    &:hover:not(.has-focus) {
        border-color: darken($lightBlueDenseAlt, 5%);
    }

    &:hover {
        cursor: text;
    }
}

.rdw-editor-toolbar {
    color: #000;

    .rdw-link-modal-label {
        margin-bottom: 0;
    }
}

.rdw-link-modal {
    font-size: 14px;
    width: 267px;
    height: 237px;
}

.rdw-colorpicker-modal {
    font-size: 14px;
    width: 242px;
    height: 207px;
}

.content-attachment {
    > *:last-child {
        margin-bottom: 0 !important;
    }
}
