// Type
.uk-font-weight-300 {
    &,
    & * {
        font-weight: 300;
    }
}

.uk-font-weight-400 {
    &,
    & * {
        font-weight: 400;
    }
}

.uk-font-weight-600 {
    &,
    & * {
        font-weight: 600;
    }
}

.uk-font-weight-700 {
    &,
    & * {
        font-weight: 700;
    }
}

.uk-text-xsmall {
    &,
    & * {
        font-size: @font-size-xsmall;
    }
}

.uk-text-normal {
    &,
    & * {
        font-size: @font-size-default;
    }
}

.uk-text-medium {
    &,
    & * {
        font-size: (1.2 * @font-size-default);
    }
}

.uk-text-large {
    &,
    & * {
        font-size: 1.5rem;
        line-height: 1.5;
    }
}

.uk-text-xlarge {
    &,
    & * {
        font-size: (2.2 * @font-size-default);
    }
}

.uk-text-xxlarge {
    &,
    & * {
        font-size: (2.7 * @font-size-default);
    }
}

.uk-link-underlined {
    text-decoration: underline;
}

.uk-link:hover {
    text-decoration: underline;
}

.uk-text-capitalize-first {
    display: inline-block;

    &::first-letter {
        text-transform: capitalize;
    }
}

// Borders
.uk-border {
    border: 1px solid @text-color;
}

.uk-border-top {
    border-top: 1px solid @text-color;
}

.uk-border-right {
    border-right: 1px solid @text-color;
}

.uk-border-bottom {
    border-bottom: 1px solid @text-color;
}

.uk-border-left {
    border-left: 1px solid @text-color;
}

.uk-border-2x {
    border-width: 2px;
}

.uk-border-4x {
    border-width: 4px;
}

.uk-border-10x {
    border-width: 10px;
}

// Buttons
.uk-button {
    &:not[class^='uk-button-large'] {
        padding-left: 20px;
        padding-right: 20px;
    }

    padding: 0 16px;
    line-height: 35px;
    border-radius: @border-radius;
    background-color: @theme-color;
    border: 1px solid @theme-color;
    .uk-text-color-default;
    text-transform: none;
    font-weight: 400;
    white-space: nowrap;
    .default-transition();
    transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
    box-shadow: 0 1px 0 rgba(27, 31, 35, 0.1), inset 0 1px 0 hsla(0, 0%, 100%, 0.08);

    &:hover,
    &:focus {
        transition-duration: 0.1s;
        background-color: darken(@theme-color, 10%);
        border-color: darken(@theme-color, 10%);
        .uk-text-color-default;
    }

    &.uk-button-line {
        border: 1px solid @theme-color;
    }

    &.uk-disabled {
        border: 1px solid @theme-light-color;
        color: @theme-dark-color !important;
        background-color: @theme-light-color;
    }
}

.uk-button-small {
    padding-left: 5px;
    padding-right: 5px;
    line-height: 24px;
    border-radius: 0;
}

.uk-button-wide {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
    line-height: 2em;
}

.uk-icon-image {
    color: inherit;
}

.uk-button {
    .uk-icon-image {
        width: 0.875rem;
        height: 0.875rem;
    }
}

.uk-button-large {
    border-radius: 0;
    line-height: 46px;
}

.uk-icon-image-large,
.uk-button-large .uk-icon-image,
.uk-button-wide .uk-icon-image {
    width: 35px;
    height: 35px;
}

.uk-button-wide .uk-icon-image {
    margin-bottom: 10px;
}

.uk-button-default {
    & {
        background-color: @primary-light-color;
        border-color: @primary-light-color;
        .uk-text-white;

        &.uk-button-line {
            background-color: @theme-color;
            border-color: @primary-color;
            .uk-text-link;
        }
    }

    &:hover,
    &:focus {
        background-color: @primary-color;
        border-color: @primary-color;
        .uk-text-white;

        &.uk-button-line {
            background-color: @theme-dark-color;
            border-color: @primary-dark-color;
            .uk-text-link;
        }
    }
}

.uk-button-primary {
    & {
        background-color: @primary-color;
        border-color: @primary-color;
        .uk-text-white;

        &.uk-button-line {
            background-color: @theme-color;
            border-color: @primary-color;
            .uk-text-primary-dark;
        }
    }

    &:hover,
    &:focus {
        background-color: darken(@primary-color, 5%);
        border-color: darken(@primary-color, 5%);
        .uk-text-white;

        &.uk-button-line {
            background-color: @theme-color;
            border-color: darken(@primary-color, 10%);
            .uk-text-primary-dark;
        }
    }
}

.uk-button-success {
    & {
        background-color: @success-color;
        border-color: @success-color;
        .uk-text-white;

        &.uk-button-line {
            background-color: @theme-color;
            border-color: @success-color;
            .uk-text-success;
        }
    }

    &:hover,
    &:focus {
        background-color: darken(@success-color, 7%);
        border-color: darken(@success-color, 7%);
        .uk-text-white;

        &.uk-button-line {
            background-color: darken(@theme-color, 10%);
            border-color: darken(@success-color, 10%);
            .uk-text-success;
        }
    }
}

.uk-button-danger {
    & {
        background-color: @danger-color;
        border-color: @danger-color;
        .uk-text-white;

        &.uk-button-line {
            background-color: @theme-color;
            border-color: @danger-color;
            .uk-text-danger;
        }
    }

    &:hover,
    &:focus {
        background-color: darken(@danger-color, 7%);
        border-color: darken(@danger-color, 7%);
        .uk-text-white;

        &.uk-button-line {
            background-color: darken(@theme-color, 10%);
            border-color: darken(@danger-color, 10%);
            .uk-text-danger;
        }
    }
}

.uk-button-muted {
    & {
        background-color: @muted-background-color;
        border-color: @muted-background-color;
        color: @muted-color !important;

        &.uk-button-line {
            background-color: @theme-color;
            border-color: @muted-background-color;
            color: @muted-color !important;
        }
    }

    &:hover,
    &:focus {
        background-color: darken(@muted-background-color, 5%);
        border-color: darken(@muted-background-color, 5%);
        color: @muted-color !important;

        &.uk-button-line {
            background-color: darken(@theme-color, 10%);
            border-color: darken(@muted-background-color, 15%);
            color: @muted-color !important;
        }
    }
}

.uk-button-light {
    background-color: #fafbfc;
    border-color: rgba(27, 31, 35, 0.15);
    color: #24292e;

    &:hover,
    &:focus {
        background-color: #f3f4f6;
        border-color: rgba(27, 31, 35, 0.15);
    }
}

.uk-button:disabled,
.uk-button-danger:disabled,
.uk-button-default:disabled,
.uk-button-primary:disabled,
.uk-button-secondary:disabled,
.uk-button-muted:disabled {
    &,
    &:hover {
        background-color: transparent;
        border: 1px solid #e5e5e5;
        .uk-text-disabled;
    }
}

.uk-button-width-small {
    padding-left: 10px;
    padding-right: 10px;
}

.uk-button-inline-group {
    .uk-button {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }

    .uk-input {
        border-left: none;
    }
}

.uk-button-group .uk-button {
    border-radius: 0;
}

.uk-button-group > .uk-button {
    border-bottom-left-radius: @border-radius;
    border-top-left-radius: @border-radius;
}

.uk-button-group > .uk-inline > .uk-button {
    border-bottom-right-radius: @border-radius;
    border-top-right-radius: @border-radius;
}

.uk-button-group > .uk-button:not(:first-child),
.uk-button-group > div:not(:first-child) .uk-button {
    margin-left: 1px;
    position: relative;

    &:after {
        content: '';
        display: block;
        top: 0;
        left: -1px;
        width: 1px;
        height: 100%;
        background-color: #fff;
    }
}

.uk-button-group-small {
    .uk-button-small {
        padding-left: 8px;
        padding-right: 8px;

        .uk-icon {
            margin-bottom: 4px;
        }
    }

    .uk-button-icon {
        padding-left: 6px;
        padding-right: 6px;
    }
}

.uk-button[uk-icon],
.uk-button-icon {
    background-color: transparent;
    border: none !important;
    vertical-align: middle;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 7px;
    color: #8e8e92 !important;
    border-radius: 0 !important;
    box-shadow: none;

    &:hover {
        background-color: transparent;
        color: darken(#8e8e92, 25%) !important;
    }

    &:focus {
        background-color: transparent !important;
    }
}

.uk-button:hover[uk-icon] {
    color: darken(#8e8e92, 25%) !important;
}

.uk-button.uk-text-danger[uk-icon] {
    color: #dc3545 !important;
}

.uk-button.uk-text-danger[uk-icon]:hover {
    color: darken(@danger-color, 10%) !important;
}

// Navbar & navbar
.uk-navbar-item,
.uk-navbar-nav > li > a,
.uk-navbar-toggle {
    min-height: 50px;
}

.uk-nav > li > a {
    margin-bottom: @gutter-width-small;
}

.uk-nav-contrast {
    .uk-navbar-item,
    & > li > a,
    .uk-navbar-toggle {
        color: #fff;

        &:hover {
            color: #fff;
        }

        &:hover,
        &.active {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                display: block;
                top: 0;
                left: -40px;
                width: 5px;
                height: 100%;
                background-color: #fff;
            }
        }
    }
}

.uk-navbar-contrast .uk-navbar-nav > li {
    & > a {
        color: #fff;
    }

    &:hover > a,
    & > a.uk-open,
    & > a:focus {
        color: #fff;
        background-color: @primary-dark-color;
    }

    &.uk-active > a,
    & > a:active {
        &,
        &:hover {
            color: #fff;
            background-color: @primary-dark-color;
        }
    }
}

.uk-navbar-item,
.uk-navbar-toggle {
    color: #fff;

    &:hover,
    &:focus {
        color: #fff;
        background-color: @primary-dark-color;
        text-decoration: none;
    }
}

.uk-navbar-dropdown {
    width: 250px;
    padding: 0;
}

.uk-navbar-dropdown,
.uk-dropdown-nav {
    & > li > a {
        margin-bottom: 0;
        padding: 8px 20px;
        color: @link-color;

        &:hover,
        &:focus {
            background-color: @theme-extra-light;
            color: @link-dark-color;
        }
    }

    & > li.uk-active > a {
        background-color: @primary-extra-light-color;
        color: @link-dark-color;
    }
}

.uk-navbar-dropdown-nav > li {
    & > a {
        margin-bottom: 0;
        padding: 5px 20px;
        color: @link-color;
    }

    & > a:hover,
    & > a:focus,
    &.uk-active > a {
        //font-weight: 600;
        background-color: @theme-light-color;
        color: @link-color;
    }
}

.uk-nav-header {
    padding: 5px 20px;
    font-weight: 400;
}

.uk-nav-divider + .uk-nav-header:not(:first-child) {
    margin-top: 5px;
}

.uk-navbar-small .uk-navbar-item,
.uk-navbar-small .uk-navbar-nav > li > a,
.uk-navbar-small .uk-navbar-toggle {
    min-height: 40px;
}

.uk-dropdown-nav .uk-comment {
    .uk-comment-header {
        margin-bottom: 0;
    }

    .uk-comment-title {
        margin-bottom: 0;
        font-size: 1rem;
        line-height: 1;
    }

    .uk-subnav > * {
        padding-left: 0;
        padding-right: 20px;
    }
}

// Dropdowns
.uk-dropdown {
    padding: 0;
    border: 1px solid #f3f3f3;
    border-radius: @border-radius;
    box-shadow: 0 8px 24px rgba(149, 157, 165, 0.2);
}

// Forms
.uk-legend {
    margin-bottom: 25px;
    font-weight: 400;
}

.uk-input,
.uk-select {
    height: 37px !important;
}

.uk-input,
.uk-select,
.uk-textarea {
    border-radius: 0;
    font-size: 0.875rem;

    &:not[disabled]:hover {
        border-color: lighten(@highlight-color, 15%);
    }

    &:focus {
        border-color: @highlight-color;
    }
}

textarea {
    &.uk-input,
    &.uk-textarea {
        resize: none;
        line-height: inherit;
    }
}

.uk-checkbox:focus,
.uk-radio:focus {
    .uk-border-link;
}

.uk-checkbox:checked,
.uk-checkbox:indeterminate,
.uk-radio:checked {
    &:not(:disabled),
    &:not(:disabled):focus {
        .uk-background-link;
    }
}

.uk-form-label {
    text-transform: uppercase;
    color: #255478;
    font-weight: 600;
    margin-bottom: 0;
}

.uk-form-width-date {
    width: 150px;
}

.uk-form-width-xlarge {
    width: 850px;
    max-width: 85%;
}

.uk-form-text-small-inline {
    display: inline;
    height: 30px;
    line-height: 30px;
}

.uk-form-text-small-inline-block {
    display: inline-block;
    height: 30px;
    line-height: 30px;
}

.uk-form-text-inline {
    display: inline;
    height: 40px;
    line-height: 40px;
}

.uk-form-text-inline-block {
    display: inline-block;
    height: 40px;
    line-height: 40px;
}

.uk-form-icon.uk-form-icon-flip {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.uk-form-danger {
    &.uk-input:disabled,
    &.uk-select:disabled,
    &.uk-textarea:disabled {
        background-color: @danger-background-color;
        border-color: @danger-color;
    }
}

// Table
.uk-table {
    margin-bottom: 10px;
}

.uk-table th,
.uk-table td {
    padding: 12px 10px;
}

.uk-table-small td,
.uk-table-small th {
    padding: 6px 8px;
    font-size: 0.75rem;

    address,
    dl,
    fieldset,
    figure,
    ol,
    p,
    pre,
    ul {
        margin-bottom: @gutter-width-small;
    }
}

.uk-table-striped tbody tr:last-of-type,
.uk-table-striped > tr:last-of-type {
    border-bottom: 1px solid #e5e5e5;
}

.uk-table td > :last-child:last-child {
    margin-bottom: 0;
}

// Card
.uk-card-muted {
    background-color: @muted-background-color;
    border: 2px solid darken(@muted-background-color, 15%);
    color: @muted-color !important;
}

.uk-card-danger {
    background-color: @danger-background-color;
    border: 2px solid darken(@danger-background-color, 10%);
    color: @danger-color !important;
}

.uk-card-primary.uk-card-body,
.uk-card-primary > :not([class*='uk-card-media']),
.uk-card-secondary.uk-card-body,
.uk-card-secondary > :not([class*='uk-card-media']),
.uk-light,
.uk-offcanvas-bar,
.uk-overlay-primary,
.uk-section-primary:not(.uk-preserve-color),
.uk-section-secondary:not(.uk-preserve-color),
.uk-tile-primary:not(.uk-preserve-color),
.uk-tile-secondary:not(.uk-preserve-color) {
    color: #ffffff;
}

// Alert
.uk-alert-success {
    background-color: @success-background-color;
    color: @success-color;
}

.uk-alert-primary {
    color: @primary-color;
}

// Utility & misc
.uk-display-inline-flex {
    display: inline-flex;
}

.uk-cursor-pointer {
    cursor: pointer;
}

.uk-cursor-default {
    cursor: default !important;
}

.uk-cursor-initial {
    cursor: initial;
}

.uk-height-xlarge {
    height: 650px;
}

.uk-overflow-x-hidden {
    overflow-x: hidden;
}

.uk-overflow-x-auto {
    overflow-x: auto;
}

.uk-overflow-x-scroll {
    overflow-x: scroll;
}

.uk-overflow-x-visible {
    overflow-x: visible;
}

.uk-overflow-y-hidden {
    overflow-y: hidden;
}

.uk-overflow-y-auto {
    overflow-y: auto;
}

.uk-overflow-y-scroll {
    overflow-y: scroll;
}

.uk-overflow-y-visible {
    overflow-y: visible;
}

.uk-vertical-align-initial {
    vertical-align: initial;
}

.uk-divider-vertical {
    display: inline;
    width: 1px;
    height: 100px;
    margin-bottom: 0;
    margin-left: 20px;
    margin-right: 20px;
    border-left: 1px solid #e5e5e5;
}

// Width
.uk-width-20-fixed {
    width: @gutter-width;
}

.uk-width-90-fixed {
    width: 90px;
}

.uk-width-280-fixed {
    width: 280px;
}

.uk-width-850-fixed {
    width: 850px;
}

.uk-width-968-fixed {
    width: 968px;
}

.uk-width-1-10 {
    width: 10%;
}

.uk-width-3-10 {
    width: 30%;
}

.uk-width-7-10 {
    width: 70%;
}

.uk-width-9-10 {
    width: 90%;
}

// Colors
.uk-background-theme,
.uk-background-theme-hover:hover {
    background-color: @theme-color;
}

.uk-background-theme-light,
.uk-background-theme-light-hover:hover,
.uk-background-hover-parent:hover .uk-background-theme-light-child {
    background-color: @theme-light-color !important;
}

.uk-background-theme-dark,
.uk-background-theme-dark-hover:hover,
.uk-background-hover-parent:hover .uk-background-theme-dark-child {
    background-color: @theme-dark-color;
}

.uk-background-link,
.uk-background-link-hover:hover {
    background-color: @link-color;
}

.uk-background-active,
.uk-background-active-hover:hover {
    background-color: @highlight-color;
}

.uk-background-primary,
.uk-background-primary-hover:hover {
    background-color: @primary-color;
}

.uk-background-primary-extra-light,
.uk-background-primary-extra-light-hover:hover,
.uk-background-hover-parent:hover .uk-background-primary-extra-light-child {
    background-color: @primary-extra-light-color;
}

.uk-background-primary-light,
.uk-background-primary-light-hover:hover,
.uk-background-hover-parent:hover .uk-background-primary-light-child {
    background-color: @primary-light-color;
}

.uk-background-primary-dark,
.uk-background-primary-dark-hover:hover {
    background-color: @primary-dark-color;
}

.uk-background-success,
.uk-background-success-hover:hover {
    background-color: @success-color;
}

.uk-background-success-light,
.uk-background-success-light-hover:hover {
    background-color: @success-background-color;
}

.uk-background-warning,
.uk-background-warning-hover:hover {
    background-color: @warning-color;
}

.uk-background-warning-light,
.uk-background-warning-light-hover:hover {
    background-color: @warning-background-color;
}

.uk-background-danger,
.uk-background-danger-hover:hover {
    background-color: @danger-color;
}

.uk-background-danger-light,
.uk-background-danger-light-hover:hover {
    background-color: @danger-light-color;
}

.uk-background-special,
.uk-background-special-hover:hover {
    background-color: @special-color;
}

.uk-background-white,
.uk-background-white-hover:hover {
    background-color: #fff;
}

.uk-background-light,
.uk-background-light-hover:hover {
    background-color: @muted-color;
}

.uk-background-neutral,
.uk-background-neutral-hover:hover {
    background-color: @neutral-color;
}

.uk-background-dark,
.uk-background-dark-hover:hover {
    background-color: @dark-color;
}

.uk-border-theme,
.uk-border-theme-hover:hover {
    border-color: @theme-color;
}

.uk-border-theme-light,
.uk-border-theme-light-hover:hover {
    border-color: #e1e4e8;
}

.uk-border-theme-dark,
.uk-border-theme-dark-hover:hover {
    border-color: @theme-dark-color;
}

.uk-border-link,
.uk-border-link-hover:hover {
    border-color: @link-color;
}

.uk-border-active,
.uk-border-active-hover:hover {
    border-color: @highlight-color;
}

.uk-border-primary,
.uk-border-primary-hover:hover {
    border-color: @primary-color;
}

.uk-border-primary-light,
.uk-border-primary-light-hover:hover {
    border-color: @primary-light-color;
}

.uk-border-primary-dark,
.uk-border-primary-dark-hover:hover {
    border-color: @primary-dark-color;
}

.uk-border-success,
.uk-border-success-hover:hover {
    border-color: @success-color;
}

.uk-border-warning,
.uk-border-warning-hover:hover {
    border-color: @warning-color;
}

.uk-border-danger,
.uk-border-danger-hover:hover {
    border-color: @danger-color;
}

.uk-border-danger-light,
.uk-border-danger-light-hover:hover {
    border-color: @danger-light-color;
}

.uk-border-white,
.uk-border-white-hover:hover {
    border-color: #fff;
}

.uk-border-dark,
.uk-border-dark-hover:hover {
    border-color: @dark-color;
}

.uk-border-muted,
.uk-border-muted-hover:hover {
    border-color: @muted-color;
}

.uk-text-color-default,
.uk-text-color-default-hover:hover {
    .color-text-and-icon(@text-color);
}

.uk-text-light,
.uk-text-light-hover:hover {
    .color-text-and-icon(@text-light-color);
}

.uk-text-theme,
.uk-text-theme-hover:hover {
    .color-text-and-icon(@theme-color);
}

.uk-text-theme-light,
.uk-text-theme-light-hover:hover {
    .color-text-and-icon(@theme-light-color);
}

.uk-text-theme-extra-light,
.uk-text-theme-extra-light-hover:hover {
    .color-text-and-icon(@theme-extra-light-color);
}

.uk-text-theme-dark,
.uk-text-theme-dark-hover:hover {
    .color-text-and-icon(@theme-dark-color);
}

.uk-text-link,
.uk-text-link-hover:hover,
.uk-link,
.uk-link-hover:hover {
    .color-text-and-icon(@text-color);
}

.uk-text-active,
.uk-text-active-hover:hover {
    .color-text-and-icon(@highlight-color);
}

.uk-text-primary,
.uk-text-primary-hover:hover {
    .color-text-and-icon(@text-color);
}

.uk-text-primary-light,
.uk-text-primary-light-hover:hover {
    .color-text-and-icon(@primary-light-color);
}

.uk-text-primary-dark,
.uk-text-primary-dark-hover:hover {
    .color-text-and-icon(@primary-dark-color);
}

.uk-text-success,
.uk-text-success-hover:hover {
    .color-text-and-icon(@success-color);
}

.uk-text-warning,
.uk-text-warning-hover:hover {
    .color-text-and-icon(@warning-color);
}

.uk-text-danger,
.uk-text-danger-hover:hover {
    .color-text-and-icon(@danger-color);
}

.uk-text-dark,
.uk-text-dark-hover:hover {
    .color-text-and-icon(@dark-color);
}

.uk-text-white,
.uk-text-white-hover-hover:hover {
    .color-text-and-icon(#fff);
}

.uk-text-disabled,
.uk-text-disabled-hover:hover {
    .color-text-and-icon(#999);
}

// Margins and paddings
.uk-margin-xsmall {
    margin: @gutter-width-xsmall;
}

.uk-margin-xsmall-top {
    margin-top: @gutter-width-xsmall;
}

.uk-margin-xsmall-right {
    margin-right: @gutter-width-xsmall;
}

.uk-margin-xsmall-bottom {
    margin-bottom: @gutter-width-xsmall;
}

.uk-margin-xsmall-left {
    margin-left: @gutter-width-xsmall;
}

.uk-margin-xsmall-vertical {
    margin-top: @gutter-width-xsmall;
    margin-bottom: @gutter-width-xsmall;
}

.uk-margin-xsmall-horizontal {
    margin-left: @gutter-width-xsmall;
    margin-right: @gutter-width-xsmall;
}

.uk-margin-small-horizontal {
    margin-left: @gutter-width-small;
    margin-right: @gutter-width-small;
}

.uk-margin-small-vertical {
    margin-top: @gutter-width-small;
    margin-bottom: @gutter-width-small;
}

.uk-margin-xlarge {
    margin: @gutter-width-xlarge;
}

.uk-margin-xlarge-top {
    margin-top: @gutter-width-xlarge;
}

.uk-margin-xlarge-right {
    margin-right: @gutter-width-xlarge;
}

.uk-margin-xlarge-bottom {
    margin-bottom: @gutter-width-xlarge;
}

.uk-margin-xlarge-left {
    margin-left: @gutter-width-xlarge;
}

.uk-margin-xlarge-horizontal {
    margin-left: @gutter-width-xlarge;
    margin-right: @gutter-width-xlarge;
}

.uk-margin-xlarge-vertical {
    margin-top: @gutter-width-xlarge;
    margin-bottom: @gutter-width-xlarge;
}

.uk-padding-xsmall {
    padding: @gutter-width-xsmall;
}

.uk-padding-xsmall-top {
    padding-top: @gutter-width-xsmall;
}

.uk-padding-xsmall-right {
    padding-right: @gutter-width-xsmall;
}

.uk-padding-xsmall-bottom {
    padding-bottom: @gutter-width-xsmall;
}

.uk-padding-xsmall-left {
    padding-left: @gutter-width-xsmall;
}

.uk-padding-xsmall-vertical {
    padding-top: @gutter-width-xsmall;
    padding-bottom: @gutter-width-xsmall;
}

.uk-padding-xsmall-horizontal {
    padding-left: @gutter-width-xsmall;
    padding-right: @gutter-width-xsmall;
}

.uk-padding-small-top {
    padding-top: @gutter-width-small;
}

.uk-padding-small-right {
    padding-right: @gutter-width-small;
}

.uk-padding-small-bottom {
    padding-bottom: @gutter-width-small;
}

.uk-padding-small-left {
    padding-left: @gutter-width-small;
}

.uk-padding-small-vertical {
    padding-top: @gutter-width-small;
    padding-bottom: @gutter-width-small;
}

.uk-padding-small-horizontal {
    padding-left: @gutter-width-small;
    padding-right: @gutter-width-small;
}

.uk-padding-top {
    padding-top: @gutter-width;
}

.uk-padding-right {
    padding-right: @gutter-width;
}

.uk-padding-bottom {
    padding-bottom: @gutter-width;
}

.uk-padding-left {
    padding-left: @gutter-width;
}

.uk-padding-vertical {
    padding-top: @gutter-width;
    padding-bottom: @gutter-width;
}

.uk-padding-horizontal {
    padding-left: @gutter-width;
    padding-right: @gutter-width;
}

.uk-padding-medium {
    padding: @gutter-width-medium;
}

.uk-padding-medium-top {
    padding-top: @gutter-width-medium;
}

.uk-padding-medium-right {
    padding-right: @gutter-width-medium;
}

.uk-padding-medium-bottom {
    padding-bottom: @gutter-width-medium;
}

.uk-padding-medium-left {
    padding-left: @gutter-width-medium;
}

.uk-padding-medium-vertical {
    padding-top: @gutter-width-medium;
    padding-bottom: @gutter-width-medium;
}

.uk-padding-medium-horizontal {
    padding-left: @gutter-width-medium;
    padding-right: @gutter-width-medium;
}

.uk-padding-large {
    padding: @gutter-width-large;
}

.uk-padding-large-top {
    padding-top: @gutter-width-large;
}

.uk-padding-large-right {
    padding-right: @gutter-width-large;
}

.uk-padding-large-bottom {
    padding-bottom: @gutter-width-large;
}

.uk-padding-large-left {
    padding-left: @gutter-width-large;
}

.uk-padding-large-vertical {
    padding-top: @gutter-width-large;
    padding-bottom: @gutter-width-large;
}

.uk-padding-large-horizontal {
    padding-left: @gutter-width-large;
    padding-right: @gutter-width-large;
}

.uk-padding-xlarge {
    padding: @gutter-width-xlarge;
}

.uk-padding-xlarge-top {
    padding-top: @gutter-width-xlarge;
}

.uk-padding-xlarge-right {
    padding-right: @gutter-width-xlarge;
}

.uk-padding-xlarge-bottom {
    padding-bottom: @gutter-width-xlarge;
}

.uk-padding-xlarge-left {
    padding-left: @gutter-width-xlarge;
}

.uk-padding-xlarge-vertical {
    padding-top: @gutter-width-xlarge;
    padding-bottom: @gutter-width-xlarge;
}

.uk-padding-xlarge-horizontal {
    padding-left: @gutter-width-xlarge;
    padding-right: @gutter-width-xlarge;
}

// Responsive margin / padding
@media (min-width: 1600px) {
    .uk-margin-remove-top-xl {
        margin-top: 0 !important;
    }
}

// Modal
.uk-modal,
.ReactModal__Overlay {
    z-index: 1050 !important;
}

// Logo
.uk-logo {
    img {
        height: 38px !important;
    }
}

.uk-button-link {
    padding: 0;
    font-size: @font-size-default;
    line-height: 1.5;
    background: none;
    border: 0;
    color: @primary-color !important;
    box-shadow: none;

    &:hover {
        background: none;
        color: @primary-color !important;
        text-decoration: underline;
    }

    &:focus {
        background: none;
        color: @primary-color !important;
        text-decoration: none;
    }

    &:disabled,
    &:disabled:hover {
        border: 0;
        text-decoration: none;
    }
}

.uk-button-link-active {
    font-weight: 700;

    &:hover {
        text-decoration: none;
    }
}

.uk-text-strike {
    .uk-table {
        text-decoration: line-through;
    }
}

.uk-margin-left-3 {
    margin-left: 20px;
}

.uk-margin-left-4 {
    margin-left: 40px;
}

.uk-margin-left-5 {
    margin-left: 60px;
}

.uk-margin-left-6 {
    margin-left: 80px;
}

.uk-label {
    line-height: 1;
    padding: 4px 10px;
}

.uk-label-success {
    background-color: @success-color;
}
