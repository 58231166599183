html {
    font-size: 16px;
    color: @text-color;
}
a,
span,
li {
    font-weight: inherit;
}
h1,
.uk-h1 {
    color: @primary-color;
    font-weight: 300;
}
h2,
.uk-h2 {
    color: @primary-color;
    font-weight: 400;
    font-size: 1.5rem;
}

strong *:not([class^='uk-font-weight']) {
    font-weight: 600;
}
em {
    color: @text-color;
}
em *:not([class^='uk-font-style']) {
    font-style: italic;
}

.base-font-smaller {
    font-size: 14px;
}
.line-height-button {
    line-height: 36px;
}
.line-height-small-button {
    line-height: 30px;
}
.pre-wrap {
    white-space: normal;
}
