$setup-text-color: #1a1a1a;
$setup-primary-color: #2356a0;
$setup-secondary-color: #61277f;

$lightGrey: #1a1a1a99;

$light: #38aae6;

$blue30: #C1DFF5;
$blue10: #E9F2F8;

$font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu", sans-serif;

.docrev-setup-wrapper {
    background-color: $blue10;
    height: 100vh;
    font-family: $font-family;

    .logo {
        max-width: 160px;
        margin-bottom: 1.5rem;
    }

    &:before {
        background: #fff;
        content: " ";
        height: 100%;
        position: fixed;
        right: 0;
        top: 0;
        transform-origin: right;
        width: 50%;
        box-shadow: 15px 0 30px 0 rgba(0, 0, 0, 0.18);
    }

    h1,
    h2,
    h3 {
        font-family: $font-family;
    }

    h2 {
        font-size: 36px;
        font-weight: 600;
        letter-spacing: -.03rem;
        color: #1a1a1ae6;
    }

    .text-secondary {
        font-weight: 500;
        color: $lightGrey !important;
        font-size: 1rem;
    }

    .extra-small {
        font-size: 14px;
    }
}

.docrev-setup-background {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: -1;
    background-color: $blue10;
}

.docrev-setup-modal-content {
    box-shadow: 15px 0 30px 0 rgba(0, 0, 0, 0.18);
    height: 100vh;
}

#setup-wizard {
    font-family: $font-family;
    -webkit-font-smoothing: antialiased;

    * {
        letter-spacing: 0 !important;
    }

    h1,
    h2,
    h3 {
        font-family: $font-family;
    }

    .text-muted {
        color: $setup-text-color !important;
        opacity: 0.5;
    }

    .docrev-react-select__single-value {
        color: $setup-text-color !important;
    }

    .docrev-react-select__value-container {
        padding: 4px 12px !important;
    }

    .docrev-react-select__control {
        min-height: 36px !important;
        border-radius: 6px !important;
        border: 0 !important;
        box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07), 0 1px 1.5px 0 rgba(0, 0, 0, 0.05) !important;
    }

    .alert-primary {
        background-color: $blue30;
    }

    .card-body {
        padding: 2rem;
    }

    #heading {
        color: $setup-primary-color;
        font-weight: normal;
    }

    #msform {
        position: relative;
    }

    #msform fieldset {
        background: white;
        border: 0 none;
        border-radius: 0.5rem;
        box-sizing: border-box;
        width: 100%;
        margin: 0;

        /*stacking fieldsets above each other*/
        position: relative;
    }

    .form-card {
        text-align: left;
    }

    #msform input.form-control,
    #msform textarea {
        padding: 8px 12px;
        border: 0;
        outline: none;
        border-radius: 6px;
        height: 36px;
        color: $setup-text-color;
        box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07), 0 1px 1.5px 0 rgba(0, 0, 0, 0.05);

        &.is-invalid {
            color: #dc2727;
            box-shadow: 0 0 0 1px #ef9896, 0 2px 4px 0 rgba(0, 0, 0, 0.07), 0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
        }
    }

    #msform input.form-control:focus,
    #msform textarea:focus {
        box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.7), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 4px rgba(50, 151, 211, 0.3);
    }

    /*Next Buttons*/
    #msform .action-button {
        width: 100px;
        height: 38px;
        background: $setup-primary-color;
        font-weight: 500;
        font-size: 1rem;
        color: white;
        border: 0 none;
        border-radius: 6px;
        cursor: pointer;
        padding: 0;
    }

    #msform .action-button:hover, #msform .action-button:focus {
        background-color: darken($setup-primary-color, 10%);
    }

    /*Previous Buttons*/
    #msform .action-button-previous {
        background: #fff;
        font-weight: 500;
        color: $setup-text-color;
        border: none;
        cursor: pointer;

        svg {
            opacity: 0.6;
            padding-top: 2px;
        }

        &:hover {
            color: $setup-text-color !important;
        }
    }

    /*FieldSet headings*/
    .fs-title {
        font-size: 20px;
        color: $setup-text-color;
        margin-bottom: 0;
        font-weight: 500;
    }

    .purple-text {
        color: $setup-primary-color;
        font-weight: normal;
    }

    /*Step Count*/
    .steps {
        font-size: 18px;
        color: $setup-text-color;
        margin-bottom: 0;
        font-weight: 500;
    }

    /*Field names*/
    .label-title-label {
        color: #1a1a1ab3 !important;
        text-align: left;
        font-size: 13px;
        font-weight: 500 !important;
        margin-bottom: 0.25rem;
    }

    /*Icon progressbar*/
    #progressbar {
        padding-left: 0;
        margin-bottom: 30px;
        overflow: hidden;
        color: lightgrey;
    }

    #progressbar .active {
        color: $setup-primary-color;
    }

    #progressbar li {
        list-style-type: none;
        font-size: 15px;
        width: 25%;
        float: left;
        position: relative;
        font-weight: 400;
    }

    /*Icons in the ProgressBar*/
    #progressbar #account:before {
        content: "";
        background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23fff' class='bi bi-unlock-fill' viewBox='0 0 16 16'><path d='M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2'/></svg>");
        background-repeat: no-repeat;
        background-size: 1rem 1rem;
        background-position: center center;
    }

    #progressbar #personal:before {
        content: "";
        background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23fff' class='bi bi-person-fill' viewBox='0 0 16 16'><path d='M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6'/></svg>");
        background-repeat: no-repeat;
        background-size: 1.25rem 1.25rem;
        background-position: center center;
    }

    #progressbar #terms:before {
        content: "";
        background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23fff' class='bi bi-file-text-fill' viewBox='0 0 16 16'><path d='M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M5 4h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1m-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1m0 2h3a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1'/></svg>");
        background-repeat: no-repeat;
        background-size: 1rem 1rem;
        background-position: center center;
    }

    #progressbar #confirm:before {
        content: "";
        background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23fff' class='bi bi-check' viewBox='0 0 16 16'><path d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z'/></svg>");
        background-repeat: no-repeat;
        background-size: 1.5rem 1.5rem;
        background-position: center center;
    }

    /*Icon ProgressBar before any progress*/
    #progressbar li:before {
        width: 50px;
        height: 50px;
        line-height: 45px;
        display: block;
        font-size: 2em;
        color: #ffffff;
        background: lightgray;
        border-radius: 50%;
        margin: 0 auto 10px auto;
        padding: 2px;
        position: relative;
        z-index: 1;
    }

    /*ProgressBar connectors*/
    #progressbar li:after {
        content: '';
        width: 100%;
        height: 2px;
        background: lightgray;
        position: absolute;
        left: 0;
        top: 25px;
        z-index: 0;
    }

    /*Color number of the step and the connector before it*/
    #progressbar li.active:before, #progressbar li.active:after {
        background: $setup-primary-color;
    }

    /*Animated Progress Bar*/
    .progress {
        height: 20px;
    }

    .progress-bar {
        background-color: $setup-primary-color;
    }

    /*Fit image in bootstrap div*/
    .fit-image {
        width: 100%;
        object-fit: cover;
    }
}
