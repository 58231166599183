.flex-table-header {
    font-size: $font-size-sm;
    text-transform: uppercase;
    text-align: left;
    padding: 6px 8px;
}

.flex-table-cell {
    font-size: $font-size-sm;
    text-align: left;
    padding: 6px 8px;

    p {
        margin-bottom: 10px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.flex-table-vertical {
    .flex-table-row {
        display: flex;
    }

    .flex-table-cell {
        padding: 10px 8px;
    }

    .flex-table-cell-editor {
        padding: 0 8px;
    }

    .flex-table-header {
        padding: 10px 8px;
    }

    &.flex-table-striped {
        .flex-table-row {
            border-bottom: 1px solid #e5e5e5;

            &:last-child {
                border-bottom: none;
            }
        }
    }
}

.docrev-flex-table {
    background-color: $white;
    padding: 0.8rem 1rem;
    font-size: $font-size-sm;
    color: $black;
    border: 1px solid #dde1e8;
    border-radius: 0.2rem;
    box-shadow: $dr-shadow-sm;

    .docrev-flex-head {
        border-bottom: 1px solid $border-color;
        color: $midBlue;
        font-weight: $font-weight-bold;
    }

    .docrev-flex-body {
        border-bottom: 1px solid $white;
    }

    .docrev-flex-body:not(:last-child) {
        border-bottom-color: $border-color;
    }

    .row > div {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
}
