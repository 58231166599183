.label {
    color: $darkBlueAlt;
    border: 1px solid $gray-150;
    border-radius: 4px;
    background-color: $white;
    font-size: $font-size-sm;
    padding: 0.25rem 0.75rem;
    display: flex;
    box-shadow: 0 2px 4px rgba($darkBlueAlt, 0.05);
    transition: background-color, opacity 100ms ease-in-out;

    &.disabled {
        opacity: 50%;
    }

    &.active {
        background-color: #eaf1fc;
        border: 1px solid $gray-400;
    }
}

.label-prefix {
    margin-right: 0.3rem;
    flex-wrap: nowrap;
    color: #6b737e;
}

.label-title {
    flex-wrap: nowrap;
}

.tag-icon-blue {
    color: $primary;
}

.tag-icon-danger {
    color: $danger;
}

.tag-icon-alert {
    color: $warning;
}

.tag-icon-success {
    color: #5cb85c;
}

.tag-color-wrapper {
    display: flex;
    width: 30px;
}

.single-color .tag-color-wrapper {
    margin-left: 10px;
}

.two-colors .tag-color-wrapper {
    display: flex;
}
