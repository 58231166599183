.rstm-toggle-icon {
    display: inline-block;
}

.rstm-toggle-icon-symbol {
    width: 2rem;
    height: 2rem;
    text-align: center;
    line-height: 2rem;
}

.rstm-tree-item-group {
    list-style-type: none;
    padding-left: 0;
    border-top: 1px solid @theme-color;
    text-align: left;
    width: 100%;
}

.rstm-tree-item {
    padding: 0.75rem 1rem;
    cursor: pointer;
    color: @text-color;
    background: none;
    border-bottom: 1px solid @theme-color;
    box-shadow: none;
    z-index: unset;
    position: relative;
}

.rstm-tree-item--active {
    color: white;
    background: @highlight-color;
    border-bottom: none;
}

.rstm-tree-item--focused {
    z-index: 999;
}

.rstm-search {
    padding: 1rem 1.5rem;
    border: none;
    width: 100%;
}
