* + address,
* + dl,
* + fieldset,
* + figure,
* + ol,
* + p,
* + pre,
* + ul {
    margin-top: 0;
}

// Document preview
.page {
    width: 900px;
    min-height: 1273px;
}

// Document editor
.editor-right-side {
    * {
        font-family: 'Poppins', sans-serif;
    }

    h3,
    h4 {
        margin-top: 0;
        margin-bottom: @gutter-width-xsmall !important;
        line-height: 1.4;
        font-weight: bold;
    }

    h3 {
        font-size: 1.2rem;
    }

    h4 {
        font-size: 1rem;
    }

    p,
    table,
    ul,
    ol {
        font-size: 14px;
        margin-top: 0;
        margin-bottom: @gutter-width-small !important;
    }

    table {
        max-width: 95%;
    }
}

// Toolbar position
.position-outside {
    position: absolute;
    height: 40px;
    top: -40px;
}

// Visibility
.opacity-3 {
    opacity: 0.3;
}

.opacity-6 {
    opacity: 0.6;
}

.opacity-75 {
    opacity: 0.75;
}

// Switch
.switch-form-margin-fix {
    margin-top: 5px;
}

.switch-text-fix {
    line-height: 36px;
    vertical-align: text-bottom;

    label {
        display: inline;
        margin-bottom: 0;
    }
}

// Highlight and select text changes
ins,
.ins {
    &,
    *,
    .fixed-link {
        font-style: normal;
        background-color: @diff-marking-ins-color;
        color: @text-color;

        em {
            font-style: italic;
        }
    }
}

del,
.del {
    &,
    *,
    .fixed-link {
        font-style: normal;
        background-color: @diff-marking-del-color;
        color: @text-color;
        text-decoration: line-through;

        em {
            font-style: italic;
        }
    }
}

.part {
    cursor: pointer;

    &:hover {
        &,
        ins,
        del {
            &,
            *,
            p,
            ol,
            ul,
            li,
            table,
            td {
                background-color: @primary-light-color;
            }
        }
    }

    &.selected,
    &.selected:hover {
        &,
        ins,
        del {
            &,
            * {
                background-color: @primary-color;
                .uk-text-white;
            }
        }
    }
}

// Inline buttons line height fix
.line-height-fix {
    line-height: 40px;
}

// Line height 1
.line-height-1 {
    line-height: 1;
}

// Interactive blocks
.interactive-block:hover {
    outline: 1px dotted #333;
    cursor: pointer;
}

// Block settings layout
.block-settings {
    outline: 1px solid @theme-color;
}

// Editor padding fix
.padding-1 {
    padding: 1px;
}

// Rotate classes
.rotate {
    &,
    &[uk-icon] svg,
    &[data-uk-icon] svg {
        .default-transition();
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

.rotate-minus180 {
    &,
    &[uk-icon] svg,
    &[data-uk-icon] svg {
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        transform: rotate(-180deg);
    }
}

.rotate-minus-5 {
    &,
    &[uk-icon] svg,
    &[data-uk-icon] svg {
        -webkit-transform: rotate(-5deg);
        -moz-transform: rotate(-5deg);
        -ms-transform: rotate(-5deg);
        -o-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }
}

.rotate-90 {
    &,
    &[uk-icon] svg,
    &[data-uk-icon] svg {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}

.rotate-minus-90 {
    &,
    &[uk-icon] svg,
    &[data-uk-icon] svg {
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }
}

.rotate-180 {
    &,
    &[uk-icon] svg,
    &[data-uk-icon] svg {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

.rotate-270 {
    &,
    &[uk-icon] svg,
    &[data-uk-icon] svg {
        -webkit-transform: rotate(270deg);
        -moz-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        -o-transform: rotate(270deg);
        transform: rotate(270deg);
    }
}

// New item indicator
.new-item-indicator {
    display: block;
    position: absolute;
    left: 4px;
    top: 50%;
    width: 8px;
    height: 8px;
    margin-top: -4px;
    background-color: @warning-color;
    background: radial-gradient(circle at center, @warning-color 0, @warning-color 40%, rgba(0, 0, 0, 0) 100%);
    border-radius: 50%;
}

// Misc
.no-content-wrap {
    white-space: nowrap;
}

.z-index-2 {
    z-index: 2;
}

.max-width-900 {
    max-width: 900px;
}

.width-160 {
    width: 160px;
}

.width-175 {
    width: 175px;
}

.margin-navbar-fix {
    margin-top: 51px;
}

.max-height-6-lines {
    position: relative;
    max-height: 85px;
    overflow: hidden;

    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 30px;

        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(
            top,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0); /* IE6-9 */
    }
}

// ReactModal elements
.modal-button-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

// Freeze table head rows
table.freeze-panes {
    thead,
    tbody,
    tfoot {
        display: block;
    }

    td,
    th {
        min-width: 300px;
        width: 300px;
        max-width: 300px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    tbody {
        max-height: 60vh;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

// Max height for overflow
.max-height-650vh {
    max-height: 60vh;
}

// Statistic block with icon
.statistic-block {
    font-size: 1.2rem;
    line-height: 1.5;

    .statistic-icon {
        color: #c3c3c3;
        font-size: 1.6rem;
    }

    .statistic-number {
        font-size: 32px;
        line-height: 1;
    }
}

.uk-padding-extra-small {
    padding: 12px;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
}

div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 280px;
    padding: 0;
    font-size: 0.875rem;
}

div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 0 20px;
    margin: 0;
}

div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: @theme-light-color;
    cursor: pointer;
}

.uk-remove-radius {
    border-radius: 0 !important;
}

.uk-label-muted {
    background-color: @muted-background-color;
    color: @muted-color;
}

.uk-card-success {
    background-color: @success-background-color;

    .uk-card-footer {
        border-top: 1px solid #28a7454d;
        background-color: #fff;
    }
}

.uk-grid {
    .uk-card-success {
        &:hover {
            background-color: darken(@success-background-color, 5%) !important;
        }
    }
}

.uk-text-extra-small {
    font-size: 0.75rem;
}

.publication-card {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

    .uk-card-body {
        min-height: 116px;
    }
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #e8ecf0;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
}

.ellipsis {
    &:after {
        content: '…';
    }
}
