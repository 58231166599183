.uk-badge-small {
  font-size: 10px;
  border-radius: 2em;
  min-width: 20px;
  height: 20px;
}

.uk-badge-danger {
  background-color: #a90000;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #faf9f8;
  color: #252423;
  padding: 2.5em 1rem 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.comment-item {
  border: 1px solid #edebe9;
  border-radius: @border-radius;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  padding: 0.8em;

  h4 {
    color: #252423;
  }

  &:focus {
    outline: none;
  }
}

/* Individual item */
.bm-item {
  display: inline-block;

  &:focus {
    outline: none;
  }
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
