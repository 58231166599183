ul.avatar-group {
    display: flex;
    margin: 0 8px 0 0;
    padding: 0;
    line-height: 1;
    list-style-type: none !important;

    li {
        margin: 0 -8px 0 0;

        > div {
            display: inline-block;
            position: relative;
            outline: 0;

            > span {
                height: 24px;
                width: 24px;
                display: flex;
                align-items: stretch;
                flex-direction: column;
                justify-content: center;
                outline: none;
                overflow: hidden;
                background-color: #fff;
                border-radius: 50%;
                box-sizing: content-box;
                cursor: inherit;
                transform: translateZ(0);
                transition: transform 200ms, opacity 200ms;
                box-shadow: 0 0 0 2px #fff;
                border: none;
                margin: 2px;
                padding: 0;
                font-size: inherit;
                font-family: inherit;
                position: relative;

                > span {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    flex: 1 1 100%;
                    background-color: transparent;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }
        }
    }
}

ul.avatar-group.tasks-avatar-group {
    margin: 0;

    li {
        margin: 0 -10px 0 0;

        > div {
            > span {
                height: 30px;
                width: 30px;
                box-shadow: 1px 1px 1px rgb(255 255 255);
            }
        }
    }
}
