@small: 30px;

.CircularProgressbar {
  .CircularProgressbar-path {
    stroke: @highlight-color;
  }

  .CircularProgressbar-text {
    fill: @highlight-color;
  }

  &.small {
    width: @small;
    height: @small;

    .CircularProgressbar-text {
      font-size: 22px;
    }
  }
}

.width-progressbar-small {
  display: inline-block;
  width: @small;
}
