.edit-area-block-content-wrapper {
    max-width: 95%;

    .block-edit-buttons {
        display: none;
    }

    &:hover {
        > div > .editor-toolbar > {
            .block-edit-buttons {
                display: inline-flex;
            }

            .block-edit-labels {
                display: none;
            }
        }
    }
}

.edit-area-navbar {
    z-index: 1;
}

.tox-dialog__disable-scroll {
    .edit-area-navbar {
        z-index: unset;
    }
}

.blockLayout-type-textExplanation {
    .edit-area-block-content-wrapper {
        max-width: unset;

        table.table.mb-2 {
            margin-bottom: 0 !important;
        }

        .edit-area-block-content {
            > div.px-3 {
                padding-bottom: 0 !important;
            }
        }

        table {
            .edit-area-block-content {
                border-color: $lightBlueMid !important;
            }
        }
    }
}

.edit-area-block {
    margin-bottom: 2.5rem;

    .edit-area-block-content {
        border: 1px solid #e2e4e7;
        border-radius: $border-radius;
        width: 100%;
    }

    .tiny-mce-content-body {
        word-break: break-word;

        * {
            line-height: 1.8;
            font-family: $font-family-base;

            a,
            a:hover {
                text-decoration: underline;
                word-break: break-all;
            }
        }

        > *:last-child {
            margin-bottom: 0;
        }

        h3 {
            font-weight: $font-weight-bold;
            font-size: $font-size-lg;
            margin-top: 0;
            margin-bottom: 6px;
            color: $darkBlueAlt;
        }

        h4 {
            font-weight: $font-weight-bold;
            font-size: $font-size-base;
            margin-top: 0;
            margin-bottom: 6px;
            color: $darkBlueAlt;
        }

        p,
        ul,
        ol {
            margin-bottom: 9px;
        }

        h3,
        h4,
        p {
            &.margin-bottom {
                padding-bottom: 3px;
                border-bottom: 10pt solid #ffecec;
            }

            &.margin-top {
                padding-top: 3px;
                border-top: 10pt solid #ffecec;
            }
        }

        strong {
            font-weight: 600 !important;
        }

        .answer {
            font-weight: 600 !important;
            margin-bottom: 0;
        }

        .mce-content-body {
            margin-top: unset;
            margin-bottom: unset;
            padding: unset;
            font-weight: unset;
            font-size: unset;
            line-height: unset !important;
        }

        .mce-content-body.mce-edit-focus {
            outline: unset;

            .answer {
                font-weight: 700 !important;
                margin-bottom: 0;
            }
        }

        .newTextBlockPlaceholder {
            opacity: 0.5;
        }
    }

    .restore-block-content {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        background-color: transparentize($danger, 0.85);
    }
}

.uk-background-danger-light {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    background-color: transparentize($danger, 0.85);
}

.uk-background-danger-light + .area-read-only {
    border-top: 0 !important;
}

.tiny-mce-content-body-linked {
    color: $midBlueAlt !important;

    * {
        line-height: 1.8;
        font-family: $font-family-base;
    }

    > *:last-child {
        margin-bottom: 0 !important;
    }

    h3 {
        font-weight: $font-weight-bold;
        font-size: $font-size-lg;
        margin-top: 0;
        margin-bottom: 6px;
        color: $midBlueAlt;
    }

    h4 {
        font-weight: $font-weight-bold;
        font-size: $font-size-base;
        margin-top: 0;
        margin-bottom: 6px;
        color: $midBlueAlt;
    }

    p {
        margin-bottom: 9px;
    }

    p.answer {
        font-weight: 600 !important;
        margin-bottom: 0 !important;
    }

    a {
        text-decoration: underline;
    }

    ul {
        margin-bottom: 9px !important;
    }

    .mce-content-body {
        margin-top: unset;
        margin-bottom: unset;
        padding: unset;
        font-weight: unset;
        font-size: unset;
        line-height: unset !important;
    }

    .mce-content-body.mce-edit-focus {
        outline: unset;
    }
}

.edit-area-block.is-deleted {
    .edit-area-block-content {
        border-color: $danger;
    }

    .drag-handle {
        color: $muted !important;
    }
}

.edit-area-block.is-dragging {
    .hide-when-dragging {
        visibility: hidden;
    }
}

.edit-area-block-type-text {
    .editor-wrapper {
        padding: 1.5rem;
        border-radius: $border-radius;

        &:hover,
        &.active {
            background-color: $bglight;
            box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
        }
    }
}

.edit-area-block-type-table {
    .uk-table {
        margin-bottom: 0;
    }
}

.edit-area-block-type-blockLayout {
    .edit-area-block {
        margin-bottom: 1rem;

        .drag-handle {
            font-size: 20px !important;
            width: 28px !important;
            margin-left: -18px !important;
        }
    }

    &.blockLayout-type-default {
        .tiny-mce-content-body * {
            line-height: 1.6;
        }

        .mce-content-body * {
            font-size: $font-size-sm;
        }

        .edit-area-block-type-text .editor-wrapper {
            padding: 1rem;
            font-size: $font-size-sm;
        }
    }

    .editor-toolbar-bottom {
        visibility: hidden;
    }

    td:hover {
        .editor-toolbar-bottom {
            visibility: visible;
        }
    }
}

.blockLayout-type-textExplanation {
    .table-bordered td,
    .table-bordered {
        border: none !important;
    }

    .edit-area-block {
        margin-bottom: 0;

        .btn-delete-block {
            display: none;
        }
    }
}

.check-area {
    .area-block {
        border: 1px solid #e2e4e7;
        border-radius: $border-radius;
    }
}

.newTextBlockPlaceholder {
    font-style: italic;
}

.fixed-link {
    display: unset !important;
    height: unset !important;
    text-decoration: underline;
    color: #0052cc;
    border-radius: 4px;
    background-color: $lightBlueMid;
    border-color: $lightBlueMid;

    &:hover {
        color: #003380 !important;
    }
}
