// Fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

// Define colors first
@import 'colors';

// Required Bootstrap
@import 'bootstrap-required';

// Custom mixins
@import 'theme-mixins';

// Custom functions
@import 'functions';

// Custom variables
@import 'variables';

// Optional Bootstrap
@import 'bootstrap-custom';

// Custom css
@import 'react-bootstrap-table2';
@import 'custom';
@import 'flex-tables';
@import 'dropdown';
@import 'nav';
@import 'editor';
@import 'forms';
@import 'tables';
@import 'badge';
@import 'buttons';
@import 'cards';
@import 'media-library';
@import 'list-group';
@import 'data-tables';
@import 'label';
@import 'modal';
@import 'sidebar';
@import 'edit-area';
@import 'custom-form';
@import 'avatar';
@import 'setup-wizard';
@import 'empty-state';
@import 'react-loading-skeleton/dist/skeleton.css';
