.badge {
    &.disabled {
        background-color: #eaecef !important;
        color: #adb5bd !important;
        pointer-events: none;
    }

    &.small {
        padding: 0.25em 0.5em;
        font-size: 0.625rem;
    }
}

.badge-warning {
    color: #f57b00;
    background-color: #ffefdf;
}

.badge-success {
    color: #1e7e34;
    background-color: #dff2e3;
}

.badge-meta {
    color: $brightBlueAlt;
    border-radius: 1rem;
    background-color: #c9e4ff;
    margin: 0 0.25rem;
    font-size: 11px;
    padding: 0.6em 1em;
}

.badge-color-alert {
    background-color: #ffde91;
    color: #bb7a32;
}
