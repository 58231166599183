.spinner {
  margin: 0;
  text-align: center;
  display: inline-block;
  height: 4em;
  font-size: 1em;
  width: auto;

  &.spinner-size-small {
    height: 2em;
    font-size: 0.5em;
  }

  &.spinner-size-default {
    height: 4em;
    font-size: 1em;
  }

  &.spinner-size-large {
    height: 8em;
    font-size: 2em;
  }

  > * {
    background-color: @neutral-color;
    height: 100%;
    width: 0.4em;
    display: inline-block;
    margin: 0 0.1em;

    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }

  .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }

  .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  .rect6 {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }

  .rect7 {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }
}


@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4)
  }
  20% {
    -webkit-transform: scaleY(1.0)
  }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}