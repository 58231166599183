table.table-docrev {
    thead {
        th {
            border-top: none;
            color: $darkBlueAlt !important;
        }
    }

    th {
        font-weight: 500;
        color: $darkBlueAlt !important;
    }

    tbody {
        tr {
            cursor: pointer !important;
        }

        &.dragging {
            tr.is-group td {
                background-color: darken(#f0f6fb, 10%) !important;
            }
        }

        &.main-dragging {
            td {
                background-color: rgba(246, 248, 250, 1) !important;
            }
        }

        tr.is-selected {
            td {
                background-color: $bglight !important;
            }
        }

        tr.has-group {
            td {
                background-color: rgba(246, 248, 250, 0.5);
            }
        }

        tr.is-group {
            td {
                background-color: #f0f6fb;
                color: $darkBlueAlt;
            }
        }

        tr.is-expanded {
            td {
                background-color: $component-active-bg;
                border-top: 1px solid $darkBlueAlt;
            }

            td:first-child {
                border-left: 1px solid $darkBlueAlt;
            }

            td:last-child {
                border-right: 1px solid $darkBlueAlt;
            }
        }

        tr.expanded-row-details {
            background-color: rgba(#e5eff7, 0.5) !important;

            &:hover {
                cursor: auto !important;
            }

            td {
                border-left: 1px solid $darkBlueAlt;
                border-right: 1px solid $darkBlueAlt;
                border-bottom: 1px solid $darkBlueAlt;
            }
        }

        tr.is-disabled {
            td {
                color: $muted;
            }
        }
    }
}

.table {
    color: $darkBlueAlt !important;
}

.table-sm {
    th,
    td {
        font-size: 85%;
    }

    thead {
        th {
            border-top: none;
            border-bottom: none;
            font-weight: $font-weight-normal;
            color: $bgDense;
        }
    }
}

table.table-publication-section {
    thead {
        th {
            color: $midBlue;
            font-size: 85%;
            font-weight: normal;
            border-top: none;
            padding-top: 0;
        }
    }
}

table.table-team {
    margin-bottom: 0;
    font-size: 13px;

    thead {
        th {
            background-color: $lightBlueLightAlt2;
            font-weight: 500;
            font-size: $font-size-sm;
            color: #475467;
            border-bottom-width: 1px;
            padding: 0.5rem 1rem;
            border-top: none;

            &:first-child {
                border-top-left-radius: 0.2rem;
            }

            &:last-child {
                border-top-right-radius: 0.2rem;
            }
        }
    }

    th,
    td {
        padding: 0.75rem 1rem;
    }
}

table.dr-table {
    margin-bottom: 0;

    th,
    td {
        border-color: $table-border-color-light;
        letter-spacing: normal;

        * {
            letter-spacing: normal;
        }
    }

    thead {
        th {
            background-color: $lightBlueLightAlt2;
            padding: 9px 0.75rem;
            font-size: $font-size-sm;
            font-weight: 500;
            border-bottom: none;
            color: $gray-600;
            letter-spacing: normal;
        }
    }

    tbody {
        td {
            vertical-align: middle;
            color: $gray-600;
            font-size: $font-size-sm;
            animation: $fade-in-normal;

            a:not(.dropdown-item):not(.btn):not(.badge):not(.page-link),
            .text-primary {
                font-size: $font-size-base;
                color: $coloralt !important;
                font-weight: 500;
                letter-spacing: $letter-spacing;
            }

            .badge {
                font-size: 10px;
                margin: 0 0.2rem 0.2rem 0;
            }
        }

        td.actions {
            > div {
                display: flex;
                align-items: center;
            }

            button.btn {
                color: $gray-600;
                font-weight: 500;
                padding: 0 !important;
                font-size: 13px;

                &:hover {
                    color: $brightBlue;
                }
            }

            button.btn.hover-danger {
                &:hover {
                    color: $danger;
                }
            }

            button.btn:not(:last-child) {
                margin-right: 1.25rem;
            }
        }

        tr.row-selected {
            background-color: #f3faff;
        }

        $expandedRowColor: $gray-300;
        $expandedBackgroundColor: $lightBlueMidAlt;

        tr.is-expanded {
            background-color: $expandedBackgroundColor;

            td {
                border-color: $expandedRowColor;

                &:first-of-type {
                    border-left: 1px solid $expandedRowColor;
                }

                &:last-of-type {
                    border-right: 1px solid $expandedRowColor;
                }
            }
        }

        tr.expanded-row-details > td {
            border: 1px solid $expandedRowColor;
            border-top: none;
            padding: 0;
            background-color: $expandedBackgroundColor;
        }

        tr.cursor-pointer:not(.is-expanded):hover > td {
            background-color: $lightBlueMid !important;
        }
    }

    tfoot {
        td {
            font-size: $font-size-sm;
            color: $muted;
            padding: 1rem 0 1rem 0.75rem;

            * {
                letter-spacing: normal;
            }
        }
    }
}

.block-layout-droppable-cell {
    .insert-block-btn {
        visibility: hidden;
    }

    &:hover {
        .insert-block-btn {
            visibility: visible;
        }
    }
}
