@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?48ctnu');
  src:  url('fonts/icomoon.eot?48ctnu#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?48ctnu') format('truetype'),
    url('fonts/icomoon.woff?48ctnu') format('woff'),
    url('fonts/icomoon.svg?48ctnu#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
  content: "\f002";
}
.icon-star:before {
  content: "\f005";
}
.icon-star-o:before {
  content: "\f006";
}
.icon-user:before {
  content: "\f007";
}
.icon-th-large:before {
  content: "\f009";
}
.icon-th:before {
  content: "\f00a";
}
.icon-th-list:before {
  content: "\f00b";
}
.icon-check:before {
  content: "\f00c";
}
.icon-close:before {
  content: "\f00d";
}
.icon-remove:before {
  content: "\f00d";
}
.icon-times:before {
  content: "\f00d";
}
.icon-search-plus:before {
  content: "\f00e";
}
.icon-search-minus:before {
  content: "\f010";
}
.icon-cog:before {
  content: "\f013";
}
.icon-gear:before {
  content: "\f013";
}
.icon-download:before {
  content: "\f019";
}
.icon-repeat:before {
  content: "\f01e";
}
.icon-rotate-right:before {
  content: "\f01e";
}
.icon-refresh:before {
  content: "\f021";
}
.icon-tag:before {
  content: "\f02b";
}
.icon-tags:before {
  content: "\f02c";
}
.icon-bookmark:before {
  content: "\f02e";
}
.icon-print:before {
  content: "\f02f";
}
.icon-font:before {
  content: "\f031";
}
.icon-bold:before {
  content: "\f032";
}
.icon-italic:before {
  content: "\f033";
}
.icon-text-height:before {
  content: "\f034";
}
.icon-text-width:before {
  content: "\f035";
}
.icon-align-left:before {
  content: "\f036";
}
.icon-align-center:before {
  content: "\f037";
}
.icon-align-right:before {
  content: "\f038";
}
.icon-align-justify:before {
  content: "\f039";
}
.icon-list:before {
  content: "\f03a";
}
.icon-dedent:before {
  content: "\f03b";
}
.icon-outdent:before {
  content: "\f03b";
}
.icon-indent:before {
  content: "\f03c";
}
.icon-image:before {
  content: "\f03e";
}
.icon-photo:before {
  content: "\f03e";
}
.icon-picture-o:before {
  content: "\f03e";
}
.icon-pencil:before {
  content: "\f040";
}
.icon-arrows:before {
  content: "\f047";
}
.icon-play:before {
  content: "\f04b";
}
.icon-pause:before {
  content: "\f04c";
}
.icon-stop:before {
  content: "\f04d";
}
.icon-chevron-left:before {
  content: "\f053";
}
.icon-chevron-right:before {
  content: "\f054";
}
.icon-check-circle:before {
  content: "\f058";
}
.icon-question-circle:before {
  content: "\f059";
}
.icon-info-circle:before {
  content: "\f05a";
}
.icon-arrow-left:before {
  content: "\f060";
}
.icon-arrow-right:before {
  content: "\f061";
}
.icon-arrow-up:before {
  content: "\f062";
}
.icon-arrow-down:before {
  content: "\f063";
}
.icon-mail-forward:before {
  content: "\f064";
}
.icon-share:before {
  content: "\f064";
}
.icon-expand:before {
  content: "\f065";
}
.icon-compress:before {
  content: "\f066";
}
.icon-exclamation-circle:before {
  content: "\f06a";
}
.icon-eye:before {
  content: "\f06e";
}
.icon-eye-slash:before {
  content: "\f070";
}
.icon-exclamation-triangle:before {
  content: "\f071";
}
.icon-warning:before {
  content: "\f071";
}
.icon-calendar:before {
  content: "\f073";
}
.icon-comment:before {
  content: "\f075";
}
.icon-chevron-up:before {
  content: "\f077";
}
.icon-chevron-down:before {
  content: "\f078";
}
.icon-folder:before {
  content: "\f07b";
}
.icon-folder-open:before {
  content: "\f07c";
}
.icon-arrows-v:before {
  content: "\f07d";
}
.icon-arrows-h:before {
  content: "\f07e";
}
.icon-bar-chart:before {
  content: "\f080";
}
.icon-bar-chart-o:before {
  content: "\f080";
}
.icon-cogs:before {
  content: "\f085";
}
.icon-gears:before {
  content: "\f085";
}
.icon-comments:before {
  content: "\f086";
}
.icon-upload:before {
  content: "\f093";
}
.icon-phone:before {
  content: "\f095";
}
.icon-bookmark-o:before {
  content: "\f097";
}
.icon-unlock:before {
  content: "\f09c";
}
.icon-arrow-circle-left:before {
  content: "\f0a8";
}
.icon-arrow-circle-right:before {
  content: "\f0a9";
}
.icon-arrow-circle-up:before {
  content: "\f0aa";
}
.icon-arrow-circle-down:before {
  content: "\f0ab";
}
.icon-globe:before {
  content: "\f0ac";
}
.icon-wrench:before {
  content: "\f0ad";
}
.icon-arrows-alt:before {
  content: "\f0b2";
}
.icon-group:before {
  content: "\f0c0";
}
.icon-users:before {
  content: "\f0c0";
}
.icon-chain:before {
  content: "\f0c1";
}
.icon-link:before {
  content: "\f0c1";
}
.icon-cloud:before {
  content: "\f0c2";
}
.icon-cut:before {
  content: "\f0c4";
}
.icon-scissors:before {
  content: "\f0c4";
}
.icon-copy:before {
  content: "\f0c5";
}
.icon-files-o:before {
  content: "\f0c5";
}
.icon-paperclip:before {
  content: "\f0c6";
}
.icon-floppy-o:before {
  content: "\f0c7";
}
.icon-save:before {
  content: "\f0c7";
}
.icon-bars:before {
  content: "\f0c9";
}
.icon-navicon:before {
  content: "\f0c9";
}
.icon-reorder:before {
  content: "\f0c9";
}
.icon-list-ul:before {
  content: "\f0ca";
}
.icon-list-ol:before {
  content: "\f0cb";
}
.icon-strikethrough:before {
  content: "\f0cc";
}
.icon-underline:before {
  content: "\f0cd";
}
.icon-table:before {
  content: "\f0ce";
}
.icon-caret-down:before {
  content: "\f0d7";
}
.icon-caret-up:before {
  content: "\f0d8";
}
.icon-caret-left:before {
  content: "\f0d9";
}
.icon-caret-right:before {
  content: "\f0da";
}
.icon-sort:before {
  content: "\f0dc";
}
.icon-unsorted:before {
  content: "\f0dc";
}
.icon-sort-desc:before {
  content: "\f0dd";
}
.icon-sort-down:before {
  content: "\f0dd";
}
.icon-sort-asc:before {
  content: "\f0de";
}
.icon-sort-up:before {
  content: "\f0de";
}
.icon-envelope:before {
  content: "\f0e0";
}
.icon-rotate-left:before {
  content: "\f0e2";
}
.icon-undo:before {
  content: "\f0e2";
}
.icon-cloud-download:before {
  content: "\f0ed";
}
.icon-cloud-upload:before {
  content: "\f0ee";
}
.icon-bell:before {
  content: "\f0f3";
}
.icon-file-text-o:before {
  content: "\f0f6";
}
.icon-angle-double-left:before {
  content: "\f100";
}
.icon-angle-double-right:before {
  content: "\f101";
}
.icon-angle-double-up:before {
  content: "\f102";
}
.icon-angle-double-down:before {
  content: "\f103";
}
.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}
.icon-angle-up:before {
  content: "\f106";
}
.icon-angle-down:before {
  content: "\f107";
}
.icon-desktop:before {
  content: "\f108";
}
.icon-laptop:before {
  content: "\f109";
}
.icon-tablet:before {
  content: "\f10a";
}
.icon-mobile:before {
  content: "\f10b";
}
.icon-mobile-phone:before {
  content: "\f10b";
}
.icon-mail-reply:before {
  content: "\f112";
}
.icon-reply:before {
  content: "\f112";
}
.icon-mail-reply-all:before {
  content: "\f122";
}
.icon-reply-all:before {
  content: "\f122";
}
.icon-chain-broken:before {
  content: "\f127";
}
.icon-unlink:before {
  content: "\f127";
}
.icon-superscript:before {
  content: "\f12b";
}
.icon-subscript:before {
  content: "\f12c";
}
.icon-chevron-circle-left:before {
  content: "\f137";
}
.icon-chevron-circle-right:before {
  content: "\f138";
}
.icon-chevron-circle-up:before {
  content: "\f139";
}
.icon-chevron-circle-down:before {
  content: "\f13a";
}
.icon-unlock-alt:before {
  content: "\f13e";
}
.icon-ellipsis-h:before {
  content: "\f141";
}
.icon-ellipsis-v:before {
  content: "\f142";
}
.icon-sort-alpha-asc:before {
  content: "\f15d";
}
.icon-sort-alpha-desc:before {
  content: "\f15e";
}
.icon-sort-amount-asc:before {
  content: "\f160";
}
.icon-sort-amount-desc:before {
  content: "\f161";
}
.icon-sort-numeric-asc:before {
  content: "\f162";
}
.icon-sort-numeric-desc:before {
  content: "\f163";
}
.icon-thumbs-up:before {
  content: "\f164";
}
.icon-thumbs-down:before {
  content: "\f165";
}
.icon-youtube-play:before {
  content: "\f16a";
}
.icon-trash:before {
  content: "\f1f8";
}
.icon-user-plus:before {
  content: "\f234";
}
.icon-user-times:before {
  content: "\f235";
}
.icon-calendar-check-o:before {
  content: "\f274";
}
.icon-user-circle:before {
  content: "\f2bd";
}
.icon-clock:before {
  content: "\e94e";
}
