// DocRev colors

$darkBlue: #33446e;
$darkBlueAlt: #255478;
$brightBlue: #2356a0;
$brightBlueAlt: #006ca6;
$midBlue: #a0a2b3;
$midBlueAlt: #a7aebb;
$lightBlueDense: #e6e8ee;
$lightBlueDenseAlt: #e8ecf0;
$lightBlueMid: #f2f3f6;
$lightBlueMidAlt: #f3f5f7;
$lightBlueLight: #faf9fc;
$lightBlueLightAlt1: #fcfbfc;
$lightBlueLightAlt2: #f9fafb;
$orange: #ff9429;
$orangeAlt: #ff9434;
$green: #1a9eab;
$greenAlt: #00abb4;
$buttonBlue: #0052cc;
$buttonBlueAlt: #0085ce;
$red: #dc3545;

$successGreen: #28a745;

// Color system
$color: $darkBlue;
$coloralt: $darkBlueAlt;
$primary: $buttonBlue;
$secondary: $midBlue;
$success: $successGreen;
$info: $green;
$warning: $orange;
$danger: $red;
//$light:         $gray-100 !default;
//$dark:          $gray-800 !default;

$bgDense: #e8ecf0;
$bgMid: #f3f5f7;
$bglight: #fdfdfd;
$gray-50: #f8f9fa;
$gray-150: #e8ecf0;
$gray-650: #5f6368;
$muted: #a7aebb;
$bgDeleted: #ffbebc;
$bgInsert: #ff9434;
$docRevLime: #d3d626;
$docRevOrange1: #e7cd96;

$diff-marking-ins-color: #006ca6;
$diff-marking-ins-bg-color: #d7fbdc;
$diff-marking-del-color: #006ca6;
$diff-marking-del-bg-color: #ffe7e7;
$cancelled-op-entry-bg-color: #ffe7e7;

// Publications

$purple: #e8e8f8;
$purpleDark: #9998ce;

$theme-colors: (
    'light': $bglight,
    'light-bg': $bgMid,
    'dark-blue': $darkBlue,
    'dark-blue-alt': $darkBlueAlt,
    'special': $purpleDark,
);
