* + address,
* + dl,
* + fieldset,
* + figure,
* + ol,
* + p,
* + pre,
* + ul {
    margin-top: 0;
}
.page {
    width: 900px;
    min-height: 1273px;
}
.editor-right-side * {
    font-family: 'Poppins', sans-serif;
}
.editor-right-side h3,
.editor-right-side h4 {
    margin-top: 0;
    margin-bottom: 6px !important;
    line-height: 1.4;
    font-weight: 500;
}
.editor-right-side h3 {
    font-size: 14px;
}
.editor-right-side h4 {
    font-size: 16px;
}
.editor-right-side a {
    text-decoration: underline;
}
.editor-right-side p,
.editor-right-side table,
.editor-right-side ul,
.editor-right-side ol {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 10px !important;
}
.editor-right-side table {
    max-width: 95%;
}
.position-outside {
    position: absolute;
    height: 40px;
    top: -40px;
}

.opacity-2 {
    opacity: 0.2;
}

.opacity-3 {
    opacity: 0.3;
}
.opacity-4 {
    opacity: 0.4;
}
.opacity-5 {
    opacity: 0.5;
}
.opacity-6 {
    opacity: 0.6;
}
.opacity-75 {
    opacity: 0.75;
}
.switch-form-margin-fix {
    margin-top: 5px;
}
.switch-text-fix {
    line-height: 36px;
    vertical-align: text-bottom;
}
.switch-text-fix label {
    display: inline;
    margin-bottom: 0;
}
ins,
.ins,
ins *,
.ins *,
ins .fixed-link,
.ins .fixed-link {
    font-style: normal;
    background-color: #fddd86bf;
    color: #33446e;
    border-radius: 5px;
}
ins em,
.ins em,
ins * em,
.ins * em,
ins .fixed-link em,
.ins .fixed-link em {
    font-style: italic;
}
del,
.del,
del *,
.del *,
del .fixed-link,
.del .fixed-link {
    font-style: normal;
    background-color: #ffbebc;
    color: #444;
    text-decoration: line-through;
}
del em,
.del em,
del * em,
.del * em,
del .fixed-link em,
.del .fixed-link em {
    font-style: italic;
}
.part {
    cursor: pointer;
}
.part:hover,
.part:hover ins,
.part:hover del,
.part:hover *,
.part:hover ins *,
.part:hover del *,
.part:hover p,
.part:hover ins p,
.part:hover del p,
.part:hover ol,
.part:hover ins ol,
.part:hover del ol,
.part:hover ul,
.part:hover ins ul,
.part:hover del ul,
.part:hover li,
.part:hover ins li,
.part:hover del li,
.part:hover table,
.part:hover ins table,
.part:hover del table,
.part:hover td,
.part:hover ins td,
.part:hover del td {
    background-color: #a1c1e6;
}
.part.selected,
.part.selected:hover,
.part.selected ins,
.part.selected:hover ins,
.part.selected del,
.part.selected:hover del,
.part.selected *,
.part.selected:hover *,
.part.selected ins *,
.part.selected:hover ins *,
.part.selected del *,
.part.selected:hover del * {
    background-color: #3c7fcc;
}
.part.selected,
.part.selected:hover,
.part.selected ins,
.part.selected:hover ins,
.part.selected del,
.part.selected:hover del,
.part.selected *,
.part.selected:hover *,
.part.selected ins *,
.part.selected:hover ins *,
.part.selected del *,
.part.selected:hover del *,
.part.selected[uk-icon],
.part.selected:hover[uk-icon],
.part.selected ins[uk-icon],
.part.selected:hover ins[uk-icon],
.part.selected del[uk-icon],
.part.selected:hover del[uk-icon],
.part.selected *[uk-icon],
.part.selected:hover *[uk-icon],
.part.selected ins *[uk-icon],
.part.selected:hover ins *[uk-icon],
.part.selected del *[uk-icon],
.part.selected:hover del *[uk-icon],
.part.selected.uk-text-color-inherit p,
.part.selected:hover.uk-text-color-inherit p,
.part.selected ins.uk-text-color-inherit p,
.part.selected:hover ins.uk-text-color-inherit p,
.part.selected del.uk-text-color-inherit p,
.part.selected:hover del.uk-text-color-inherit p,
.part.selected *.uk-text-color-inherit p,
.part.selected:hover *.uk-text-color-inherit p,
.part.selected ins *.uk-text-color-inherit p,
.part.selected:hover ins *.uk-text-color-inherit p,
.part.selected del *.uk-text-color-inherit p,
.part.selected:hover del *.uk-text-color-inherit p {
    color: #fff !important;
}
.line-height-fix {
    line-height: 40px;
}
.line-height-1 {
    line-height: 1;
}
.interactive-block:hover {
    outline: 1px dotted #333;
    cursor: pointer;
}
.block-settings {
    outline: 1px solid #dce4eb;
}
.padding-1 {
    padding: 1px;
}
.rotate,
.rotate[uk-icon] svg,
.rotate[data-uk-icon] svg {
    transition: all 0.3s ease-out;
    transform: rotate(0deg);
}
.rotate-minus180,
.rotate-minus180[uk-icon] svg,
.rotate-minus180[data-uk-icon] svg {
    transform: rotate(-180deg);
}
.rotate-minus-5,
.rotate-minus-5[uk-icon] svg,
.rotate-minus-5[data-uk-icon] svg {
    transform: rotate(-5deg);
}
.rotate-90,
.rotate-90[uk-icon] svg,
.rotate-90[data-uk-icon] svg {
    transform: rotate(90deg);
}
.rotate-minus-90,
.rotate-minus-90[uk-icon] svg,
.rotate-minus-90[data-uk-icon] svg {
    transform: rotate(-90deg);
}
.rotate-180,
.rotate-180[uk-icon] svg,
.rotate-180[data-uk-icon] svg {
    transform: rotate(180deg);
}
.rotate-270,
.rotate-270[uk-icon] svg,
.rotate-270[data-uk-icon] svg {
    transform: rotate(270deg);
}
.new-item-indicator {
    display: block;
    position: absolute;
    left: 4px;
    top: 50%;
    width: 8px;
    height: 8px;
    margin-top: -4px;
    background-color: #f19f4d;
    background: radial-gradient(circle at center, #f19f4d 0, #f19f4d 40%, rgba(0, 0, 0, 0) 100%);
    border-radius: 50%;
}
/* .no-content-wrap {
    white-space: nowrap;
} */
.z-index-2 {
    z-index: 2;
}
.max-width-900 {
    max-width: 900px;
}
.width-160 {
    width: 160px;
}
.width-175 {
    width: 175px;
}
.margin-navbar-fix {
    margin-top: 51px;
}
.max-height-6-lines {
    position: relative;
    max-height: 85px;
    overflow: hidden;
}
.max-height-6-lines:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 30px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
    /* IE6-9 */
}
.modal-button-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}
table.freeze-panes thead,
table.freeze-panes tbody,
table.freeze-panes tfoot {
    display: block;
}
table.freeze-panes td,
table.freeze-panes th {
    min-width: 300px;
    width: 300px;
    max-width: 300px;
    box-sizing: border-box;
}
table.freeze-panes tbody {
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.max-height-650vh {
    max-height: 60vh;
}
.statistic-block {
    font-size: 1.2rem;
    line-height: 1.5;
}
.statistic-block .statistic-icon {
    color: #c3c3c3;
    font-size: 1.6rem;
}
.statistic-block .statistic-number {
    font-size: 32px;
    line-height: 1;
}
.uk-padding-extra-small {
    padding: 12px;
}
/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
}
div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 280px;
    padding: 0;
    font-size: 0.875rem;
}
div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 0 20px;
    margin: 0;
}
div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #f6f8fa;
    cursor: pointer;
}
.uk-remove-radius {
    border-radius: 0 !important;
}
.uk-label-muted {
    background-color: #f6f8fa;
    color: #999;
}
.uk-card-success {
    background-color: #dcf5d2;
}
.uk-card-success .uk-card-footer {
    border-top: 1px solid #28a7454d;
    background-color: #fff;
}
.uk-grid .uk-card-success:hover {
    background-color: #ccf0bd !important;
}
.uk-text-extra-small {
    font-size: 0.75rem;
}
.publication-card {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}
.publication-card .publication-name {
    padding-right: 150px;
}
.publication-card .uk-card-body {
    min-height: 116px;
}
.img-thumbnail {
    padding: 0.25rem;
    background-color: #e8ecf0;
    border: 1px solid #e8ecf0;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
}
.ellipsis:after {
    content: '…';
}
.default-transition {
    transition: all 0.3s ease-out;
}
html {
    font-size: 16px;
    color: #444;
}
a,
span,
li {
    font-weight: inherit;
}
h1,
.uk-h1 {
    color: #255478;
    font-weight: 600;
}
h2,
.uk-h2 {
    color: #255478;
    font-weight: 500;
    font-size: 1.5rem;
}
h3,
h4,
h5,
h6 {
    color: #255478;
}
a,
a:focus,
a:hover {
    text-decoration: none;
}
a:focus,
a:hover {
    color: #2c66a9;
}
strong *:not([class^='uk-font-weight']) {
    font-weight: 600;
}
em {
    color: #444;
}
em *:not([class^='uk-font-style']) {
    font-style: italic;
}
.base-font-smaller {
    font-size: 14px;
}
.line-height-button {
    line-height: 36px;
}

.line-height-small-button {
    line-height: 30px;
}
.pre-wrap {
    white-space: pre-wrap;
}
.spinner {
    margin: 0;
    text-align: center;
    display: inline-block;
    height: 4em;
    font-size: 1em;
    width: auto;
}
.spinner.spinner-size-small {
    height: 2em;
    font-size: 0.5em;
}
.spinner.spinner-size-default {
    height: 4em;
    font-size: 1em;
}
.spinner.spinner-size-large {
    height: 8em;
    font-size: 2em;
}
.spinner > * {
    background-color: #e2e2e2;
    height: 100%;
    width: 0.4em;
    display: inline-block;
    margin: 0 0.1em;
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}
.spinner .rect3 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}
.spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}
.spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}
.spinner .rect6 {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}
.spinner .rect7 {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}
@-webkit-keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
        -webkit-transform: scaleY(0.4);
    }
    20% {
        -webkit-transform: scaleY(1);
    }
}
@keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }
    20% {
        transform: scaleY(1);
        -webkit-transform: scaleY(1);
    }
}
.fixed-link {
    display: inline-block;
    padding-left: 3px;
    padding-right: 3px;
    height: 20px;
    background-color: #dce4eb;
    border: 1px solid #eeeeee;
    color: #3c7fcc;
    text-align: center;
    text-decoration: underline;
}
.fixed-link.deleted {
    color: #dc3545;
}
.uk-font-weight-300,
.uk-font-weight-300 * {
    font-weight: 300;
}
.uk-font-weight-400,
.uk-font-weight-400 * {
    font-weight: 400;
}
.uk-font-weight-600,
.uk-font-weight-600 * {
    font-weight: 600;
}
.uk-font-weight-700,
.uk-font-weight-700 * {
    font-weight: 700;
}
.uk-text-xsmall,
.uk-text-xsmall * {
    font-size: 12px;
}
.uk-text-normal,
.uk-text-normal * {
    font-size: 14px;
}
.uk-text-medium,
.uk-text-medium * {
    font-size: 16px;
}
.uk-text-large,
.uk-text-large * {
    font-size: 1.5rem;
    line-height: 1.5;
}
.uk-text-xlarge,
.uk-text-xlarge * {
    font-size: 35.2px;
}
.uk-text-xxlarge,
.uk-text-xxlarge * {
    font-size: 43.2px;
}
.uk-link-underlined {
    text-decoration: none;
}
.uk-link .a {
    color: #255478 !important;
    text-decoration: none;
}
.uk-link:hover {
    text-decoration: none;
}
.uk-text-capitalize-first {
    display: inline-block;
}
.uk-text-capitalize-first::first-letter {
    text-transform: capitalize;
}
.uk-border {
    border: 1px solid #444;
}
.uk-border-top {
    border-top: 1px solid #444;
}
.uk-border-right {
    border-right: 1px solid #444;
}
.uk-border-bottom {
    border-bottom: 1px solid #444;
}
.uk-border-left {
    border-left: 1px solid #444;
}
.uk-border-2x {
    border-width: 2px;
}
.uk-border-4x {
    border-width: 4px;
}
.uk-border-10x {
    border-width: 10px;
}
.uk-button {
    padding: 0 16px;
    line-height: 35px;
    border-radius: 4px;
    background-color: #dce4eb;
    border: 1px solid #dce4eb;
    text-transform: none;
    font-weight: 400;
    white-space: nowrap;
    transition: all 0.3s ease-out;
    transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
    box-shadow: 0 1px 0 rgba(27, 31, 35, 0.1), inset 0 1px 0 hsla(0, 0%, 100%, 0.08);
}
.uk-button:not[class^='uk-button-large'] {
    padding-left: 20px;
    padding-right: 20px;
}
.uk-button,
.uk-button[uk-icon],
.uk-button.uk-text-color-inherit p {
    color: #444 !important;
}
.uk-button:hover,
.uk-button:focus {
    transition-duration: 0.1s;
    background-color: #bbcad9;
    border-color: #bbcad9;
}
.uk-button:hover,
.uk-button:focus,
.uk-button:hover[uk-icon],
.uk-button:focus[uk-icon],
.uk-button:hover.uk-text-color-inherit p,
.uk-button:focus.uk-text-color-inherit p {
    color: #444 !important;
}
.uk-button.uk-button-line {
    border: 1px solid #dce4eb;
}
.uk-button.uk-disabled {
    border: 1px solid #f6f8fa;
    color: #bbcad9 !important;
    background-color: #f6f8fa;
}
.uk-button-small {
    padding-left: 5px;
    padding-right: 5px;
    line-height: 24px;
    border-radius: 0;
}
.uk-button-wide {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
    line-height: 2em;
}
.uk-icon-image {
    color: inherit;
}
.uk-button .uk-icon-image {
    width: 0.875rem;
    height: 0.875rem;
}
.uk-button-large {
    border-radius: 0;
    line-height: 46px;
}
.uk-icon-image-large,
.uk-button-large .uk-icon-image,
.uk-button-wide .uk-icon-image {
    width: 35px;
    height: 35px;
}
.uk-button-wide .uk-icon-image {
    margin-bottom: 10px;
}
.uk-button-default {
    background-color: #a1c1e6;
    border-color: #a1c1e6;
}
.uk-button-default,
.uk-button-default[uk-icon],
.uk-button-default.uk-text-color-inherit p {
    color: #fff !important;
}
.uk-button-default.uk-button-line {
    background-color: #dce4eb;
    border-color: #3c7fcc;
}
.uk-button-default.uk-button-line,
.uk-button-default.uk-button-line[uk-icon],
.uk-button-default.uk-button-line.uk-text-color-inherit p {
    color: #3c7fcc !important;
}
.uk-button-default:hover,
.uk-button-default:focus {
    background-color: #3c7fcc;
    border-color: #3c7fcc;
}
.uk-button-default:hover,
.uk-button-default:focus,
.uk-button-default:hover[uk-icon],
.uk-button-default:focus[uk-icon],
.uk-button-default:hover.uk-text-color-inherit p,
.uk-button-default:focus.uk-text-color-inherit p {
    color: #fff !important;
}
.uk-button-default:hover.uk-button-line,
.uk-button-default:focus.uk-button-line {
    background-color: #bbcad9;
    border-color: #295f9d;
}
.uk-button-default:hover.uk-button-line,
.uk-button-default:focus.uk-button-line,
.uk-button-default:hover.uk-button-line[uk-icon],
.uk-button-default:focus.uk-button-line[uk-icon],
.uk-button-default:hover.uk-button-line.uk-text-color-inherit p,
.uk-button-default:focus.uk-button-line.uk-text-color-inherit p {
    color: #3c7fcc !important;
}
.uk-button-primary {
    background-color: #3c7fcc;
    border-color: #3c7fcc;
}
.uk-button-primary,
.uk-button-primary[uk-icon],
.uk-button-primary.uk-text-color-inherit p {
    color: #fff !important;
}
.uk-button-primary.uk-button-line {
    background-color: #dce4eb;
    border-color: #3c7fcc;
}
.uk-button-primary.uk-button-line,
.uk-button-primary.uk-button-line[uk-icon],
.uk-button-primary.uk-button-line.uk-text-color-inherit p {
    color: #295f9d !important;
}
.uk-button-primary:hover,
.uk-button-primary:focus {
    background-color: #3172bd;
    border-color: #3172bd;
}
.uk-button-primary:hover,
.uk-button-primary:focus,
.uk-button-primary:hover[uk-icon],
.uk-button-primary:focus[uk-icon],
.uk-button-primary:hover.uk-text-color-inherit p,
.uk-button-primary:focus.uk-text-color-inherit p {
    color: #fff !important;
}
.uk-button-primary:hover.uk-button-line,
.uk-button-primary:focus.uk-button-line {
    background-color: #dce4eb;
    border-color: #2c66a9;
}
.uk-button-primary:hover.uk-button-line,
.uk-button-primary:focus.uk-button-line,
.uk-button-primary:hover.uk-button-line[uk-icon],
.uk-button-primary:focus.uk-button-line[uk-icon],
.uk-button-primary:hover.uk-button-line.uk-text-color-inherit p,
.uk-button-primary:focus.uk-button-line.uk-text-color-inherit p {
    color: #295f9d !important;
}
.uk-button-success {
    background-color: #28a745;
    border-color: #28a745;
}
.uk-button-success,
.uk-button-success[uk-icon],
.uk-button-success.uk-text-color-inherit p {
    color: #fff !important;
}
.uk-button-success.uk-button-line {
    background-color: #dce4eb;
    border-color: #28a745;
}
.uk-button-success.uk-button-line,
.uk-button-success.uk-button-line[uk-icon],
.uk-button-success.uk-button-line.uk-text-color-inherit p {
    color: #28a745 !important;
}
.uk-button-success:hover,
.uk-button-success:focus {
    background-color: #159530;
    border-color: #159530;
}
.uk-button-success:hover,
.uk-button-success:focus,
.uk-button-success:hover[uk-icon],
.uk-button-success:focus[uk-icon],
.uk-button-success:hover.uk-text-color-inherit p,
.uk-button-success:focus.uk-text-color-inherit p {
    color: #fff !important;
}
.uk-button-success:hover.uk-button-line,
.uk-button-success:focus.uk-button-line {
    background-color: #bbcad9;
    border-color: #13872c;
}
.uk-button-success:hover.uk-button-line,
.uk-button-success:focus.uk-button-line,
.uk-button-success:hover.uk-button-line[uk-icon],
.uk-button-success:focus.uk-button-line[uk-icon],
.uk-button-success:hover.uk-button-line.uk-text-color-inherit p,
.uk-button-success:focus.uk-button-line.uk-text-color-inherit p {
    color: #28a745 !important;
}

.uk-button-info {
    background-color: #1a9eab;
    border-color: #1a9eab;
}
.uk-button-info,
.uk-button-info[uk-icon],
.uk-button-info.uk-text-color-inherit p {
    color: #fff !important;
}
.uk-button-info.uk-button-line {
    background-color: #dce4eb;
    border-color: #1a9eab;
}
.uk-button-info.uk-button-line,
.uk-button-info.uk-button-line[uk-icon],
.uk-button-info.uk-button-line.uk-text-color-inherit p {
    color: #1a9eab !important;
}
.uk-button-info:hover,
.uk-button-info:focus {
    background-color: #15828c;
    border-color: #15828c;
}
.uk-button-info:hover,
.uk-button-info:focus,
.uk-button-info:hover[uk-icon],
.uk-button-info:focus[uk-icon],
.uk-button-info:hover.uk-text-color-inherit p,
.uk-button-info:focus.uk-text-color-inherit p {
    color: #fff !important;
}
.uk-button-info:hover.uk-button-line,
.uk-button-info:focus.uk-button-line {
    background-color: #bbcad9;
    border-color: #15828c;
}
.uk-button-info:hover.uk-button-line,
.uk-button-info:focus.uk-button-line,
.uk-button-info:hover.uk-button-line[uk-icon],
.uk-button-info:focus.uk-button-line[uk-icon],
.uk-button-info:hover.uk-button-line.uk-text-color-inherit p,
.uk-button-nfos:focus.uk-button-line.uk-text-color-inherit p {
    color: #1a9eab !important;
}

.uk-button-danger {
    background-color: #dc3545;
    border-color: #dc3545;
}
.uk-button-danger,
.uk-button-danger[uk-icon],
.uk-button-danger.uk-text-color-inherit p {
    color: #fff !important;
}
.uk-button-danger.uk-button-line {
    background-color: #dce4eb;
    border-color: #dc3545;
}
.uk-button-danger.uk-button-line,
.uk-button-danger.uk-button-line[uk-icon],
.uk-button-danger.uk-button-line.uk-text-color-inherit p {
    color: #dc3545 !important;
}
.uk-button-danger:hover,
.uk-button-danger:focus {
    background-color: #a22b36;
    border-color: #a22b36;
}
.uk-button-danger:hover,
.uk-button-danger:focus,
.uk-button-danger:hover[uk-icon],
.uk-button-danger:focus[uk-icon],
.uk-button-danger:hover.uk-text-color-inherit p,
.uk-button-danger:focus.uk-text-color-inherit p {
    color: #fff !important;
}
.uk-button-danger:hover.uk-button-line,
.uk-button-danger:focus.uk-button-line {
    background-color: #bbcad9;
    border-color: #962732;
}
.uk-button-danger:hover.uk-button-line,
.uk-button-danger:focus.uk-button-line,
.uk-button-danger:hover.uk-button-line[uk-icon],
.uk-button-danger:focus.uk-button-line[uk-icon],
.uk-button-danger:hover.uk-button-line.uk-text-color-inherit p,
.uk-button-danger:focus.uk-button-line.uk-text-color-inherit p {
    color: #dc3545 !important;
}
.uk-button-muted {
    background-color: #f6f8fa;
    border-color: #f6f8fa;
    color: #999 !important;
}
.uk-button-muted.uk-button-line {
    background-color: #dce4eb;
    border-color: #f6f8fa;
    color: #999 !important;
}
.uk-button-muted:hover,
.uk-button-muted:focus {
    background-color: #e6ebf1;
    border-color: #e6ebf1;
    color: #999 !important;
}
.uk-button-muted:hover.uk-button-line,
.uk-button-muted:focus.uk-button-line {
    background-color: #bbcad9;
    border-color: #c5d2df;
    color: #999 !important;
}
.uk-button-light {
    background-color: #fafbfc;
    border-color: rgba(27, 31, 35, 0.15);
    color: #24292e;
}
.uk-button-light:hover,
.uk-button-light:focus {
    background-color: #f3f4f6;
    border-color: rgba(27, 31, 35, 0.15);
}
.uk-button:disabled,
.uk-button-danger:disabled,
.uk-button-default:disabled,
.uk-button-primary:disabled,
.uk-button-secondary:disabled,
.uk-button-muted:disabled,
.uk-button:disabled:hover,
.uk-button-danger:disabled:hover,
.uk-button-default:disabled:hover,
.uk-button-primary:disabled:hover,
.uk-button-secondary:disabled:hover,
.uk-button-muted:disabled:hover {
    background-color: transparent;
    border: 1px solid #e5e5e5;
}
.uk-button:disabled,
.uk-button-danger:disabled,
.uk-button-default:disabled,
.uk-button-primary:disabled,
.uk-button-secondary:disabled,
.uk-button-muted:disabled,
.uk-button:disabled:hover,
.uk-button-danger:disabled:hover,
.uk-button-default:disabled:hover,
.uk-button-primary:disabled:hover,
.uk-button-secondary:disabled:hover,
.uk-button-muted:disabled:hover,
.uk-button:disabled[uk-icon],
.uk-button-danger:disabled[uk-icon],
.uk-button-default:disabled[uk-icon],
.uk-button-primary:disabled[uk-icon],
.uk-button-secondary:disabled[uk-icon],
.uk-button-muted:disabled[uk-icon],
.uk-button:disabled:hover[uk-icon],
.uk-button-danger:disabled:hover[uk-icon],
.uk-button-default:disabled:hover[uk-icon],
.uk-button-primary:disabled:hover[uk-icon],
.uk-button-secondary:disabled:hover[uk-icon],
.uk-button-muted:disabled:hover[uk-icon],
.uk-button:disabled.uk-text-color-inherit p,
.uk-button-danger:disabled.uk-text-color-inherit p,
.uk-button-default:disabled.uk-text-color-inherit p,
.uk-button-primary:disabled.uk-text-color-inherit p,
.uk-button-secondary:disabled.uk-text-color-inherit p,
.uk-button-muted:disabled.uk-text-color-inherit p,
.uk-button:disabled:hover.uk-text-color-inherit p,
.uk-button-danger:disabled:hover.uk-text-color-inherit p,
.uk-button-default:disabled:hover.uk-text-color-inherit p,
.uk-button-primary:disabled:hover.uk-text-color-inherit p,
.uk-button-secondary:disabled:hover.uk-text-color-inherit p,
.uk-button-muted:disabled:hover.uk-text-color-inherit p {
    color: #999 !important;
}
.uk-button-width-small {
    padding-left: 10px;
    padding-right: 10px;
}
.uk-button-inline-group .uk-button {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
.uk-button-inline-group .uk-input {
    border-left: none;
}
.uk-button-group .uk-button {
    border-radius: 0;
}
.uk-button-group > .uk-button {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}
.uk-button-group > .uk-inline > .uk-button {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}
.uk-button-group > .uk-button:not(:first-child),
.uk-button-group > div:not(:first-child) .uk-button {
    margin-left: -5px;
    position: relative;
}
.uk-button-group > .uk-button:not(:first-child):after,
.uk-button-group > div:not(:first-child) .uk-button:after {
    content: '';
    display: block;
    top: 0;
    left: -1px;
    width: 1px;
    height: 100%;
    background-color: #fff;
}
.uk-button-group-small .uk-button-small {
    padding-left: 8px;
    padding-right: 8px;
}
.uk-button-group-small .uk-button-small .uk-icon {
    margin-bottom: 4px;
}
.uk-button-group-small .uk-button-icon {
    padding-left: 6px;
    padding-right: 6px;
}
.uk-button[uk-icon],
.uk-button-icon {
    background-color: transparent;
    border: none !important;
    vertical-align: middle;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 7px;
    color: #8e8e92 !important;
    border-radius: 0 !important;
    box-shadow: none;
}
.uk-button[uk-icon]:hover,
.uk-button-icon:hover {
    background-color: transparent;
    color: #4f4f52 !important;
}
.uk-button[uk-icon]:focus,
.uk-button-icon:focus {
    background-color: transparent !important;
}
.uk-button:hover[uk-icon] {
    color: #4f4f52 !important;
}
.uk-button.uk-text-danger[uk-icon] {
    color: #dc3545 !important;
}
.uk-button.uk-text-danger[uk-icon]:hover {
    color: #962732 !important;
}
.uk-navbar-item,
.uk-navbar-nav > li > a,
.uk-navbar-toggle {
    min-height: 50px;
}
.uk-nav > li > a {
    margin-bottom: 10px;
}
.uk-nav-contrast .uk-navbar-item,
.uk-nav-contrast > li > a,
.uk-nav-contrast .uk-navbar-toggle {
    color: #fff;
}
.uk-nav-contrast .uk-navbar-item:hover,
.uk-nav-contrast > li > a:hover,
.uk-nav-contrast .uk-navbar-toggle:hover {
    color: #fff;
}
.uk-nav-contrast .uk-navbar-item:hover,
.uk-nav-contrast > li > a:hover,
.uk-nav-contrast .uk-navbar-toggle:hover,
.uk-nav-contrast .uk-navbar-item.active,
.uk-nav-contrast > li > a.active,
.uk-nav-contrast .uk-navbar-toggle.active {
    position: relative;
}
.uk-nav-contrast .uk-navbar-item:hover:before,
.uk-nav-contrast > li > a:hover:before,
.uk-nav-contrast .uk-navbar-toggle:hover:before,
.uk-nav-contrast .uk-navbar-item.active:before,
.uk-nav-contrast > li > a.active:before,
.uk-nav-contrast .uk-navbar-toggle.active:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: -40px;
    width: 5px;
    height: 100%;
    background-color: #fff;
}
.uk-navbar-contrast .uk-navbar-nav > li > a {
    color: #fff;
}
.uk-navbar-contrast .uk-navbar-nav > li:hover > a,
.uk-navbar-contrast .uk-navbar-nav > li > a.uk-open,
.uk-navbar-contrast .uk-navbar-nav > li > a:focus {
    color: #fff;
    background-color: #295f9d;
}
.uk-navbar-contrast .uk-navbar-nav > li.uk-active > a,
.uk-navbar-contrast .uk-navbar-nav > li > a:active,
.uk-navbar-contrast .uk-navbar-nav > li.uk-active > a:hover,
.uk-navbar-contrast .uk-navbar-nav > li > a:active:hover {
    color: #fff;
    background-color: #295f9d;
}
.uk-navbar-item,
.uk-navbar-toggle {
    color: #fff;
}
.uk-navbar-item:hover,
.uk-navbar-toggle:hover,
.uk-navbar-item:focus,
.uk-navbar-toggle:focus {
    color: #fff;
    background-color: #295f9d;
    text-decoration: none;
}
.uk-navbar-dropdown {
    width: 250px;
    padding: 0;
}
.uk-navbar-dropdown > li > a,
.uk-dropdown-nav > li > a {
    margin-bottom: 0;
    padding: 8px 20px;
    color: #3c7fcc;
}
.uk-navbar-dropdown > li > a:hover,
.uk-dropdown-nav > li > a:hover,
.uk-navbar-dropdown > li > a:focus,
.uk-dropdown-nav > li > a:focus {
    background-color: #f3f3f3;
    color: #1c416c;
}
.uk-navbar-dropdown > li.uk-active > a,
.uk-dropdown-nav > li.uk-active > a {
    background-color: #c9dcf1;
    color: #1c416c;
}
.uk-navbar-dropdown-nav > li > a {
    margin-bottom: 0;
    padding: 5px 20px;
    color: #3c7fcc;
}
.uk-navbar-dropdown-nav > li > a:hover,
.uk-navbar-dropdown-nav > li > a:focus,
.uk-navbar-dropdown-nav > li.uk-active > a {
    background-color: #f6f8fa;
    color: #3c7fcc;
}
.uk-nav-header {
    padding: 5px 20px;
    font-weight: 400;
}
.uk-nav-divider + .uk-nav-header:not(:first-child) {
    margin-top: 5px;
}
.uk-navbar-small .uk-navbar-item,
.uk-navbar-small .uk-navbar-nav > li > a,
.uk-navbar-small .uk-navbar-toggle {
    min-height: 40px;
}
.uk-dropdown-nav .uk-comment .uk-comment-header {
    margin-bottom: 0;
}
.uk-dropdown-nav .uk-comment .uk-comment-title {
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1;
}
.uk-dropdown-nav .uk-comment .uk-subnav > * {
    padding-left: 0;
    padding-right: 20px;
}
.uk-dropdown {
    padding: 0;
    border: 1px solid #f3f3f3;
    border-radius: 4px;
    box-shadow: 0 8px 24px rgba(149, 157, 165, 0.2);
}
.uk-legend {
    margin-bottom: 25px;
    font-weight: 400;
}
.uk-input,
.uk-select {
    height: 37px !important;
}
.uk-input,
.uk-select,
.uk-textarea {
    border-radius: 4px;
    font-size: 0.75rem;
    color: #255478 !important;
}
.uk-input:not[disabled]:hover,
.uk-select:not[disabled]:hover,
.uk-textarea:not[disabled]:hover {
    border-color: #e8ecf0;
}
.uk-input:focus,
.uk-select:focus,
.uk-textarea:focus {
    border-color: #e8ecf0;
}
textarea.uk-input,
textarea.uk-textarea {
    resize: none;
    line-height: inherit;
}
.uk-checkbox:focus,
.uk-radio:focus {
    border-color: #3c7fcc;
}
.uk-checkbox:checked:not(:disabled),
.uk-checkbox:indeterminate:not(:disabled),
.uk-radio:checked:not(:disabled),
.uk-checkbox:checked:not(:disabled):focus,
.uk-checkbox:indeterminate:not(:disabled):focus,
.uk-radio:checked:not(:disabled):focus {
    background-color: #3c7fcc;
}
.uk-form-label {
    text-transform: uppercase;
    color: #255478;
    font-weight: 600;
    margin-bottom: 0;
}
.uk-form-width-date {
    width: 150px;
}
.uk-form-width-xlarge {
    width: 850px;
    max-width: 85%;
}
.uk-form-text-small-inline {
    display: inline;
    height: 30px;
    line-height: 30px;
}
.uk-form-text-small-inline-block {
    display: inline-block;
    height: 30px;
    line-height: 30px;
}
.uk-form-text-inline {
    display: inline;
    height: 40px;
    line-height: 40px;
}
.uk-form-text-inline-block {
    display: inline-block;
    height: 40px;
    line-height: 40px;
}
.uk-form-icon.uk-form-icon-flip {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.uk-form-danger.uk-input:disabled,
.uk-form-danger.uk-select:disabled,
.uk-form-danger.uk-textarea:disabled {
    background-color: #f9e6e9;
    border-color: #dc3545;
}
.uk-table {
    margin-bottom: 10px;
}
.uk-table th,
.uk-table td {
    padding: 12px 10px;
}
.uk-table-small td,
.uk-table-small th {
    padding: 6px 8px;
    font-size: 0.75rem;
}
.uk-table-small td address,
.uk-table-small th address,
.uk-table-small td dl,
.uk-table-small th dl,
.uk-table-small td fieldset,
.uk-table-small th fieldset,
.uk-table-small td figure,
.uk-table-small th figure,
.uk-table-small td ol,
.uk-table-small th ol,
.uk-table-small td p,
.uk-table-small th p,
.uk-table-small td pre,
.uk-table-small th pre,
.uk-table-small td ul,
.uk-table-small th ul {
    margin-bottom: 10px;
}
.uk-table-striped tbody tr:last-of-type,
.uk-table-striped > tr:last-of-type {
    border-bottom: 1px solid #e5e5e5;
}
.uk-table td > :last-child:last-child {
    margin-bottom: 0;
}
.uk-card-muted {
    background-color: #f6f8fa;
    border: 2px solid #c5d2df;
    color: #999 !important;
}
.uk-card-danger {
    background-color: #f9e6e9;
    border: 2px solid #efbdc5;
    color: #dc3545 !important;
}
.uk-card-primary.uk-card-body,
.uk-card-primary > :not([class*='uk-card-media']),
.uk-card-secondary.uk-card-body,
.uk-card-secondary > :not([class*='uk-card-media']),
.uk-light,
.uk-offcanvas-bar,
.uk-overlay-primary,
.uk-section-primary:not(.uk-preserve-color),
.uk-section-secondary:not(.uk-preserve-color),
.uk-tile-primary:not(.uk-preserve-color),
.uk-tile-secondary:not(.uk-preserve-color) {
    color: #ffffff;
}
.uk-alert-success {
    background-color: #dcf5d2;
    color: #28a745;
}
.uk-alert-primary {
    color: #3c7fcc;
}
.uk-display-inline-flex {
    display: inline-flex;
}
.uk-cursor-pointer {
    cursor: pointer;
}
.uk-cursor-default {
    cursor: default !important;
}
.uk-cursor-initial {
    cursor: initial;
}
.uk-height-xlarge {
    height: 650px;
}
.uk-overflow-x-hidden {
    overflow-x: hidden;
}
.uk-overflow-x-auto {
    overflow-x: auto;
}
.uk-overflow-x-scroll {
    overflow-x: scroll;
}
.uk-overflow-x-visible {
    overflow-x: visible;
}
.uk-overflow-y-hidden {
    overflow-y: hidden;
}
.uk-overflow-y-auto {
    overflow-y: auto;
}
.uk-overflow-y-scroll {
    overflow-y: scroll;
}
.uk-overflow-y-visible {
    overflow-y: visible;
}
.uk-vertical-align-initial {
    vertical-align: initial;
}
.uk-divider-vertical {
    display: inline;
    width: 1px;
    height: 100px;
    margin-bottom: 0;
    margin-left: 20px;
    margin-right: 20px;
    border-left: 1px solid #e5e5e5;
}
.uk-width-20-fixed {
    width: 20px;
}
.uk-width-90-fixed {
    width: 90px;
}
.uk-width-280-fixed {
    width: 280px;
}
.uk-width-850-fixed {
    width: 850px;
}
.uk-width-968-fixed {
    width: 968px;
}
.uk-width-1-10 {
    width: 10%;
}
.uk-width-3-10 {
    width: 30%;
}
.uk-width-7-10 {
    width: 70%;
}
.uk-width-9-10 {
    width: 90%;
}
.uk-background-theme,
.uk-background-theme-hover:hover {
    background-color: #dce4eb;
}
.uk-background-theme-light,
.uk-background-theme-light-hover:hover,
.uk-background-hover-parent:hover .uk-background-theme-light-child {
    background-color: #f3f5f7 !important;
}
.uk-background-theme-dark,
.uk-background-theme-dark-hover:hover,
.uk-background-hover-parent:hover .uk-background-theme-dark-child {
    background-color: #e8ecf0;
}
.uk-background-link,
.uk-background-link-hover:hover {
    background-color: #006ca6;
}
.uk-background-active,
.uk-background-active-hover:hover {
    background-color: #006ca6;
}
.uk-background-primary,
.uk-background-primary-hover:hover {
    background-color: #006ca6;
}
.uk-background-primary-extra-light,
.uk-background-primary-extra-light-hover:hover,
.uk-background-hover-parent:hover .uk-background-primary-extra-light-child {
    background-color: #fcfbfc;
}
.uk-background-primary-light,
.uk-background-primary-light-hover:hover,
.uk-background-hover-parent:hover .uk-background-primary-light-child {
    background-color: #fcfbfc;
}
.uk-background-primary-dark,
.uk-background-primary-dark-hover:hover {
    background-color: #dc3545;
}
.uk-background-success,
.uk-background-success-hover:hover {
    background-color: #00abb4;
}
.uk-background-success-light,
.uk-background-success-light-hover:hover {
    background-color: #dcf5d282;
}
.uk-background-warning,
.uk-background-warning-hover:hover {
    background-color: #f19f4d;
}
.uk-background-warning-light,
.uk-background-warning-light-hover:hover {
    background-color: #faebd8;
}
.uk-background-danger,
.uk-background-danger-hover:hover {
    background-color: #dc3545;
}
.uk-background-danger-light,
.uk-background-danger-light-hover:hover {
    background-color: #ffefef;
}
.uk-background-special,
.uk-background-special-hover:hover {
    background-color: #e4eef9;
}
.uk-background-white,
.uk-background-white-hover:hover {
    background-color: #fff;
}
.uk-background-light,
.uk-background-light-hover:hover {
    background-color: #999;
}
.uk-background-secondary,
.uk-background-secondary-hover:hover {
    background-color: #faf9fc;
}
.uk-background-neutral,
.uk-background-neutral-hover:hover {
    background-color: #e2e2e2;
}
.uk-background-dark,
.uk-background-dark-hover:hover {
    background-color: #222d32;
}
.uk-border-theme,
.uk-border-theme-hover:hover {
    border-color: #dce4eb;
}
.uk-border-theme-light,
.uk-border-theme-light-hover:hover {
    border-color: #e1e4e8;
}
.uk-border-theme-dark,
.uk-border-theme-dark-hover:hover {
    border-color: #bbcad9;
}
.uk-border-link,
.uk-border-link-hover:hover {
    border-color: #006ca6;
}
.uk-border-active,
.uk-border-active-hover:hover {
    border-color: #006ca6;
}
.uk-border-primary,
.uk-border-primary-hover:hover {
    border-color: #006ca6;
}
.uk-border-primary-light,
.uk-border-primary-light-hover:hover {
    border-color: #a1c1e6;
}
.uk-border-primary-dark,
.uk-border-primary-dark-hover:hover {
    border-color: #006ca6;
}
.uk-border-success,
.uk-border-success-hover:hover {
    border-color: #00abb4;
}
.uk-border-warning,
.uk-border-warning-hover:hover {
    border-color: #ff9434;
}
.uk-border-danger,
.uk-border-danger-hover:hover {
    border-color: #dc3545;
}
.uk-border-danger-light,
.uk-border-danger-light-hover:hover {
    border-color: #ffefef;
}
.uk-border-white,
.uk-border-white-hover:hover {
    border-color: #fff;
}
.uk-border-dark,
.uk-border-dark-hover:hover {
    border-color: #222d32;
}
.uk-border-muted,
.uk-border-muted-hover:hover {
    border-color: #999;
}
.uk-text-color-default,
.uk-text-color-default-hover:hover,
.uk-text-color-default[uk-icon],
.uk-text-color-default-hover:hover[uk-icon],
.uk-text-color-default.uk-text-color-inherit p,
.uk-text-color-default-hover:hover.uk-text-color-inherit p {
    color: #444 !important;
}
.uk-text-light,
.uk-text-light-hover:hover,
.uk-text-light[uk-icon],
.uk-text-light-hover:hover[uk-icon],
.uk-text-light.uk-text-color-inherit p,
.uk-text-light-hover:hover.uk-text-color-inherit p {
    color: #848484 !important;
}
.uk-text-theme,
.uk-text-theme-hover:hover,
.uk-text-theme[uk-icon],
.uk-text-theme-hover:hover[uk-icon],
.uk-text-theme.uk-text-color-inherit p,
.uk-text-theme-hover:hover.uk-text-color-inherit p {
    color: #dce4eb !important;
}
.uk-text-theme-light,
.uk-text-theme-light-hover:hover,
.uk-text-theme-light[uk-icon],
.uk-text-theme-light-hover:hover[uk-icon],
.uk-text-theme-light.uk-text-color-inherit p,
.uk-text-theme-light-hover:hover.uk-text-color-inherit p {
    color: #f6f8fa !important;
}
.uk-text-theme-extra-light,
.uk-text-theme-extra-light-hover:hover,
.uk-text-theme-extra-light[uk-icon],
.uk-text-theme-extra-light-hover:hover[uk-icon],
.uk-text-theme-extra-light.uk-text-color-inherit p,
.uk-text-theme-extra-light-hover:hover.uk-text-color-inherit p {
    color: #c3c3c3 !important;
}
.uk-text-theme-dark,
.uk-text-theme-dark-hover:hover,
.uk-text-theme-dark[uk-icon],
.uk-text-theme-dark-hover:hover[uk-icon],
.uk-text-theme-dark.uk-text-color-inherit p,
.uk-text-theme-dark-hover:hover.uk-text-color-inherit p {
    color: #bbcad9 !important;
}
.uk-text-link,
.uk-text-link-hover:hover,
.uk-link,
.uk-link-hover:hover,
.uk-text-link[uk-icon],
.uk-text-link-hover:hover[uk-icon],
.uk-link[uk-icon],
.uk-link-hover:hover[uk-icon],
.uk-text-link.uk-text-color-inherit p,
.uk-text-link-hover:hover.uk-text-color-inherit p,
.uk-link.uk-text-color-inherit p,
.uk-link-hover:hover.uk-text-color-inherit p {
    color: #255478 !important;
}
.uk-text-active,
.uk-text-active-hover:hover,
.uk-text-active[uk-icon],
.uk-text-active-hover:hover[uk-icon],
.uk-text-active.uk-text-color-inherit p,
.uk-text-active-hover:hover.uk-text-color-inherit p {
    color: #255478 !important;
}
.uk-text-primary,
.uk-text-primary-hover:hover,
.uk-text-primary[uk-icon],
.uk-text-primary-hover:hover[uk-icon],
.uk-text-primary.uk-text-color-inherit p,
.uk-text-primary-hover:hover.uk-text-color-inherit p {
    color: #255478 !important;
    font-size: 16px;
    font-weight: 600;
}
.uk-text-primary-light,
.uk-text-primary-light-hover:hover,
.uk-text-primary-light[uk-icon],
.uk-text-primary-light-hover:hover[uk-icon],
.uk-text-primary-light.uk-text-color-inherit p,
.uk-text-primary-light-hover:hover.uk-text-color-inherit p {
    color: #a1c1e6 !important;
}
.uk-text-primary-dark,
.uk-text-primary-dark-hover:hover,
.uk-text-primary-dark[uk-icon],
.uk-text-primary-dark-hover:hover[uk-icon],
.uk-text-primary-dark.uk-text-color-inherit p,
.uk-text-primary-dark-hover:hover.uk-text-color-inherit p {
    color: #255478 !important;
}
.uk-text-success,
.uk-text-success-hover:hover,
.uk-text-success[uk-icon],
.uk-text-success-hover:hover[uk-icon],
.uk-text-success.uk-text-color-inherit p,
.uk-text-success-hover:hover.uk-text-color-inherit p {
    color: #00abb4 !important;
}
.uk-text-warning,
.uk-text-warning-hover:hover,
.uk-text-warning[uk-icon],
.uk-text-warning-hover:hover[uk-icon],
.uk-text-warning.uk-text-color-inherit p,
.uk-text-warning-hover:hover.uk-text-color-inherit p {
    color: #f19f4d !important;
}
.uk-text-danger,
.uk-text-danger-hover:hover,
.uk-text-danger[uk-icon],
.uk-text-danger-hover:hover[uk-icon],
.uk-text-danger.uk-text-color-inherit p,
.uk-text-danger-hover:hover.uk-text-color-inherit p {
    color: #dc3545 !important;
}
.uk-text-dark,
.uk-text-dark-hover:hover,
.uk-text-dark[uk-icon],
.uk-text-dark-hover:hover[uk-icon],
.uk-text-dark.uk-text-color-inherit p,
.uk-text-dark-hover:hover.uk-text-color-inherit p {
    color: #255478 !important;
}
.uk-text-white,
.uk-text-white-hover-hover:hover,
.uk-text-white[uk-icon],
.uk-text-white-hover-hover:hover[uk-icon],
.uk-text-white.uk-text-color-inherit p,
.uk-text-white-hover-hover:hover.uk-text-color-inherit p {
    color: #fff !important;
}
.uk-text-disabled,
.uk-text-disabled-hover:hover,
.uk-text-disabled[uk-icon],
.uk-text-disabled-hover:hover[uk-icon],
.uk-text-disabled.uk-text-color-inherit p,
.uk-text-disabled-hover:hover.uk-text-color-inherit p {
    color: #a7aebb !important;
}
.uk-margin-xsmall {
    margin: 6px;
}
.uk-margin-xsmall-top {
    margin-top: 6px;
}
.uk-margin-xsmall-right {
    margin-right: 6px;
}
.uk-margin-xsmall-bottom {
    margin-bottom: 6px;
}
.uk-margin-xsmall-left {
    margin-left: 6px;
}
.uk-margin-xsmall-vertical {
    margin-top: 6px;
    margin-bottom: 6px;
}
.uk-margin-xsmall-horizontal {
    margin-left: 6px;
    margin-right: 6px;
}
.uk-margin-small-horizontal {
    margin-left: 10px;
    margin-right: 10px;
}
.uk-margin-small-vertical {
    margin-top: 10px;
    margin-bottom: 10px;
}
.uk-margin-xlarge {
    margin: 110px;
}
.uk-margin-xlarge-top {
    margin-top: 110px;
}
.uk-margin-xlarge-right {
    margin-right: 110px;
}
.uk-margin-xlarge-bottom {
    margin-bottom: 110px;
}
.uk-margin-xlarge-left {
    margin-left: 110px;
}
.uk-margin-xlarge-horizontal {
    margin-left: 110px;
    margin-right: 110px;
}
.uk-margin-xlarge-vertical {
    margin-top: 110px;
    margin-bottom: 110px;
}
.uk-padding-xsmall {
    padding: 6px;
}
.uk-padding-xsmall-top {
    padding-top: 6px;
}
.uk-padding-xsmall-right {
    padding-right: 6px;
}
.uk-padding-xsmall-bottom {
    padding-bottom: 6px;
}
.uk-padding-xsmall-left {
    padding-left: 6px;
}
.uk-padding-xsmall-vertical {
    padding-top: 6px;
    padding-bottom: 6px;
}
.uk-padding-xsmall-horizontal {
    padding-left: 6px;
    padding-right: 6px;
}
.uk-padding-small-top {
    padding-top: 10px;
}
.uk-padding-small-right {
    padding-right: 10px;
}
/* .uk-text-muted {
    font-weight: 400;
    color: #255478!important;
} */
.uk-padding-small-bottom {
    padding-bottom: 10px;
}
.uk-padding-small-left {
    padding-left: 10px;
}
.uk-padding-small-vertical {
    padding-top: 10px;
    padding-bottom: 10px;
}
.uk-padding-small-horizontal {
    padding-left: 10px;
    padding-right: 10px;
}
.uk-padding-top {
    padding-top: 20px;
}
.uk-padding-right {
    padding-right: 20px;
}
.uk-padding-bottom {
    padding-bottom: 20px;
}
.uk-padding-left {
    padding-left: 20px;
}
.uk-padding-vertical {
    padding-top: 20px;
    padding-bottom: 20px;
}
.uk-padding-horizontal {
    padding-left: 20px;
    padding-right: 20px;
}
.uk-padding-medium {
    padding: 40px;
}
.uk-padding-medium-top {
    padding-top: 40px;
}
.uk-padding-medium-right {
    padding-right: 40px;
}
.uk-padding-medium-bottom {
    padding-bottom: 40px;
}
.uk-padding-medium-left {
    padding-left: 40px;
}
.uk-padding-medium-vertical {
    padding-top: 40px;
    padding-bottom: 40px;
}
.uk-padding-medium-horizontal {
    padding-left: 40px;
    padding-right: 40px;
}
.uk-padding-large {
    padding: 70px;
}
.uk-padding-large-top {
    padding-top: 70px;
}
.uk-padding-large-right {
    padding-right: 70px;
}
.uk-padding-large-bottom {
    padding-bottom: 70px;
}
.uk-padding-large-left {
    padding-left: 70px;
}
.uk-padding-large-vertical {
    padding-top: 70px;
    padding-bottom: 70px;
}
.uk-padding-large-horizontal {
    padding-left: 70px;
    padding-right: 70px;
}
.uk-padding-xlarge {
    padding: 110px;
}
.uk-padding-xlarge-top {
    padding-top: 110px;
}
.uk-padding-xlarge-right {
    padding-right: 110px;
}
.uk-padding-xlarge-bottom {
    padding-bottom: 110px;
}
.uk-padding-xlarge-left {
    padding-left: 110px;
}
.uk-padding-xlarge-vertical {
    padding-top: 110px;
    padding-bottom: 110px;
}
.uk-padding-xlarge-horizontal {
    padding-left: 110px;
    padding-right: 110px;
}
@media (min-width: 1600px) {
    .uk-margin-remove-top-xl {
        margin-top: 0 !important;
    }
}
.uk-modal,
.ReactModal__Overlay {
    z-index: 1050 !important;
}
.uk-logo img {
    height: 38px !important;
}
.uk-button-link {
    padding: 0;
    font-size: 16px;
    line-height: 1.5;
    background: none;
    border: 0;
    color: #255478 !important;
    box-shadow: none;
}
.uk-button-link:hover {
    background: none;
    color: #255478 !important;
    text-decoration: underline;
}
.uk-button-link:focus {
    background: none;
    color: #255478 !important;
    text-decoration: none;
}
.uk-button-link:disabled,
.uk-button-link:disabled:hover {
    border: 0;
    text-decoration: none;
}
.uk-button-link-active {
    font-weight: 600;
}
.uk-button-link-active:hover {
    text-decoration: none;
}
.uk-text-strike .uk-table {
    text-decoration: line-through;
}
.uk-margin-left-2 {
    font-weight: 600 !important;
    font-size: 12px;
}
.uk-margin-left-3 {
    margin-left: 20px;
    font-weight: 5006 !important;
    font-size: 12px;
}
.uk-margin-left-4 {
    margin-left: 40px;
    font-weight: 5006 !important;
    font-size: 12px;
}
.uk-margin-left-5 {
    margin-left: 60px;
    font-weight: 5006 !important;
    font-size: 12px;
}
.uk-margin-left-6 {
    margin-left: 80px;
    font-weight: 5006 !important;
    font-size: 12px;
}
.uk-label {
    line-height: 1;
    padding: 4px 5px;
    font-weight: 500;
}
.uk-label-success {
    background-color: #28a745;
}
.uk-badge-small {
    font-size: 10px;
    border-radius: 2em;
    min-width: 20px;
    height: 20px;
}
.uk-badge-danger {
    background-color: #a90000;
}
/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
}
/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
}
/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #a90000;
}
/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}
/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}
/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}
/* General sidebar styles */
.bm-menu {
    color: #255478 !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    background-color: white !important;
}
.comment-item {
    border: 1px solid #edebe9;
    border-radius: 4px;
}
/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}
/* Wrapper for item list */
.bm-item-list {
    padding: 0.8em;
}
.bm-item-list h4 {
    color: #255478 !important;
    font-size: 16px;
    font-weight: 600;
}
.bm-item-list h5 {
    color: #255478 !important;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}
/*.bm-item-list .mb-1 {*/
/*    color: #255478 !important;*/
/*    font-size: 14px;*/
/*    font-weight: 400 !important;*/
/*}*/

.bm-item-list:focus {
    outline: none;
}
/* Individual item */
.bm-item {
    display: inline-block;
}
.bm-item:focus {
    outline: none;
}
/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}
@font-face {
    font-family: 'Poppins' !important;
    src: url('fonts/icomoon.eot?48ctnu');
    src: url('fonts/icomoon.eot?48ctnu#iefix') format('embedded-opentype'),
        url('fonts/icomoon.ttf?48ctnu') format('truetype'), url('fonts/icomoon.woff?48ctnu') format('woff'),
        url('fonts/icomoon.svg?48ctnu#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'Poppins' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-search:before {
    content: '\f002';
}
.icon-star:before {
    content: '\f005';
}
.icon-star-o:before {
    content: '\f006';
}
.icon-user:before {
    content: '\f007';
}
.icon-th-large:before {
    content: '\f009';
}
.icon-th:before {
    content: '\f00a';
}
.icon-th-list:before {
    content: '\f00b';
}
.icon-check:before {
    content: '\f00c';
}
.icon-close:before {
    content: '\f00d';
}
.icon-remove:before {
    content: '\f00d';
}
.icon-times:before {
    content: '\f00d';
}
.icon-search-plus:before {
    content: '\f00e';
}
.icon-search-minus:before {
    content: '\f010';
}
.icon-cog:before {
    content: '\f013';
}
.icon-gear:before {
    content: '\f013';
}
.icon-download:before {
    content: '\f019';
}
.icon-repeat:before {
    content: '\f01e';
}
.icon-rotate-right:before {
    content: '\f01e';
}
.icon-refresh:before {
    content: '\f021';
}
.icon-tag:before {
    content: '\f02b';
}
.icon-tags:before {
    content: '\f02c';
}
.icon-bookmark:before {
    content: '\f02e';
}
.icon-print:before {
    content: '\f02f';
}
.icon-font:before {
    content: '\f031';
}
.icon-bold:before {
    content: '\f032';
}
.icon-italic:before {
    content: '\f033';
}
.icon-text-height:before {
    content: '\f034';
}
.icon-text-width:before {
    content: '\f035';
}
.icon-align-left:before {
    content: '\f036';
}
.icon-align-center:before {
    content: '\f037';
}
.icon-align-right:before {
    content: '\f038';
}
.icon-align-justify:before {
    content: '\f039';
}
.icon-list:before {
    content: '\f03a';
}
.icon-dedent:before {
    content: '\f03b';
}
.icon-outdent:before {
    content: '\f03b';
}
.icon-indent:before {
    content: '\f03c';
}
.icon-image:before {
    content: '\f03e';
}
.icon-photo:before {
    content: '\f03e';
}
.icon-picture-o:before {
    content: '\f03e';
}
.icon-pencil:before {
    content: '\f040';
}
.icon-arrows:before {
    content: '\f047';
}
.icon-play:before {
    content: '\f04b';
}
.icon-pause:before {
    content: '\f04c';
}
.icon-stop:before {
    content: '\f04d';
}
.icon-chevron-left:before {
    content: '\f053';
}
.icon-chevron-right:before {
    content: '\f054';
}
.icon-check-circle:before {
    content: '\f058';
}
.icon-question-circle:before {
    content: '\f059';
}
.icon-info-circle:before {
    content: '\f05a';
}
.icon-arrow-left:before {
    content: '\f060';
}
.icon-arrow-right:before {
    content: '\f061';
}
.icon-arrow-up:before {
    content: '\f062';
}
.icon-arrow-down:before {
    content: '\f063';
}
.icon-mail-forward:before {
    content: '\f064';
}
.icon-share:before {
    content: '\f064';
}
.icon-expand:before {
    content: '\f065';
}
.icon-compress:before {
    content: '\f066';
}
.icon-exclamation-circle:before {
    content: '\f06a';
}
.icon-eye:before {
    content: '\f06e';
}
.icon-eye-slash:before {
    content: '\f070';
}
.icon-exclamation-triangle:before {
    content: '\f071';
}
.icon-warning:before {
    content: '\f071';
}
.icon-calendar:before {
    content: '\f073';
}
.icon-comment:before {
    content: '\f075';
}
.icon-chevron-up:before {
    content: '\f077';
}
.icon-chevron-down:before {
    content: '\f078';
}
.icon-folder:before {
    content: '\f07b';
}
.icon-folder-open:before {
    content: '\f07c';
}
.icon-arrows-v:before {
    content: '\f07d';
}
.icon-arrows-h:before {
    content: '\f07e';
}
.icon-bar-chart:before {
    content: '\f080';
}
.icon-bar-chart-o:before {
    content: '\f080';
}
.icon-cogs:before {
    content: '\f085';
}
.icon-gears:before {
    content: '\f085';
}
.icon-comments:before {
    content: '\f086';
}
.icon-upload:before {
    content: '\f093';
}
.icon-phone:before {
    content: '\f095';
}
.icon-bookmark-o:before {
    content: '\f097';
}
.icon-unlock:before {
    content: '\f09c';
}
.icon-arrow-circle-left:before {
    content: '\f0a8';
}
.icon-arrow-circle-right:before {
    content: '\f0a9';
}
.icon-arrow-circle-up:before {
    content: '\f0aa';
}
.icon-arrow-circle-down:before {
    content: '\f0ab';
}
.icon-globe:before {
    content: '\f0ac';
}
.icon-wrench:before {
    content: '\f0ad';
}
.icon-arrows-alt:before {
    content: '\f0b2';
}
.icon-group:before {
    content: '\f0c0';
}
.icon-users:before {
    content: '\f0c0';
}
.icon-chain:before {
    content: '\f0c1';
}
.icon-link:before {
    content: '\f0c1';
}
.icon-cloud:before {
    content: '\f0c2';
}
.icon-cut:before {
    content: '\f0c4';
}
.icon-scissors:before {
    content: '\f0c4';
}
.icon-copy:before {
    content: '\f0c5';
}
.icon-files-o:before {
    content: '\f0c5';
}
.icon-paperclip:before {
    content: '\f0c6';
}
.icon-floppy-o:before {
    content: '\f0c7';
}
.icon-save:before {
    content: '\f0c7';
}
.icon-bars:before {
    content: '\f0c9';
}
.icon-navicon:before {
    content: '\f0c9';
}
.icon-reorder:before {
    content: '\f0c9';
}
.icon-list-ul:before {
    content: '\f0ca';
}
.icon-list-ol:before {
    content: '\f0cb';
}
.icon-strikethrough:before {
    content: '\f0cc';
}
.icon-underline:before {
    content: '\f0cd';
}
.icon-table:before {
    content: '\f0ce';
}
.icon-caret-down:before {
    content: '\f0d7';
}
.icon-caret-up:before {
    content: '\f0d8';
}
.icon-caret-left:before {
    content: '\f0d9';
}
.icon-caret-right:before {
    content: '\f0da';
}
.icon-sort:before {
    content: '\f0dc';
}
.icon-unsorted:before {
    content: '\f0dc';
}
.icon-sort-desc:before {
    content: '\f0dd';
}
.icon-sort-down:before {
    content: '\f0dd';
}
.icon-sort-asc:before {
    content: '\f0de';
}
.icon-sort-up:before {
    content: '\f0de';
}
.icon-envelope:before {
    content: '\f0e0';
}
.icon-rotate-left:before {
    content: '\f0e2';
}
.icon-undo:before {
    content: '\f0e2';
}
.icon-cloud-download:before {
    content: '\f0ed';
}
.icon-cloud-upload:before {
    content: '\f0ee';
}
.icon-bell:before {
    content: '\f0f3';
}
.icon-file-text-o:before {
    content: '\f0f6';
}
.icon-angle-double-left:before {
    content: '\f100';
}
.icon-angle-double-right:before {
    content: '\f101';
}
.icon-angle-double-up:before {
    content: '\f102';
}
.icon-angle-double-down:before {
    content: '\f103';
}
.icon-angle-left:before {
    content: '\f104';
}
.icon-angle-right:before {
    content: '\f105';
}
.icon-angle-up:before {
    content: '\f106';
}
.icon-angle-down:before {
    content: '\f107';
}
.icon-desktop:before {
    content: '\f108';
}
.icon-laptop:before {
    content: '\f109';
}
.icon-tablet:before {
    content: '\f10a';
}
.icon-mobile:before {
    content: '\f10b';
}
.icon-mobile-phone:before {
    content: '\f10b';
}
.icon-mail-reply:before {
    content: '\f112';
}
.icon-reply:before {
    content: '\f112';
}
.icon-mail-reply-all:before {
    content: '\f122';
}
.icon-reply-all:before {
    content: '\f122';
}
.icon-chain-broken:before {
    content: '\f127';
}
.icon-unlink:before {
    content: '\f127';
}
.icon-superscript:before {
    content: '\f12b';
}
.icon-subscript:before {
    content: '\f12c';
}
.icon-chevron-circle-left:before {
    content: '\f137';
}
.icon-chevron-circle-right:before {
    content: '\f138';
}
.icon-chevron-circle-up:before {
    content: '\f139';
}
.icon-chevron-circle-down:before {
    content: '\f13a';
}
.icon-unlock-alt:before {
    content: '\f13e';
}
.icon-ellipsis-h:before {
    content: '\f141';
}
.icon-ellipsis-v:before {
    content: '\f142';
}
.icon-sort-alpha-asc:before {
    content: '\f15d';
}
.icon-sort-alpha-desc:before {
    content: '\f15e';
}
.icon-sort-amount-asc:before {
    content: '\f160';
}
.icon-sort-amount-desc:before {
    content: '\f161';
}
.icon-sort-numeric-asc:before {
    content: '\f162';
}
.icon-sort-numeric-desc:before {
    content: '\f163';
}
.icon-thumbs-up:before {
    content: '\f164';
}
.icon-thumbs-down:before {
    content: '\f165';
}
.icon-youtube-play:before {
    content: '\f16a';
}
.icon-trash:before {
    content: '\f1f8';
}
.icon-user-plus:before {
    content: '\f234';
}
.icon-user-times:before {
    content: '\f235';
}
.icon-calendar-check-o:before {
    content: '\f274';
}
.icon-user-circle:before {
    content: '\f2bd';
}
.icon-clock:before {
    content: '\e94e';
}
.CircularProgressbar .CircularProgressbar-path {
    stroke: #3c7fcc;
}
.CircularProgressbar .CircularProgressbar-text {
    fill: #3c7fcc;
}
.CircularProgressbar.small {
    width: 30px;
    height: 30px;
}
.CircularProgressbar.small .CircularProgressbar-text {
    font-size: 22px;
}
.width-progressbar-small {
    display: inline-block;
    width: 30px;
}
.mce-tinymce {
    box-shadow: none !important;
    width: auto !important;
}
.mce-content-body {
    margin-top: 13px;
    margin-bottom: 10px;
    padding: 8px 8px 1px 8px;
    color: #666;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px !important;
    outline: 1px solid #e2e4e7;
}
.mce-content-body.mce-edit-focus {
    max-height: 50vh;
    overflow-y: scroll;
}
.mce-content-body.mce-edit-focus,
.mce-content-body:hover {
    outline: 1px solid #3c7fcc;
}
.mce-content-body:not(.mce-edit-focus):hover {
    cursor: pointer;
}
.mce-content-body.block-deleted {
    outline: none;
}
.mce-content-body * {
    color: #666;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}
.mce-content-body h3,
.mce-content-body h4 {
    margin-top: 0;
    margin-bottom: 6px;
    line-height: 1.4;
    font-weight: bold;
}
.mce-content-body h3 {
    font-size: 1rem;
}
.mce-content-body h4 {
    font-size: 0.875rem;
}
.mce-content-body p,
.mce-content-body ul,
.mce-content-body ol,
.mce-content-body table {
    margin-top: 0;
    margin-bottom: 6px;
}
.mce-content-body li > ul,
.mce-content-body li > ol {
    margin-bottom: 0;
}
.mce-content-body table {
    max-width: 95%;
}
.mce-content-body a {
    color: #3c7fcc;
    text-decoration: none;
}
.mce-content-body a:hover,
.mce-content-body a:focus {
    text-decoration: underline;
}
.mce-content-body strong {
    font-weight: 600;
}
.mce-content-body ins {
    background-color: #fdde86;
    text-decoration: none;
    font-style: normal;
}
.mce-content-body del {
    background-color: #ffbebc;
    text-decoration: none;
    font-style: normal;
}
.mce-content-body .placeholder {
    display: block;
    width: 100%;
    height: 24px;
    border: 2px silver solid;
    background-color: #eee;
    padding: 15px 0;
    text-align: center;
    color: #999;
}
.uk-table td .mce-content-body {
    margin-top: 0;
    margin-bottom: 0;
}
.mce-ico.mce-i-arrow-up {
    background: transparent url(../images/icons/arrow-up.svg) center no-repeat;
}
.mce-ico.mce-i-arrow-down {
    background: transparent url(../images/icons/arrow-down.svg) center no-repeat;
}
.mce-ico.mce-i-trash {
    background: transparent url(../images/icons/trash.svg) center no-repeat;
}
/**
 * <div class="react-tags">
 *   <div class="react-tags__selected">
 *     <button class="react-tags__selected-tag">
 *       <span class="react-tags__selected-tag-name" />
 *     </button>
 *   </div>
 *   <div class="react-tags__search">
 *     <div class="react-tags__search-wrapper">
 *       <input class="react-tags__search-input" />
 *       <div />
 *     </div>
 *     <div class="react-tags__suggestions">
 *       <ul>
 *         <li class="is-active">
 *           <mark />
 *         </li>
 *         <li class="is-disabled">
 *           <mark />
 *         </li>
 *       </ul>
 *     </div>
 *   </div>
 */
.react-tags {
    position: relative;
    padding: 6px 0 0 6px;
    border: 1px solid #e5e5e5;
    border-radius: 1px;
    /* shared font styles */
    font-size: 0.75rem;
    line-height: 1.1;
    /* clicking anywhere will focus the input */
    cursor: text;
    background-color: white;
}
.react-tags__selected {
    display: inline;
}
.uk-tag,
.react-tags__selected-tag {
    display: inline-block;
    box-sizing: border-box;
    margin: 0 6px 6px 0;
    padding: 0 8px;
    border: 1px solid transparent;
    border-radius: 0.5em;
    font-size: 11px;
    font-weight: 500;
    line-height: 20px;
}
.uk-tag-default {
    background-color: #f1f8ff;
    color: #0366d6;
}
.uk-tag-alert {
    background-color: #ffde91;
    color: #bb7a32;
}
.react-tags__selected .uk-tag-default:hover {
    background-color: #def;
}
.react-tags__selected .uk-tag-alert:hover {
    background-color: #ffd260;
}
.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
    cursor: pointer;
    outline-width: 0;
}
.react-tags__selected-tag:after {
    content: '\2715';
    margin-left: 8px;
}
.react-tags__search {
    display: inline-block;
    /* match tag layout */
    padding: 7px 2px;
    margin-bottom: 6px;
    /* prevent autoresize overflowing the container */
    max-width: 100%;
}
@media screen and (min-width: 30em) {
    .react-tags__search {
        /* this will become the offsetParent for suggestions */
        position: relative;
    }
}
.react-tags__search-input {
    /* prevent autoresize overflowing the container */
    max-width: 100%;
    /* remove styles and layout from this element */
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
}
::placeholder {
    color: #999999;
}
.react-tags__search-input::-ms-clear {
    display: none;
}
.react-tags__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 1500;
}
@media screen and (min-width: 30em) {
    .react-tags__suggestions {
        width: 240px;
    }
}
.react-tags__suggestions ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}
.react-tags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;
}
.react-tags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}
.react-tags__suggestions li:hover {
    cursor: pointer;
    background: #eee;
}
.react-tags__suggestions li.is-active {
    background: #b7cfe0;
}
.react-tags__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: auto;
}
.rstm-toggle-icon {
    display: inline-block;
}
.rstm-toggle-icon-symbol {
    width: 2rem;
    height: 2rem;
    text-align: center;
    line-height: 2rem;
}
.rstm-tree-item-group {
    list-style-type: none;
    padding-left: 0;
    border-top: 1px solid #dce4eb;
    text-align: left;
    width: 100%;
}
.rstm-tree-item {
    padding: 0.75rem 1rem;
    cursor: pointer;
    color: #444;
    background: none;
    border-bottom: 1px solid #dce4eb;
    box-shadow: none;
    z-index: unset;
    position: relative;
}
.rstm-tree-item--active {
    color: white;
    background: #3c7fcc;
    border-bottom: none;
}
.rstm-tree-item--focused {
    z-index: 999;
}
.rstm-search {
    padding: 1rem 1.5rem;
    border: none;
    width: 100%;
}
/*# sourceMappingURL=main.css.map */
