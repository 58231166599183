.template-modal {
    .modal-content {
        width: auto;
        min-width: 600px;
        overflow: auto;

        .uk-form-controls {
            margin-left: 3px;
        }

        .dropdown-toggle {
            margin-left: 15px;
            width: 210px;
            color: $darkBlue;
            font-weight: $font-weight-normal;
        }
    }

    .modal-dialog {
        max-width: none !important;
        width: 1100px !important;
    }

    .uk-modal {
        z-index: 1200 !important;
    }
}

.modal-header {
    background-color: $modal-header-bg;
    align-items: center !important;
    border-top-left-radius: $modal-content-border-radius;
    border-top-right-radius: $modal-content-border-radius;

    .modal-title {
        color: $modal-header-color;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.modal-body {
    .label-title {
        font-size: $font-size-sm !important;
        color: $darkBlueAlt;
    }
}

.modal-fullscreen {
    .modal-dialog {
        width: 100%;
        max-width: 95%;
    }
}

.react-joyride__overlay {
    z-index: 1250 !important;
}

.uk-modal-danger .uk-modal .uk-modal-footer {
    .uk-button-primary {
        background-color: $danger;

        &:hover {
            background-color: darken($danger, 7.5%);
        }
    }
}

.uk-modal-warning .uk-modal .uk-modal-footer {
    .uk-button-primary {
        background-color: $warning;

        &:hover {
            background-color: darken($warning, 7.5%);
        }
    }
}

.modal-content {
    .nav-tabs {
        .nav-item {
            padding: 0;

            .nav-link {
                background-color: transparent;
                border: none;
                color: $darkBlue;
                padding: 0.75rem 1rem;
                border-bottom: 2px solid transparent;

                &.active {
                    border-bottom: 2px solid $darkBlue;
                }

                &:not(.active):hover {
                    border-bottom-color: $gray-400;
                }

                &.disabled {
                    color: $muted;
                }
            }
        }
    }
}

.dr-modal-content-light {
    * {
        letter-spacing: normal;
    }

    .dr-label {
        color: $midBlue;
        font-weight: 600;
    }

    .accordion {
        > .card > .card-header {
            margin-bottom: unset;
            border-radius: $card-border-radius;
        }

        .card {
            margin-bottom: 0.5rem;
            border-radius: $card-border-radius !important;
            border: $card-border-width solid $card-border-color !important;
        }

        .card-header {
            background-color: $light;
        }
    }
}

.media-viewer-popup {
    position: fixed;
    inset: 0;
    background-color: rgb(34, 39, 43);
    z-index: 1250;
    display: flex;

    .media-viewer-header {
        color: #fff;
        font-size: 1.25rem;

        .btn {
            color: #fff !important;
            font-size: 1.25rem;
        }

        .file-icon {
            svg {
                color: $green !important;
            }
        }
    }

    .media-viewer-content {
        overflow-y: auto;
    }
}
