// navbar z-index fix
.bm-menu-wrap,
.bm-overlay {
    z-index: 1200 !important;
}

.bm-menu {
    background-color: $light;
    border-left: 1px solid $lightBlueDense;
    overflow: hidden !important;

    .navbar {
        height: 56px;
        font-weight: 600;
        color: $midBlue;

        .navbar-nav .nav-link {
            padding-top: 19px;
            color: $midBlue;
            padding-bottom: 15px;
            font-weight: 500;
            border-bottom: solid 1px transparent;

            &:hover {
                color: $darkBlueAlt;
            }

            &.disabled {
                color: $navbar-light-disabled-color;
            }
        }

        .navbar-nav .active > .nav-link {
            border-bottom-color: $darkBlueAlt;
            color: $darkBlueAlt;
            font-weight: 600;

            &:hover {
                color: $darkBlueAlt;
            }
        }

        .navbar-nav .nav-link:hover {
            text-decoration: none !important;
            color: $midBlue;
        }
    }

    .form-container {
        .label-title {
            font-size: $font-size-sm !important;
        }

        .form-label {
            font-size: $font-size-base;
        }
    }

    .label-container {
        .label-title {
            font-size: $font-size-sm;
        }

        .basis-title {
            font-size: $font-size-sm;
            font-weight: $font-weight-bolder;
            margin-bottom: 10px;
        }

        .variant-container {
            .variant-name {
                font-size: $font-size-sm;
                font-weight: $font-weight-bolder;
            }
        }
    }
}

.sidebar-top {
    background-color: $lightBlueMidAlt;
    width: 100%;
    padding: 0.55rem 0 0.55rem 1.2rem;
    font-size: 14px;
    font-weight: 500;
    height: 73px;
    justify-content: space-between;

    .sidebar-top-buttons {
        margin-right: 60px;

        .btn-secondary {
            color: $midBlue;
            font-weight: $font-weight-normal;

            &:hover {
                background-color: $lightBlueDenseAlt;
            }
        }
    }

    .sidebar-top-item-name {
        margin-left: 12px;
    }
}

.icon-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
    gap: 10px;
    padding-top: 10px;
}

.icon-grid-wrapper {
    height: 450px;
    overflow: auto;
}

.sidebar-form {
    color: $color;

    .sidebar-form-group {
        border-top: 1px solid $border-color;
        padding-top: 1rem;
        margin-bottom: 1rem;

        &:first-of-type {
            border-top: none;
        }
    }

    .form-checkbox {
        margin-bottom: 1rem;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .form-text {
        color: $text-muted;
    }

    .form-check-label {
        font-size: $font-size-sm;
        letter-spacing: normal;
    }
}
