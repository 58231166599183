.mce-tinymce {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    width: auto !important;
}

.mce-content-body {
    margin-top: 13px;
    margin-bottom: 10px;
    padding: 8px 8px 1px 8px;
    color: #666;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px !important;
    outline: 1px solid #e2e4e7;

    &.mce-edit-focus {
        max-height: 50vh;
        overflow-y: scroll;
    }

    &.mce-edit-focus,
    &:hover {
        outline: 1px solid @primary-color;
    }

    &:not(.mce-edit-focus):hover {
        cursor: pointer;
    }

    &.block-deleted {
        outline: none;
    }

    * {
        color: #666;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
    }

    h3,
    h4 {
        margin-top: 0;
        margin-bottom: @gutter-width-xsmall;
        line-height: 1.4;
        font-weight: bold;
    }

    h3 {
        font-size: 1rem;
    }

    h4 {
        font-size: 0.875rem;
    }

    p,
    ul,
    ol,
    table {
        margin-top: 0;
        margin-bottom: @gutter-width-xsmall;
    }

    li {
        > ul,
        > ol {
            margin-bottom: 0;
        }
    }

    table {
        max-width: 95%;
    }

    a {
        color: @link-color;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    strong {
        font-weight: 600;
    }

    ins {
        background-color: @diff-marking-ins-color;
        text-decoration: none;
        font-style: normal;
    }

    del {
        background-color: @diff-marking-del-color;
        text-decoration: none;
        font-style: normal;
    }

    .placeholder {
        display: block;
        width: 100%;
        height: 24px;
        border: 2px silver solid;
        background-color: #eee;
        padding: 15px 0;
        text-align: center;
        color: #999;
    }

    @import '../build/fixed-link';
}

.uk-table td .mce-content-body {
    margin-top: 0;
    margin-bottom: 0;
}

.mce-ico.mce-i-arrow-up {
    background: transparent url(../images/icons/arrow-up.svg) center no-repeat;
}

.mce-ico.mce-i-arrow-down {
    background: transparent url(../images/icons/arrow-down.svg) center no-repeat;
}

.mce-ico.mce-i-trash {
    background: transparent url(../images/icons/trash.svg) center no-repeat;
}
