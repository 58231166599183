// @include theme-list-group-item-variant('publications', $purple, $list-group-action-color);

.list-group-item-nav {
    border-bottom: none;
    border-top: none;
    padding: 0.8rem 0 0.8rem 1.1rem;
    display: flex;
    align-items: center;
    color: $darkBlue;
    font-size: $font-size-sm;
    background-color: initial;

    svg {
        margin-right: 0.5rem;
        font-size: $font-size-lg !important;
        color: $darkBlue;
    }

    &:hover {
        background-color: $white;
    }

    &.active {
        background-color: #e9e9e9;

        &:before {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 2px;
            height: 100%;
            background-color: $darkBlue !important;
        }
    }

    &:active {
        background-color: #dddddd;
    }
}

.list-group-item + .list-group-item.active {
    margin-top: 0;
    border-top-width: 0;
}

.main-sidebar {
    hr {
        margin: 0.55rem 0 0.55rem 1rem;
        border: 0;
        border-top: 1px solid #cbcbcb;
        display: none;
    }

    .sidebar-nav-section:has(a) {
        hr {
            display: block;
        }
    }
}

.list-group-flush > .sidebar-nav-section > .list-group-item {
    border-width: 0 0 1px;
}
