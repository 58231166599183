.empty-state-wrapper {
    color: $darkBlue;
}

.empty-state-wrapper-inner {
    max-width: 464px;
    margin: 0 auto;
}

.empty-state-header {
    margin-bottom: 0.75rem;
}

.empty-state-body {
    margin-bottom: 1.25rem;
}

.empty-state-header-text {
    font-size: 1.25rem;
}

.empty-state-icon {
    font-size: 72px;
    margin-bottom: 1rem;
    color: $muted;
    max-width: 160px;
}

.empty-state-icon-rocket {
    opacity: 0.75;
}
