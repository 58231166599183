.card-header {
    font-family: $font-open-sans;
    font-weight: 500;
    font-size: $font-size-base;
    color: $darkBlueAlt;
    background-color: $white;
}

/* DocRev style cards */

$dr-component-border-color: #dde1e8;
$dr-component-border-active-color: #bfc3d0;
$dr-title-color: $darkBlueAlt;
$dr-title-font-family: 'Poppins', sans-serif;
$dr-title-font-weight: 600;

.text-title {
    color: $dr-title-color;
}

.dr-card {
    border-color: $dr-component-border-color;
    border-radius: 0;
    box-shadow: 0 0.125rem 0.25rem rgba($black, 0.05);

    input[type='checkbox'],
    .dropdown-absolute > svg {
        opacity: 0.4;

        &:hover,
        &:checked {
            opacity: 1;
        }
    }

    .dropdown-absolute.show > svg {
        opacity: 1;
    }

    .dropdown-absolute > svg {
        margin-top: -2px;
    }
}

.dr-card-body {
    padding: 2rem;

    .card-title {
        font-weight: $dr-title-font-weight;
        font-family: $dr-title-font-family;
        color: $dr-title-color;
    }

    .card-body {
        padding: 0 0 1rem 0;
    }
}

.dropdown-absolute {
    .dropdown-menu.show {
        position: fixed !important;
    }
}

.dr-card-row {
    border: 1px solid $dr-component-border-color;
    border-radius: $border-radius-sm;
    padding: 0.5rem 1rem;
    cursor: pointer;

    &:hover,
    &.selected {
        background-color: $lightBlueLightAlt1;
    }

    .dropdown-menu.show {
        position: fixed !important;
    }
}

.dr-translation-item-wrapper {
    position: relative;
    border: 1px solid $dr-component-border-color;
    border-radius: $border-radius-sm;
    padding-top: 0.5rem;

    &.can-translate:not(.active) {
        &:hover {
            cursor: pointer;
            border-color: $primary;

            .dr-translation-actions {
                .btn {
                    opacity: 0.65;
                }
            }
        }
    }

    &.translation-disabled {
        opacity: 0.5;

        &:not(.active):hover {
            cursor: default;
            border-color: $dr-component-border-color;
        }
    }

    &.active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-color: $dr-component-border-active-color;
        border-left-color: $dr-component-border-active-color;
        border-right-color: $dr-component-border-active-color;
    }

    .dr-translation-type {
        position: absolute;
        right: 3px;
        top: -20px;
        font-size: $font-size-sm;
        color: $secondary;
    }

    .dr-translation-actions {
        position: absolute;
        left: 6px;
        top: -17px;
        background-color: $white;
        padding: 0.25rem;

        .btn {
            opacity: 0.5;
            color: $bgDense;
            margin: 0 2px;

            &:not(:disabled):hover {
                opacity: 1 !important;
            }
        }
    }
}

.dr-translation-table {
    cursor: default !important;
    padding: 2rem 1rem 1rem 1rem;

    &:hover {
        background-color: inherit !important;
    }
}

.dr-edit-translation {
    border: 1px solid $dr-component-border-active-color;
    border-bottom-left-radius: $border-radius-sm;
    border-bottom-right-radius: $border-radius-sm;
    border-top: none;
    padding: 1.25rem;

    .mce-content-body {
        margin-top: 0 !important;
        margin-bottom: 1.5rem;
        min-height: 39px;
        padding: 1rem;
    }
}

.rendered-content {
    font-size: $font-size-sm;
    font-family: $font-family-sans-serif;

    p,
    ul,
    ol {
        margin: 0 0 0.75em 0;
    }

    p.answer {
        font-weight: 600;
        margin-bottom: 0;
    }

    ul,
    ol {
        padding-left: 15px;
    }

    h3,
    h4,
    h5,
    h6 {
        font-family: $font-family-sans-serif;
        margin: 0 0 0.5em 0;
        font-weight: $font-weight-bold;
        font-size: $font-size-sm;
    }

    h3 {
        font-size: $font-size-lg;
    }

    h4 {
        font-size: $font-size-base;
    }

    h3,
    h4,
    p {
        &.margin-bottom {
            padding-bottom: 3px;
            border-bottom: 10pt solid #ffecec;
        }

        &.margin-top {
            padding-top: 3px;
            border-top: 10pt solid #ffecec;
        }
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.dr-translation-item {
    padding: 1.5rem;
}

.dr-translation-preview {
    margin: 0.25rem 1.5rem 1.5rem 1.5rem;
    padding-top: 1.5rem;
    font-size: $font-size-sm;
    border-top: 1px solid $dr-component-border-active-color;
}

.area-excluded {
    .card-title {
        color: $bgDense !important;
    }
}

.area-heading-level {
    margin-top: 0.65rem;
}

.area-heading-level-3,
.area-heading-level-4,
.area-heading-level-5 {
    .area-heading-level-inner {
        border-left: 1px solid $dr-component-border-color;
        padding-left: 1rem;
    }
}

.area-heading-level-3 + .area-heading-level-3,
.area-heading-level-4 + .area-heading-level-4,
.area-heading-level-5 + .area-heading-level-5 {
    margin-top: 0;

    & > input {
        margin-top: 0.65rem;
    }

    .area-heading-level-inner {
        padding-top: 0.65rem;
    }
}

.area-heading-level-2 {
    .area-heading-level-inner {
        margin-left: 1rem;
    }
}

.area-heading-level-3 {
    .area-heading-level-inner {
        margin-left: 2rem;
    }
}

.area-heading-level-4 {
    .area-heading-level-inner {
        margin-left: 3rem;
    }
}

.area-heading-level-5 {
    .area-heading-level-inner {
        margin-left: 4rem;
    }
}

/* DocRev style accordion */

.dr-accordion {
    .card {
        border: none;
        margin-bottom: 1rem;

        > .card-header {
            margin-bottom: 0;
        }
    }

    .card-header {
        background-color: $white !important;
        font-family: $dr-title-font-family;
        border-bottom: 0;
        margin-bottom: 0;

        .btn-link,
        .translation-key-card-title {
            color: $dr-title-color;
            font-size: 13px;
        }

        .translation-key-card-title {
            font-weight: 400;
        }

        .btn-link {
            font-weight: $dr-title-font-weight;
            padding: 0.375rem;
        }
    }

    .dr-card-row {
        font-family: $dr-title-font-family;
    }
}

.oke-card {
    .card-header {
        background-color: $card-cap-bg;
    }

    &.oke-card-active {
        border-color: darken($card-border-color, 10%);
        box-shadow: $shadow-sm;
    }

    &.oke-card-danger {
        border-color: $success;

        .card-header {
            color: #196c2c;
            background-color: #28a74540;
        }
    }

    .table {
        th {
            border-top: 0;
            background-color: #fff;
        }
    }
}
