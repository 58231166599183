.btn-warning {
    color: $white !important;
}

.btn-action {
    color: $coloralt;
    padding: 0 2px;
    text-align: left;

    &:not(:disabled):hover {
        color: $darkBlue;
        text-decoration: underline;
    }
}

.uk-modal .uk-modal-close,
.btn-secondary {
    background-color: $white;
    color: #414651 !important;
    font-weight: 400;
    border: 1px solid #d5d7da;

    &:hover {
        background-color: $lightBlueMid;
        border-color: $midBlueAlt;
    }

    &:not(:disabled):not(.disabled):active {
        background-color: $lightBlueMid;
    }
}

.btn-outline-secondary {
    background-color: $lightBlueLight;
    color: $midBlue;
    font-weight: 100;
    border-color: $lightBlueDense;

    &:hover {
        color: $midBlue;
        background-color: $lightBlueDenseAlt;
    }
}

.outline-small-button {
    background-color: $white;
    color: $darkBlue;
    font-size: $font-size-sm;
    border: 1px solid $lightBlueDense;

    svg {
        margin-right: 0.25rem !important;
    }

    &:hover {
        color: $darkBlue;
        background-color: $lightBlueDenseAlt;
        outline-style: none;
    }

    &:focus {
        box-shadow: none;
    }
}

.tags-button {
    background-color: $purple;
    color: #21205c;
    font-weight: 400;
    border: 1px solid $purpleDark;
    border-radius: 5px;
    padding: 2px 6px;
    font-size: $font-size-sm;
    word-break: break-all;

    svg {
        margin-top: -1px;
    }

    &:hover {
        background-color: #d6d6e3;
    }
}

.entity-title-tags-button {
    border: 1px dashed #adb5bb;
    border-radius: 4px;
    padding: 1px 4px;
    margin: 0 2px;

    &:hover {
        background-color: #e6ebf1;
        border-color: #7a95b5;
    }
}

.refs-button {
    background-color: #ffe8b4;
    color: #683f19;
    font-weight: 400;
    border: 1px solid #e3c98c;
    border-radius: 5px;
    padding: 2px 6px;
    font-size: $font-size-sm;
    word-break: break-all;

    svg {
        margin-top: -1px;
    }

    //&:hover {
    //    background-color: #edd8a9;
    //}
}

.btn-settings {
    opacity: 0.2;
    padding: 3px !important;

    svg {
        margin-right: 0 !important;
    }
}

.edit-area-block,
.section-row,
.section-area-row {
    &:hover {
        .btn-settings {
            opacity: 1;
        }
    }
}

.btn-dark,
.btn-light {
    &:focus,
    &:not(:disabled):not(.disabled):active {
        box-shadow: none;
        color: $darkBlue;
        background-color: $white;
    }
}

.btn-warning:focus,
.btn-warning.focus {
    color: $white;
}

.btn-outline-secondary-dashed {
    border-style: dashed;

    &:hover {
        color: darken($secondary, 25%);
        background-color: $lightBlueLight !important;
    }
}

.btn {
    letter-spacing: normal;

    * {
        letter-spacing: normal;
    }
}

.btn:not(.btn-link) {
    svg {
        margin-right: 0.5rem;
        font-size: 18px;
    }
}

.btn-sm {
    svg {
        margin-right: 0.35rem;
        font-size: 16px;
    }
}

.btn-outline-light {
    border-color: $gray-400;
    color: $gray-800;
    background-color: $white;

    &:not(:disabled):not(.disabled):hover {
        border-color: $gray-400;
        background-color: $gray-200;
    }

    &.disabled,
    &:disabled {
        color: $gray-800;
        opacity: 0.5;

        &:hover {
            border-color: $gray-400;
        }
    }
}

.btn-outline-light:not(:disabled):not(.disabled):active {
    border-color: $gray-400;
    background-color: $gray-300;
}

.btn-bookmark {
    padding: 0;
    height: auto;
    line-height: 1;
    color: $gray-500;

    svg {
        margin-right: 0;
    }

    &:hover {
        color: $gray-600;
    }

    &.active {
        color: $brightBlue;
    }
}

.uk-button {
    letter-spacing: normal;
    height: 35px;
    border: 0;
    box-shadow: none !important;
    font-weight: $btn-font-weight;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-right: 1px solid $white !important;
}

.dropdown-toggle-split {
    border-left: 1px solid $white !important;
    padding-left: 8px !important;
    padding-right: 8px !important;

    &::after {
        border: 0 !important;
        vertical-align: -.4em;
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='%23fff' class='bi bi-chevron-down' viewBox='0 0 18 18'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708'/></svg>");
        background-repeat: no-repeat;
        background-size: 18px 18px;
        width: 18px;
        height: 18px;
    }

    &.btn-sm {
        &::after {
            background-size: 16px 16px;
            width: 16px;
            height: 16px;
        }
    }
}
