.data-table {
    overflow-wrap: normal;

    .chevron {
        transition: all 0.15s ease-out;
    }

    .data-table-item-wrapper {
        cursor: default;

        .icon-grip {
            visibility: hidden;

            &:hover {
                cursor: grab;
            }
        }

        &:hover {
            .icon-grip {
                visibility: visible;
            }
        }

        &.is-dragging {
            .icon-grip {
                visibility: visible;
            }
        }

        //.dropdown-toggle::after {
        //    vertical-align: 0.07em;
        //    font-size: 20px;
        //}
        //
        //.dropdown-toggle.btn-sm::after {
        //    font-size: 18px;
        //}
    }

    .item-col-title {
        @include media-breakpoint-up(xxl) {
            width: 35% !important;
            padding-left: 1rem;
        }
    }

    .item-col-prefix {
        width: 120px;
        min-width: 120px;

        @include media-breakpoint-up(xl) {
            width: 132px;
            min-width: 132px;
        }

        @include media-breakpoint-up(xxl) {
            width: 15%;
        }
    }

    .item-col-prefix-content,
    .item-col-status-content,
    .item-col-document-content,
    .item-col-role-content,
    .item-col-team-content,
    .item-col-revision-content {
        color: $darkBlueAlt;

        @include media-breakpoint-up(xxl) {
            font-size: $font-size-base;
        }
    }

    .item-col-status-content,
    .item-col-revision-content {
        font-size: 12px !important;
    }

    .item-col-document {
        width: 132px;
        min-width: 132px;

        @include media-breakpoint-up(xl) {
            width: 160px;
            min-width: 160px;
        }

        @include media-breakpoint-up(xxl) {
            width: 25%;
        }
    }

    .data-table-group-header {
        color: $darkBlueAlt;
        font-weight: $font-weight-bold;

        .btn-icon {
            visibility: hidden;
            color: $darkBlueAlt !important;
        }

        &:hover {
            .btn-icon {
                visibility: visible;
            }
        }
    }

    .data-table-group-level-0 > .data-table-group-header {
        font-weight: 600;
        font-size: $font-size-lg;
        padding: -1rem 0 0.25rem 0;
        margin-bottom: 20px;
        margin-top: 15px;
    }

    .data-table-group-level-1 > .data-table-group-header {
        padding: 0.55rem 0 0.55rem 1.2rem;
        font-size: $font-size-base;
        font-weight: 500;
        background-color: $bgMid;
    }

    .data-table-header {
        padding-left: 2rem;
        padding-right: 1rem;
        color: $midBlue;
        font-size: 80%;
        font-weight: 600;
        background-color: $white;
        border-radius: 5px;
    }

    // .data-table-group-droppable {
    //     &.dragging-over {
    //         background-color: white !important;
    //         border-color: $dropzone-active-border-color !important;
    //     }
    // }

    // &.is-dragging {
    //     .data-table-group-level-1 {
    //         background-color: white;
    //         padding-left: 0.5rem;
    //         border-radius: 5px;
    //         margin-left: -0.5rem;
    //     }
    // }

    .data-table-group-expanded > .data-table-group-header > .chevron {
        transform: rotate(90deg);
    }

    .data-table-item {
        border-radius: 5px;

        .field-title {
            color: $darkBlueAlt;
            font-weight: 600;
            font-size: $font-size-base;
        }

        .chevron {
            color: $component-active-color;
        }

        &.data-table-item-disable {
            .data-table-item-header {
                .field-title,
                .chevron {
                    color: $muted !important;
                    font-weight: $font-weight-bolder;
                }
            }
        }
    }

    .data-table-item-wrapper.is-dragging {
        border-color: $border-color !important;
        border-radius: 5px !important;
    }

    .data-table-item-expanded {
        border-radius: 10px !important;
        background-color: $white !important;
        position: relative;

        .chevron {
            transform: rotate(90deg);
        }
    }

    .data-table-item-header {
        cursor: pointer !important;
        background-color: $white;
        margin-top: -10px;

        &:hover {
            border-color: $darkBlueAlt;
        }
    }

    .item-col-status-content {
        .badge {
            padding: 0.55rem 1rem;
            min-width: 115px;

            @include media-breakpoint-up(xl) {
                margin-right: 2rem;
            }
        }

        .badge-success {
            color: #303602;
            background-color: $docRevLime;
        }

        .badge-success-light {
            color: #464e05;
            background-color: rgba($docRevLime, 0.4);
        }
    }
}

.data-table-documents {
    margin-left: 15px;
    margin-right: 25px;

    .item-col-status {
        width: 140px;
        min-width: 140px;
        margin-left: 15px;

        .span {
            font-size: $font-size-sm;
        }

        @include media-breakpoint-up(xl) {
            width: 140px;
            min-width: 140px;
        }

        @include media-breakpoint-up(xxl) {
            width: 5%;
        }
    }

    .item-col-revision {
        width: 100px;
        min-width: 100px;
        margin-left: -10px;
        @include media-breakpoint-up(xl) {
            width: 120px;
            min-width: 120px;
        }

        @include media-breakpoint-up(xxl) {
            width: 10%;
        }
    }

    .item-col-team {
        width: 100px;
        min-width: 100px;

        @include media-breakpoint-up(xxl) {
            width: 10%;
        }
    }

    .item-col-role {
        width: 100px;
        min-width: 100px;

        @include media-breakpoint-up(xl) {
            width: 140px;
            min-width: 140px;
        }

        @include media-breakpoint-up(xxl) {
            width: 12%;
            min-width: 160px;
        }
    }

    .item-col-actions {
        width: 100px;
        min-width: 100px;

        .button {
            background-color: $buttonBlueAlt;
            padding-left: 2px;
            padding-right: 2px;
            display: inline;
            cursor: pointer;
            @include media-breakpoint-up(xl) {
                width: 140px;
                min-width: 140px;
            }

            &:hover {
                background-color: $darkBlueAlt;
            }
        }

        @include media-breakpoint-up(xxl) {
            width: 10%;
            min-width: 160px;
        }
    }
}

.data-table-publications {
    margin-left: 15px;
    margin-right: 25px;

    .data-table-item-selected {
        > .border {
            border-color: $gray-500 !important;
            background-color: $lightBlueLight !important;
        }
    }

    .data-table-item-expanded {
        svg.caret {
            transform: rotate(90deg);
        }
    }

    .item-col-title {
        @include media-breakpoint-up(xxl) {
            width: 45%;
        }
    }

    .item-col-status {
        width: 115px;
        min-width: 115px;

        @include media-breakpoint-up(xl) {
            width: 147px;
            min-width: 147px;
        }

        @include media-breakpoint-up(xxl) {
            width: 15%;
        }
    }

    .uk-background-theme-light-hover {
        .caret {
            visibility: hidden;
        }

        &:hover {
            .caret {
                visibility: unset;
            }
        }
    }
}

.data-table-reimbursements {
    .data-table-item-header {
        .btn-icon {
            visibility: hidden;
        }

        .field-title {
            min-height: 28px;
        }
    }

    .data-table-item:not(.data-table-item-expanded) {
        &:hover {
            background-color: $list-group-hover-bg;

            .btn-icon {
                visibility: visible;
            }
        }
    }

    .icon-grip {
        visibility: hidden;
    }

    &:not(.is-dragging) {
        .data-table-row {
            &:hover {
                .icon-grip {
                    visibility: visible;
                }
            }
        }
    }

    .data-table-item-wrapper {
        &.is-dragging {
            & > .uk-border-left {
                border-color: #fff;
            }
        }
    }
}

.data-table-translations {
    .item-col-title {
        width: 30%;

        @include media-breakpoint-up(xxl) {
            width: 20%;
        }
    }

    .item-col-type {
        width: 20%;
    }

    .item-col-name {
        width: 20%;
    }

    .item-col-languages {
        width: 15%;
    }

    .item-col-role {
        width: 15%;
    }

    .item-col-actions {
        width: 100px;
        min-width: 130px;
        margin-left: auto;

        @include media-breakpoint-up(xxl) {
            width: 10%;
        }
    }
}

.data-table-propositions {
    .icon-grip {
        margin-left: -16px;
        margin-right: 4px;
        visibility: hidden;
    }
}

$nestedVariantsBorderColor: $lightBlueDense;

.dr-variants {
    .border-left {
        border-left: 2px solid $nestedVariantsBorderColor !important;
    }
}

.variant-droppable.is-dragging-over {
    .dr-child-variant-wrapper {
        border-left-color: $white;
    }

    .dr-variant-border {
        &:before {
            display: none !important;
        }
    }
}

.dr-child-variant-wrapper {
    border-left: 2px solid $nestedVariantsBorderColor;

    .dr-variant-border {
        position: relative;

        &:before {
            content: ' ';
            display: block;
            position: absolute;
            left: -18px;
            width: 18px;
            top: -8px;
            height: 39px;
            border-bottom: 2px solid $nestedVariantsBorderColor;
            border-left: 2px solid $nestedVariantsBorderColor;
        }

        &.dr-variant-group {
            &:before {
                top: -10px;
                height: 32px;
            }
        }
    }

    &:last-of-type {
        > .dr-variant-border {
            &:before {
                border-bottom-left-radius: 0.5rem;
            }
        }
    }
}

.variant-droppable > .dr-child-variant-wrapper:last-child {
    border-left-color: $white;
}

.variant-droppable {
    border-radius: $border-radius;

    .dr-variant.is-dragging {
        .drag-handle {
            visibility: visible;
        }
    }

    .dr-variant.is-dragging {
        .btn {
            visibility: hidden;
        }

        .bg-white {
            box-shadow: $shadow-sm;
        }
    }
}

.variant-is-dragging .variant-droppable {
    &.is-dragging-over {
        background-color: $dropzone-active-bg;
    }

    .dr-variant:hover:not(.is-dragging) {
        .drag-handle {
            visibility: hidden !important;
        }
    }
}

.toggle-visible {
    visibility: hidden;
}

.toggle-on-hover:hover {
    .toggle-visible {
        visibility: visible;
    }
}

.one-liner-table-content-preview {
    p {
        margin: 0;
    }
}

.guard-alias-row {
    .border-cell {
        position: relative;

        &:before {
            content: ' ';
            display: block;
            position: absolute;
            left: 17px;
            width: 16px;
            top: -50%;
            height: 100%;
            border-bottom: 2px solid $nestedVariantsBorderColor;
            border-left: 2px solid $nestedVariantsBorderColor;
        }
    }

    &.first {
        .border-cell {
            &:before {
                top: calc(-1 * (50% - 20px));
                height: calc(100% - 20px);
            }
        }
    }

    &.last {
        .border-cell {
            &:before {
                border-bottom-left-radius: 0.5rem;
            }
        }
    }
}
