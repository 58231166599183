$sidebar-width: 160px;

$body-bg: $lightBlueLight;
$letter-spacing: -0.3px;

// Typography

$font-family-sans-serif: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans',
sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-base: $font-family-sans-serif;
$headings-font-family: $font-family-sans-serif;
$font-open-sans: 'Poppins', sans-serif;
$font-size-base: 0.875rem;
$font-size-lg: 1rem;
$font-size-sm: 0.75rem;
$small-font-size: 0.75rem;
$headings-margin-bottom: $spacer * 1.25;
$headings-font-weight: 300;
$font-weight-bolder: 500;
$font-weight-bold: 600;
$font-weight-regular: 400;
$font-weight-500: 500;
$font-weight-700: 700;
$text-muted: $muted;

// Components

$component-active-color: $darkBlueAlt;
$component-active-bg: $bgDense;
$border-radius-xl: 0.65rem !default;
$shadow-sm: 0 3px 5px rgba(0, 0, 0, 0.082);
$box-shadow-sm: 0px 0px 8px 1px rgba(131, 131, 131, 0.3);
$rounded-sm: 10px;

// Options

$enable-shadows: true;
$dr-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.05) !default;
$zindex-modal: 1200;
$zindex-modal-backdrop: 1190;
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1600px,
);

// Modals

$modal-inner-padding: 1.25rem;
$modal-content-border-color: #efefef;
$modal-backdrop-opacity: .5;
$modal-backdrop-bg: #04142c;
$modal-header-bg: #f8f8f8 !default;
$modal-header-color: $darkBlueAlt !default;
$modal-header-border-color: #dee2e66e;
$modal-footer-border-color: $modal-header-border-color;
$modal-header-padding-y: 1rem;
$modal-header-padding-x: 1.25rem;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility
$modal-content-box-shadow-sm-up: 0 .5rem 1rem rgba($black, .35);
$modal-title-line-height: 1.75rem;

// Close

$close-font-size: 1.65rem;
$close-font-weight: $font-weight-normal;

// Forms

$input-border-radius: 4px;
$input-border-color: $gray-150;
$input-color: $darkBlueAlt;
$input-focus-color: $input-color;
$input-font-size: $font-size-base;
$input-font-size-sm: $font-size-sm;
$input-placeholder-color: $midBlueAlt;
$input-box-shadow: none;
$input-focus-box-shadow: none;
$input-focus-bg: $bgDense;
$input-padding-y: 0.6rem;
$input-padding-x: 0.9rem;
$input-height: 42px;
//$input-font-family: $font-open-sans;
$input-line-height: 1.5;
$btn-font-size-sm: $small-font-size;
$input-disabled-bg: $gray-50;

$input-group-addon-border-color: $input-border-color;

$custom-switch-width: 2.75rem;
//$custom-switch-indicator-size: 20px;
$custom-control-indicator-checked-bg: #38aae6;
$custom-control-indicator-checked-border-color: #38aae6;
$custom-checkbox-indicator-indeterminate-bg: #38aae6;
$custom-checkbox-indicator-indeterminate-border-color: #38aae6;

$custom-control-indicator-border-color: #dddddd;
$custom-control-indicator-hover-border-color: #bbb9d1;

// Cards

$card-border-radius: 0;
$card-cap-bg: rgba($bgMid, 0.5);
$card-border-color: darken($bgMid, 5);
$card-border-radius: 4px;

// Buttons

$btn-font-size: $font-size-base;
$btn-font-size-sm: $font-size-sm;
$btn-padding-x: 16px;
$btn-padding-y: 6px;
$btn-disabled-opacity: 0.35;
$btn-box-shadow: unset;
$btn-active-box-shadow: unset;
$btn-border-radius: 4px;
$btn-font-weight: 500;
$btn-padding-y-sm: 4px;
$btn-padding-x-sm: 11px;
$caret-spacing: 0.4rem;

// Tables

$table-cell-padding: 1rem 0.75rem;
$table-hover-bg: $gray-100;
$table-border-color: $gray-200;
$table-border-color-light: #ebebeb;
$table-cell-padding-sm: 0.75rem;

// Navbar

$navbar-brand-font-size: $font-size-lg;
$main-sidebar-bg: $brightBlue;

// Tabs
$nav-tabs-link-active-bg: $component-active-bg;
$nav-tabs-border-color: $component-active-bg;
$nav-tabs-link-hover-border-color: $component-active-bg;
$nav-tabs-link-active-border-color: $component-active-bg;
$nav-tabs-link-active-color: $component-active-color;
$nav-tabs-border-radius: 0.4rem;

// List group

$list-group-active-color: $component-active-color;
$list-group-active-bg: $component-active-bg;
$list-group-action-active-color: $component-active-color;
$list-group-action-active-bg: $component-active-bg;
$list-group-item-padding-y: 0.75rem;

// Dropdowns

$dropdown-min-width: 12rem;
$dropdown-font-size: $small-font-size;
$dropdown-link-active-bg: $component-active-bg;
$dropdown-link-active-color: $component-active-color;
$dropdown-box-shadow: 0 0.2rem 0.4rem rgba($black, 0.1);
$dropdown-padding-y: 0.5rem;
$dropdown-item-padding-x: 1rem;
$dropdown-item-padding-y: 0.35rem;
$dropdown-header-padding: $dropdown-padding-y $dropdown-item-padding-x;
$dropdown-border-color: rgba($black, 0.1);

// Badges

$badge-padding-y: 0.5em;
$badge-padding-x: 0.85em;
$badge-font-weight: $font-weight-bold;
$badge-font-size: 12px;

// Tooltips

$tooltip-bg: #383838bb;
$tooltip-border-radius: 4px;
$tooltip-font-size: 12px;
$tooltip-arrow-color: $tooltip-bg;
$tooltip-opacity: 1;

// Dropzones

$dropzone-bg: #fafafa !default;
$dropzone-border-color: #eee !default;
$dropzone-active-bg: #9be1c436 !default;
$dropzone-active-border-color: #459d79 !default;
$dropzone-border-radius: 0.5rem !default;

// Colors

$text-color: $darkBlue;
$text-darkBlue: $darkBlue;

// Animations

$fade-in-normal: fade-in 300ms ease-out !default;
