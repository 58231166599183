@mixin theme-list-group-item-variant($state, $background, $color) {
  .list-group-item-#{$state} {
    color: $color;
    background-color: $white;

    &.list-group-item-action {
      &.active,
      &:active {
        color: $component-active-color;
        background-color: $background;
        border-color: $background;

        &:before {
          background-color: darken($background, 25%);
        }
      }
    }
  }
}

@mixin theme-folder-nav-link($state, $background, $color) {
  .folder-nav-#{$state} {
    .nav-link {
      color: $color;

      &:not(.active) {
        &:hover {
          background-color: rgba($background, .25);
        }
      }
    }

    &:not(.is-dragging) {
      .nav-link {
        &.active {
          color: $component-active-color !important;
          background-color: $background !important;
        }
      }
    }
  }
}

@mixin badge-variant($bg) {
  color: darken($bg, 10%);
  background-color: rgba($bg, 0.15);

  @at-root a#{&} {
    @include hover-focus() {
      color: color-yiq($bg);
      background-color: darken($bg, 10%);
    }

    &:focus,
    &.focus {
      outline: 0;
      box-shadow: 0 0 0 $badge-focus-width rgba($bg, .5);
    }
  }
}
