// Tabs

.card-header-tabs {
    border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
    margin: 0;
}

.nav-tabs {
    .nav-item {
        font-size: 0.8rem;
        padding-top: 0.35rem;
        padding-bottom: 0.35rem;
    }
}

// Subnav

.subnav {
    .nav-link {
        height: 55px;
        padding: 1.2rem 0.95rem;
        color: $midBlue;
        border-bottom: solid 1px transparent;
        font-size: $font-size-base;
        font-weight: 500;

        &:not(.disabled):hover {
            color: $darkBlueAlt;
        }
    }

    .nav-link.active {
        border-bottom-color: $darkBlueAlt;
        color: $darkBlueAlt;
    }

    .nav-link.disabled {
        opacity: 0.5;
    }
}

.nav-link.active {
    font-weight: 600;
    border-bottom-color: $green;
}

// Search form

.nav-form-search {
    background-color: $white;
    color: $midBlue !important;
    margin-left: 10px;
    margin-top: 5px;

    &:focus,
    &.has-content {
        background-color: $white;
    }
}

// Sidebar

.main-sidebar {
    flex: 0 0 $sidebar-width;
    background-color: #f4f4f4;

    > div {
        top: 0.75rem;
        width: 150px;
    }

    * {
        letter-spacing: normal;
    }
}

.content-sidebar {
    border-right: $border-width solid $border-color;
    width: 160px;
    min-width: 160px;
    background-color: $lightBlueLight;
    @include media-breakpoint-up(xl) {
        width: 240px;
        min-width: 240px;
    }

    @include media-breakpoint-up(xxl) {
        width: 280px;
        min-width: 280px;
    }

    &::-webkit-scrollbar {
        width: 5px !important;
    }
}

.content-sidebar-document {
    border: $border-width solid $border-color;
    border-radius: 4px;
    width: 220px;
    height: fit-content;
    min-width: 220px;
    background-color: $lightBlueLight;
    margin-left: 30px;
    margin-top: 48px;
    @include media-breakpoint-up(xl) {
        width: 240px;
        min-width: 240px;
    }

    @include media-breakpoint-up(xxl) {
        width: 280px;
        min-width: 280px;
    }
}

.content-sidebar-document-1 {
    border: $border-width solid $border-color;
    border-radius: 4px;
    width: 220px;
    height: fit-content;
    min-width: 220px;
    background-color: $lightBlueLight;
    margin-left: 6px;
    margin-right: 48px;
    @include media-breakpoint-up(xl) {
        width: 240px;
        min-width: 240px;
    }

    @include media-breakpoint-up(xxl) {
        width: 280px;
        min-width: 280px;
    }
}

.nav-dropdown-hover {
    color: $darkBlueAlt;

    .dropdown {
        visibility: hidden;

        &.dropdown-open {
            visibility: visible !important;
        }
    }

    &:hover {
        .dropdown {
            visibility: visible !important;
        }
    }
}

.folder-nav {
    .nav-link {
        padding: 0.55rem 1rem 0.55rem 1.5rem;
        cursor: pointer !important;
        color: $darkBlueAlt !important;

        &:not(.active) {
            &:hover {
                background-color: $list-group-hover-bg;
            }
        }

        &:hover .icon-grip {
            visibility: visible !important;
        }

        &.is-dragging {
            box-shadow: $box-shadow-sm;
            border-color: $border-color !important;
            border-radius: $border-radius !important;
            background-color: $white !important;
        }

        .icon-grip {
            visibility: hidden;
            min-width: 16px;
            position: absolute;
            left: 4px;
        }

        .docrev-dropdown-toggle {
            min-width: 16px;
        }

        &.active {
            .badge-light {
                background-color: $bgDense;
            }
        }
    }

    &.is-dragging {
        background-color: lighten($dropzone-active-bg, 15%);
        border-color: $dropzone-border-color;
    }

    &.dragging-over {
        background-color: $dropzone-active-bg !important;
        border-color: $dropzone-active-border-color !important;
    }
}

.sidebar-nav {
    .nav-link {
        padding-left: 1.5rem;
        color: $component-active-color;
        font-size: $font-size-sm;
        letter-spacing: 0;
        display: flex;
        align-items: center;

        &.active {
            color: $component-active-color;
            background-color: $gray-200;
            font-weight: $font-weight-bolder;
        }

        &:hover {
            background-color: rgba($gray-200, 0.4);
        }

        svg {
            margin-right: .5rem;
        }
    }
}

.content-static-body {
    position: absolute;
    top: 73px;
    right: 0;
    left: 0;
    bottom: 0;
    color: $darkBlueAlt;
    background-color: $lightBlueLight;

    &.has-subnav {
        top: 128px;
    }

    &.is-dragging-group {
        .droppable {
            background-color: $dropzone-bg;
            border-color: $dropzone-border-color;
        }

        .folder-nav.droppable {
            background-color: lighten($dropzone-active-bg, 15%);
        }
    }

    .folder-nav.droppable {
        min-height: 36px;
    }

    .table {
        color: $darkBlueAlt;
    }

    &:not(.is-dragging-group) {
        .folder-nav {
            .nav-link {
                &:hover {
                    background-color: $bgMid;
                }

                &.active {
                    color: $component-active-color;
                    background-color: $bgMid;
                }
            }
        }
    }

    &.is-dragging-item {
        .data-table-group-droppable {
            background-color: $dropzone-bg;
            border-color: $dropzone-border-color !important;
            border-style: dashed;

            &.drop-disabled {
                background-color: #ffedef;
                border: 0 !important;
            }

            &.dragging-over {
                background-color: $dropzone-active-bg !important;
                border-color: $dropzone-active-border-color !important;

                .data-table-group-header {
                    background-color: $dropzone-active-bg !important;
                }
            }
        }

        .animation-fade {
            background-color: $white !important;
        }

        .text-primary {
            color: $darkBlueAlt !important;
            font-weight: 500;
        }

        .btn-secondary {
            background-color: $midBlue;
        }

        .form-control {
            color: $darkBlueAlt !important;
            font-size: 12px !important;
            font-weight: 500 !important;
        }

        .container form h3 {
            color: $darkBlueAlt;
            font-weight: 500;
            font-size: $font-size-lg;
        }
    }
}

.text-color {
    color: $darkBlueAlt !important;
    font-weight: 500;
}

.text-darkBlueAlt {
    color: $darkBlueAlt !important;
}

.content-static-filters {
    top: 73px;
    right: 0;
    left: 160px;
    position: absolute;
    height: 75px;
    transition: top 0.3s;

    &.has-subnav {
        top: 130px;
    }

    @include media-breakpoint-up(xl) {
        left: 240px;
    }
    @include media-breakpoint-up(xxl) {
        left: 280px;
    }
}

.docrev-dropdown-toggle {
    &.dots {
        color: $coloralt;
        opacity: 0.75;

        &:hover {
            opacity: 1;
        }
    }
}

// Pills
.nav-dr-pills {
    background-color: #f5f4f8;

    .nav-link {
        color: $midBlueAlt;
        font-weight: 500;
        transition: background-color 100ms ease-in-out;

        &:hover {
            color: $component-active-color;
            background-color: $white;
            box-shadow: 0 1px 5px 1px rgb(60 64 67 / 15%);
            border-radius: 3px;
        }

        &.active {
            color: $component-active-color;
            background-color: $white;
            box-shadow: 0 1px 5px 1px rgb(60 64 67 / 15%);
            border-radius: 3px;
        }

        &.disabled {
            color: $muted;
        }
    }
}

.task-modal-history {
    box-shadow: inset -2px 0px 2px 0px rgba(0, 0, 0, 0.082);

    a.nav-link {
        font-size: $font-size-sm;
        padding: 0.75rem 1rem;
        color: $darkBlue;

        &.active {
            background-color: $lightBlueDense;
        }

        &:hover:not(.active) {
            background-color: $lightBlueMid;
        }
    }
}
