ul.dnd-list-grid {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 176px);
    gap: 15px;
    padding: 0;
    margin: 0 0 0 15px;

    li {
        position: relative;
        list-style: none;
        width: 150px;
        margin-bottom: 0.5rem;

        &.selected {
            .btn-media-select {
                opacity: 1;
                color: $darkBlueAlt;
            }
        }
    }

    .btn-media-select {
        color: $white;
        opacity: 0.75;

        &:hover {
            opacity: 1;
        }
    }

    .file-header {
        visibility: hidden;
    }

    .file-content {
        box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 6px 0 rgba(34, 33, 81, 0.3);
    }

    li.selected,
    li:hover {
        .file-header {
            visibility: visible !important;
        }
    }

    li.selected {
        background-color: $bglight;
        border-radius: 3px;
    }

    li.selected .file-content {
        transform: scale3d(0.78, 0.82, 1);
        z-index: 100;
    }
}

#media-selector-modal {
    height: 100% !important;
}

.media-selector-img-wrapper {
    width: 125px;
    margin: 0 5px 14px 5px;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 6px 0 rgba(34, 33, 81, 0.3);

    &.active,
    &:hover {
        .media-selector-img,
        .image {
            background-color: $bgDense;
        }

        img {
            transform: scale3d(0.9, 0.85, 1);
        }
    }

    &.active {
        box-shadow: 0 0 0 1px #526bd3, 0 1px 6px 0 #37383c;

        .media-selector-img,
        .image {
            background-color: $buttonBlueAlt;
        }
    }
}

.media-selector-img {
    img {
        height: 170px;
        object-fit: cover;
    }

    .image {
        padding: 0.25rem;
        background-color: $bgDense;
        border: 1px solid #dee2e6;
        border-radius: 0.25rem;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
        max-width: 100%;

        &:hover {
            background-color: $bgDense;
        }

        &:focus {
            border-color: darken($bglight, 30%);
        }
    }

    &:hover {
        cursor: pointer;

        img {
            background-color: $bglight;
            border-color: darken($bglight, 30%);
        }
    }
}
