//body,
//html {
//    height: 100%;
//}

* {
    letter-spacing: $letter-spacing;
}

.font-size-sm {
    font-size: $font-size-sm;
    letter-spacing: normal;
}

.small {
    letter-spacing: normal;
}

.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^='right'] > .arrow {
    left: calc(11.5rem - 1px);
}

.popover {
    max-width: 300px;
}

.dr-container {
    border: 1px solid #dde1e8;
    border-radius: 0.2rem;
    box-shadow: $dr-shadow-sm;
    background-color: $white;

    .docrev-react-select-container {
        min-width: 250px;
        max-width: 340px;
    }

    &.dragging-over {
        border: 1px dashed $dropzone-active-border-color;
    }

    > .table {
        th {
            border-top: 0;

            &:first-of-type {
                border-top-left-radius: 0.2rem;
            }

            &:last-of-type {
                border-top-right-radius: 0.2rem;
            }
        }
    }
}

.page-login {
    .dr-container {
        border: none;
        box-shadow: 0 6px 1rem 2px rgb(0 0 0 / 21%);
    }

    img.logo {
        width: 66%;
        margin-bottom: 1.5rem;
    }
}

.text-secondary {
    color: $darkBlue !important;
}

.area-include-variants {
    -webkit-box-shadow: 0px 0px 3px 1px rgba(229, 102, 166, 1);
    -moz-box-shadow: 0px 0px 3px 1px rgba(229, 102, 166, 1);
    box-shadow: 0px 0px 3px 1px rgba(229, 102, 166, 1);
}

.break-word {
    overflow-wrap: break-word !important;
}

.vertical-align-middle {
    vertical-align: middle;
}

.sidebar-titles {
    word-break: break-word;
    overflow: hidden;
    hyphens: auto;
}

.sidebar-icons {
    flex-shrink: 0;
    min-width: 14px;
}


.publication-documents {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
}

.document-col {
    flex-basis: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: $font-weight-bold;
}

.variant-group-title {
    font-size: 10px !important;
    font-weight: $font-weight-700 !important;
}

.select-variant-content {
    width: 245px;
}

.scrollable-list {
    display: flex;
    flex-direction: column;
}

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar:horizontal {
    height: 12px;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background-color: rgba($darkBlue, 0.7);
    border-radius: 5px;
}

.dr-side-container {
    background: $gray-50;
    padding: 1.5rem;

    .label-title {
        font-size: $font-size-sm;
    }

    .template-field-select {
        font-size: $font-size-sm !important;
    }
}

.dr-instellingen-container {
    background: white;
    padding: 1.5rem;

    .label-title {
        font-size: $font-size-sm;
    }

    .template-field-select {
        font-size: $font-size-sm !important;
    }
}

.document-variants-bewerken {
    text-transform: uppercase;
    font-weight: $font-weight-regular;
    color: $darkBlueAlt;
    margin-bottom: 0;
    width: 200px;
    margin-top: 7px;
    float: left;
}

.container-class {
    margin-top: 39px !important;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-help {
    cursor: help;
}

.uk-button-default,
.uk-button-primary {
    background-color: $primary;
}

.bg-transparent-orange {
    background-color: rgba(255, 166, 0, 0.1); /* set the orange color and opacity */
    padding: 2px; /* optional: add some padding to the icon to make the background more visible */
    border-radius: 4px; /* optional: add some border radius to the background */
}

.bg-transparent-blue {
    background-color: rgba(37, 84, 120, 0.06);
    padding: 2px;
    border-radius: 4px;
}

.text-transparent {
    background-color: rgba(255, 255, 255, 0);
}

.uk-modal {
    background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
    z-index: $zindex-modal-backdrop !important;

    .uk-modal-close {
        margin-right: 0.25rem;
    }

    .uk-modal-dialog {
        border-radius: $modal-content-border-radius;
        @include box-shadow($modal-content-box-shadow-sm-up);
        max-width: $modal-md !important;
    }

    .uk-modal-body {
        border-top-right-radius: calc($modal-content-border-radius - 1px);
        border-top-left-radius: calc($modal-content-border-radius - 1px);
        padding-left: $modal-inner-padding;
        padding-right: $modal-inner-padding;
    }

    .uk-modal-footer {
        border-bottom-right-radius: calc($modal-content-border-radius - 1px);
        border-bottom-left-radius: calc($modal-content-border-radius - 1px);
        padding: $modal-inner-padding - $modal-footer-margin-between * .5;
        border-color: $modal-footer-border-color;
    }
}

.uk-text-bold {
    font-weight: $font-weight-bold;
}

.uk-tooltip {
    z-index: 1300;
    background-color: #383838b5;
    border-radius: 4px;
    font-size: $font-size-sm;
    font-weight: 300;
    // Styling for when using a tooltip to show changes (i.e. used for the tagHistory in editArea)
    &.mark-changes {
        padding: 0;
        max-width: 500px;

        .tooltip-banner {
            width: 100%;
            text-align: center;
            font-style: normal;
            background-color: #fdde86;
            color: #444;

            .tooltip-title {
                padding: 5px;
            }
        }

        .inner-tooltip {
            padding: 5px;
        }
    }
}

.tag-changes-icon {
    color: $orangeAlt;
}

.table-hover {
    tbody tr {
        @include hover() {
            cursor: pointer;
        }
    }
}

//.docrev-dropdown-toggle {
//  background-color: $gray-300;
//  color: $gray-700;
//  padding: .15rem .12rem;
//
//  &:hover {
//    background-color: $gray-400;
//  }
//}

.docrev-dropdown-toggle-simple {
    color: $darkBlueAlt;
    padding: 0.15rem 0.12rem;
    font-size: 1.15rem;
}

.dropdown > span {
    &:hover {
        cursor: pointer;
    }
}

.react-bootstrap-table {
    .table {
        table-layout: fixed;
    }

    .table thead th {
        border-top: none;
        font-weight: $font-weight-normal;
        font-size: $font-size-sm;
        color: $text-muted;

        &:nth-child(3) {
            width: 15%;
        }
    }

    .table td {
        vertical-align: middle;
    }
}

.modal-title.h4 {
    font-size: 1.125rem;
    font-weight: $font-weight-normal;
}

.avatar {
    .dropdown-toggle {
        font-size: $font-size-base;
        font-weight: $font-weight-light;
        color: $white;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        width: 35px;
        line-height: 35px;
        background-color: #4baae5;
        border-radius: 50%;

        &::after {
            content: none;
        }

        &:hover,
        &[aria-expanded='true'] {
            cursor: pointer;
            background-color: darken($primary, 0.5%);
        }
    }
}

.document-avatar {
    .dropdown-toggle {
        font-size: $font-size-sm;
        font-weight: $font-weight-light;
        color: $white;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        width: 25px;
        line-height: 25px;
        background-color: #4baae5;
        border-radius: 50%;
        margin-top: 1px;

        &::after {
            content: none;
        }

        &:hover,
        &[aria-expanded='true'] {
            cursor: pointer;
            background-color: darken($primary, 0.5%);
        }
    }
}

.btn-group {
    .btn:not(:first-child) {
        padding-left: 10px;
        padding-right: 10px;
    }
}

#page-content-wrapper {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.14);
    background-color: $white;

    > .breadcrumbs {
        height: 73px;
        z-index: 1050; // fix for z-index issue on the avatar dropdown

        .button {
            color: orange !important;
        }

        .links {
            font-size: $font-size-lg;
            color: $darkBlueAlt !important;
            font-weight: $font-weight-bold;

            a {
                color: $darkBlueAlt !important;
                text-decoration: none;
            }
        }
    }

    > nav {
        left: $sidebar-width;
        z-index: 1000;

        &.subnav {
            top: 73px;
        }
    }

    overflow: hidden;

    .container-fluid {
        color: $darkBlueAlt !important;
    }
}

#content {
    transition: all 0.3s;
    width: 100%;
}

h4 {
    svg {
        font-size: 1.3rem;
        color: $gray-700;
        margin-right: 0.5rem;
    }
}

#sidebar {
    z-index: 1100;
    width: 370px;
    transition: all 0.3s ease 0s;
    position: fixed;
    height: 100%;
    right: 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    overflow-y: auto;
    background-color: $lightBlueLight;

    h4 {
        margin-bottom: 0;
        color: $darkBlueAlt;
        font-size: $font-size-lg;
        font-weight: 500;
    }

    &.is-closed {
        width: 0 !important;
    }

    .close {
        font-size: 2rem;
        font-weight: $font-weight-light;
    }

    .btn-link {
        color: $primary;
    }
}

.icon-spin {
    -webkit-animation: icon-spin 2s linear infinite;
    animation: icon-spin 2s linear infinite;
}

@-webkit-keyframes icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}

@keyframes icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}

.animation-fade {
    animation: fade-in 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.btn-link.disabled,
.btn-link:disabled {
    background-color: transparent !important;
    border-color: transparent !important;
}

.bg-light {
    background-color: $lightBlueLight !important;

    .btn-light {
        background-color: #fafbfc;
        border-color: rgba(27, 31, 35, 0.15);

        &:hover {
            background-color: #f3f4f6;
        }

        &:not(:disabled):not(.disabled):active {
            background-color: #f4f4f4;
        }
    }
}

.bg-light-gray {
    background-color: #f6f8fa !important;
}

.bg-lightBlueLight {
    background-color: $lightBlueLight;
}

.bg-lightBlueMidAlt {
    background-color: $lightBlueMidAlt;
}

.modal-button-bar {
    z-index: 100;

    a.active {
        font-weight: bold;
    }
}

.read-only-content > * {
    &:last-child {
        margin-bottom: 0;
    }
}

.area-text-block {
    padding: 1.5rem;
    min-height: 43px;

    border-radius: 5px;

    &:not(.is-deleted) {
        border: 1px solid #e2e4e7;
    }

    .mce-content-body {
        margin: 0 !important;
        padding: 0 !important;
        outline: unset;

        * {
            color: $darkBlueAlt !important;
            line-height: 1.8 !important;
        }

        h3,
        h4 {
            font-weight: $font-weight-bolder;
        }

        .newTextBlockPlaceholder {
            color: $midBlue !important;
        }
    }
}

.area-text-block-has-editor {
    border-color: $bglight;
    box-shadow: 0 0.125rem 0.4rem rgba(0, 0, 0, 0.15);
    max-height: 100%;
}

.area-text-block-no-editor {
    &:hover {
        cursor: pointer;
        // box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* add your desired box shadow values */
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
    }

    &.block-variants-edit-mode {
        outline: 1px solid $darkBlue;
    }
}

.is-dragging {
    .area-text-block-no-editor {
        &:hover {
            border-color: #e2e4e7 !important;
        }
    }
}

.is-dragging-item {
    .comment-btn {
        visibility: hidden;
    }
}

.area-text-block-min-height {
    min-height: 58px !important;
}

.linked-block-modal-blocks {
    .linked-block {
        .area-read-only {
            cursor: pointer;
            border: 1px solid #e2e4e7;
            border-radius: $border-radius;

            &:hover {
                background-color: $lightBlueMid;
            }
        }

        &.is-selected {
            .area-read-only {
                background-color: $component-active-bg;
                border: 1px solid $component-active-color;
            }
        }
    }

    .area-block {
        margin-bottom: 1rem !important;
    }
}

.area-read-only {
    padding: 1.5rem;

    * {
        line-height: 1.8;

        a,
        a:hover {
            text-decoration: underline;
            word-break: break-all;
        }
    }

    > *:last-child {
        margin-bottom: 0 !important;
    }

    > ins:last-of-type,
    > del:last-of-type {
        > *:last-child {
            margin-bottom: 0 !important;
        }
    }

    ul,
    ol,
    table {
        margin-bottom: 6px !important;
    }

    h3,
    h4 {
        font-size: 1rem;
        margin-top: 0;
        margin-bottom: 6px;
        line-height: 1.8;
        font-weight: $font-weight-bolder;
        color: $darkBlueAlt;
    }

    h4 {
        font-size: 0.875rem;
    }
}

.area-preview {
    .area-read-only {
        color: $darkBlueAlt;
        border-top: 1px solid $border-color !important;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .block-layout {
        overflow: auto;

        .area-read-only {
            border-top: unset !important;
            padding: 0;
        }

        &.blockLayout-type-default {
            * {
                font-size: $font-size-sm !important;
            }
        }

        .area-block {
            &:last-child {
                margin-bottom: 2rem !important;
            }
        }

        &.show-table-header {
            & > div:first-child {
                background-color: #f6f8fa;
            }
        }

        .col {
            min-width: 140px;
            word-break: break-all;
        }
    }

    .block-name {
        display: none !important;
    }

    .tiny-mce-content-body-linked {
        color: $midBlueAlt !important;

        h3,
        h4 {
            color: $midBlueAlt !important;
        }
    }
}

.reimbursement-read-only {
    margin-top: 10px !important;
    margin-bottom: 15px !important;
}

.label-block-read-only {
    min-height: 43px;

    &:not(.is-deleted) {
        margin-top: 12px;
        border: 1px dashed #e2e4e7;
    }
}

.label-block-content {
    padding: 1.4rem 0.5rem 1rem 1rem;
    line-height: 1.75rem;
}

.label-block.block-context-left-checkChanges,
.file-block.block-context-left-checkChanges {
    padding: 0 !important;
    border: 1px solid #dfdfdf !important;

    .check-changes-header {
        padding: 0.75rem 0 0.75rem 0.5rem;
        font-size: 0.65rem;
        border-bottom: 1px solid #dfdfdf !important;

        &.has-changes {
            background-color: #ffd !important;
        }

        &.no-changes {
            button {
                background-color: $muted;
                border-color: $muted;
            }
        }

        &.accepted {
            background-color: #dcf5d2 !important;
            font-weight: bold;

            button.btn-accept {
                background-color: $green;
                border-color: $green;
            }
        }

        &.rejected {
            background-color: $bgDeleted !important;
            font-weight: bold;

            button.btn-reject {
                background-color: $red;
                border-color: $red;
            }
        }
    }
}

.basic-editor-text-block {
    margin-bottom: 10px;
    min-height: 38px;
}


.editor-toolbar {
    position: absolute;
    top: -13px;
    right: 0;

    .uk-button-group-small .uk-button-small .uk-icon {
        margin-bottom: unset !important;
        border: 1px solid #e2e4e7;
        background-color: white;
        padding: 5px 5px 5px 5px;
    }

    .uk-button-group-small .uk-button-small.btn-edit-variants-warning {
        span.uk-icon {
            border-color: $danger;
            color: $danger;
        }
    }

    .uk-button-group-small .uk-button-small.btn-edit-variants {
        span.uk-icon {
            border-color: $warning;
            color: $warning;
        }
    }

    .uk-button-group-small .uk-button-small.btn-edit-variants-warning-icon {
        padding-right: 9px;
        position: relative;

        .triangle-icon {
            position: absolute;
            top: -9px;
            right: 0;
            color: $danger;
        }

        span.uk-icon {
            border-color: $danger;
            color: $danger;
        }
    }

    .linked-icon {
        color: $docRevOrange1;
        border: 1px solid #e2e4e7;
        border-radius: 5px;
        background-color: white;
        width: 28px;
        height: 29px;
        display: inline-block;
        position: absolute;
        right: -2px;
        top: -1px;
    }

    .linked-icon-2 {
        color: $docRevOrange1;
        border: 1px solid #e2e4e7;
        border-radius: 5px;
        background-color: white;
        width: 28px;
        height: 28px;
    }

    span {
        padding-top: 2px;
        padding-bottom: 2px;
        border-radius: 5px;
    }

    .linked-span-icon {
        background-color: transparent;
    }
}

.is-dragging-block {
    max-width: 200px;
    max-height: 120px;
    overflow: hidden;

    .area-text-block,
    .drag-handle {
        background-color: $white;
    }

    .block-name,
    .editor-toolbar {
        visibility: hidden !important;
    }
}

.publication-form {
    .mce-content-body {
        height: 120px;
        width: 500px;
        overflow: hidden;
    }
}

.form-label {
    white-space: nowrap;
    overflow: hidden;
}

.paketten-buttons {
    border-radius: $border-radius;
}

.bm-item-list {
    padding: 0;

    h4 {
        color: $darkBlue;
    }

    .reply {
        background-color: $lightBlueMid !important;
    }
}

.bm-cross {
    background: #7c7d7d;
}

.bm-cross-button:hover .bm-cross {
    background: $darkBlue;
}

// Borders

.border-lg {
    border-width: 10px !important;
}

// Operations List

.op-list-row-title {
    &:hover {
        cursor: pointer;
        background-color: $gray-100;
    }
}

// Diff marking
.opList-linked-variants-ins {
    background-color: $diff-marking-ins-bg-color !important;
    color: $diff-marking-ins-color !important;
}

.opList-linked-variants-del {
    background-color: $diff-marking-del-bg-color !important;
    color: $diff-marking-del-color !important;
    text-decoration: line-through;
}

.opList-linked-variants-not-in-opEntry {
    color: #71777dff !important;
}

.opList-block-text.opList-block-removed {
    font-style: normal;
    background-color: $diff-marking-del-bg-color;
    color: $diff-marking-del-color;
    text-decoration: line-through;
}

.opList-block-reimbursement.opList-block-removed {
    .uk-background-muted {
        background: $diff-marking-del-bg-color !important;
    }

    .uk-table-striped tr {
        background: $diff-marking-del-bg-color !important;
    }

    .uk-table-striped td {
        text-decoration: line-through;
    }

    .fixed-link {
        font-style: normal;
        background-color: inherit !important;
        color: inherit !important;
        border: none;
    }

    font-style: normal;
    color: $diff-marking-del-color !important;
}

.opList-block-reimbursement del * {
    color: $diff-marking-del-color !important;
    background: $diff-marking-del-bg-color !important;
}

.opList-block-table.opList-block-removed {
    .uk-background-muted {
        background: $diff-marking-del-bg-color !important;
        color: $diff-marking-del-color !important;
    }

    .uk-table-striped tbody tr {
        background: $diff-marking-del-bg-color !important;
        color: $diff-marking-del-color !important;
    }

    .fixed-link {
        font-style: normal;
        background-color: inherit !important;
        color: inherit !important;
        border: none;
    }

    font-style: normal;
    color: $diff-marking-del-color !important;
}

.opList-block-table del * {
    color: $diff-marking-del-color !important;
    background: $diff-marking-del-bg-color !important;
}

.opList-block-reimbursement.opList-block-show-changes:not(.opList-block-removed) li.replacement,
.opList-block-table.opList-block-show-changes:not(.opList-block-removed) li.replacement,
.opList-block-text.opList-block-show-changes:not(.opList-block-removed) li.replacement {
    background-color: $diff-marking-ins-bg-color;
    color: $diff-marking-ins-color;
}

.opList-block-reimbursement.opList-block-show-changes:not(.opList-block-added) li.removed,
.opList-block-table.opList-block-show-changes:not(.opList-block-added) li.removed,
.opList-block-text.opList-block-show-changes:not(.opList-block-added) li.removed {
    background-color: $diff-marking-del-bg-color;
    color: $diff-marking-del-color;
}

.opList-block-text.opList-block-added {
    font-style: normal;
    background-color: $diff-marking-ins-bg-color !important;
    color: $diff-marking-ins-color !important;
}

.opList-block-reimbursement.opList-block-added {
    .uk-background-muted {
        background: $diff-marking-ins-bg-color !important;
    }

    .uk-table-striped tr {
        background: $diff-marking-ins-bg-color !important;
    }

    .fixed-link {
        font-style: normal;
        background-color: inherit !important;
        color: inherit !important;
        border: none;
    }

    font-style: normal;
    color: $diff-marking-ins-color !important;
}

.opList-block-table.opList-block-added {
    .uk-background-muted {
        background: $diff-marking-ins-bg-color !important;
    }

    .uk-table-striped tbody tr {
        background: $diff-marking-ins-bg-color !important;
    }

    .fixed-link {
        font-style: normal;
        background-color: inherit !important;
        color: inherit !important;
        border: none;
    }

    font-style: normal;
    color: $diff-marking-ins-color !important;
}

// Do not give references (kruisverwijzingen) special dark highlighting and a border. Instead just use the already present styling
.opList-block-text {
    del,
    .del,
    del *,
    .del * {
        font-style: normal;
        background-color: $diff-marking-del-bg-color !important;
        color: $diff-marking-del-color !important;
        text-decoration: line-through;
    }

    ins,
    .ins,
    ins *,
    .ins * {
        font-style: normal;
        background-color: $diff-marking-ins-bg-color !important;
        color: $diff-marking-ins-color !important;
    }

    ins .fixed-link,
    .ins .fixed-link {
        font-style: normal;
        background-color: inherit !important;
        color: inherit !important;
        border: none;
    }

    del .fixed-link,
    .del .fixed-link {
        font-style: normal;
        background-color: inherit !important;
        color: inherit !important;
        border: none;
    }
}

.opVariant-content {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    line-height: 1.7;
    background-color: $bglight;

    .opVariant-content-inner {
        box-shadow: #3c404326 0 1px 3px 1px;
        background-color: #fff;
        padding: 2rem 1.5rem 1rem 1.5rem;

        .opList-block-container {
            // We simulate a background-image rather than border to show dashed line because the size and distance of the border dashes cannot be affected
            // And via background-image we can affect them
            background-image: linear-gradient(to right, #d5d5d5 50%, rgba(255, 255, 255, 0) 0%);
            background-position: bottom;
            background-size: 20px 1px;
            background-repeat: repeat-x;

            p {
                margin-bottom: 0;
                margin-top: 0;
            }

            padding-bottom: 0.75rem;
            padding-top: 0.75rem;
        }

        .opList-block-container:last-child {
            // Do not show dashed line at bottom of last child
            background: none;
        }
    }

    h3,
    h4,
    h5,
    h6 {
        font-size: 13px;
        font-weight: 600;
        font-family: 'Lato', sans-serif;
        margin-bottom: 1rem;
    }

    h3 {
        font-size: $font-size-lg;
    }

    ins {
        background-color: $diff-marking-ins-bg-color !important;
        color: $diff-marking-ins-color !important;
        border-color: $diff-marking-ins-color !important;

        p,
        h3,
        h4,
        h5,
        h6 {
            background-color: $diff-marking-ins-bg-color !important;
            color: $diff-marking-ins-color !important;
            border-color: $diff-marking-ins-color !important;
        }
    }

    del {
        background-color: $diff-marking-del-bg-color !important;
        color: $diff-marking-del-color !important;
    }
}

// Cards

.card-deleted {
    background-color: rgba($danger, 0.1);

    &:hover {
        background-color: rgba($danger, 0.15);
    }

    .text-muted {
        color: $gray-600 !important;
    }
}

// Badges

.badge-secondary {
    background-color: $lightBlueLight;
    color: $component-active-color;
}

.badge-light {
    color: $gray-600;
    background-color: $gray-150;
}

// Forms

//.custom-control {
//    z-index: auto !important;
//}
//
//.custom-control-label {
//    font-size: 0.8rem;
//    padding-top: 1px;
//}

// Comments

.text-highlighted {
    color: #b3cc27 !important;
}

.comment-resolved {
    background-color: $gray-100;
    color: $gray-600;

    .uk-tag {
        opacity: 0.5;
    }
}

$orange_tag_bg: #e78931;
$orange_tag_color: #ffffff;
$purple_tag_bg: #68348e;
$purple_tag_color: #f2e5ff;
$green_tag_bg: #adcb4b;
$green_tag_color: #ffffff;
$yellow_tag_bg: #f6ec36;
$yellow_tag_color: #bb9432;
$black_tag_bg: #3e3e3e;
$black_tag_color: #dedede;
$blue_tag_bg: #50b4ee;
$blue_tag_color: #ffffff;

.uk-tag-danger {
    background-color: #d9534f;
    color: #f1f8ff;
}

.uk-tag-success {
    background-color: #5cb85c;
    color: #f1f8ff;
}

.uk-tag-orange {
    background-color: $orange_tag_bg;
    color: $orange_tag_color;
}

.uk-tag-purple {
    background-color: $purple_tag_bg;
    color: $purple_tag_color;
}

.uk-tag-green {
    background-color: $green_tag_bg;
    color: $green_tag_color;
}

.uk-tag-yellow {
    background-color: $yellow_tag_bg;
    color: $yellow_tag_color;
}

.uk-tag-black {
    background-color: $black_tag_bg;
    color: $black_tag_color;
}

.uk-tag-blue {
    background-color: $blue_tag_bg;
    color: $blue_tag_color;
}

.tags-invalid .react-tags {
    border-color: $red !important;
}

.react-tags__selected {
    .uk-tag-orange {
        &:hover {
            background-color: darken($orange_tag_bg, 15%);
        }
    }

    .uk-tag-purple {
        &:hover {
            background-color: darken($purple_tag_bg, 15%);
        }
    }

    .uk-tag-green {
        &:hover {
            background-color: darken($green_tag_bg, 15%);
        }
    }

    .uk-tag-yellow {
        &:hover {
            background-color: darken($yellow_tag_bg, 15%);
        }
    }

    .uk-tag-black {
        &:hover {
            background-color: darken($black_tag_bg, 15%);
        }
    }

    .uk-tag-blue {
        &:hover {
            background-color: darken($blue_tag_bg, 15%);
        }
    }
}

// Speech bubble

.speech-bubble {
    position: relative;
    background: $gray-300;
    padding: 0.75rem;
    font-size: $font-size-sm;
    margin-bottom: 0.7rem;
    white-space: pre-wrap;
}

.speech-bubble:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 15%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: $gray-300;
    border-bottom: 0;
    border-left: 0;
    margin-left: -7px;
    margin-bottom: -10px;
}

// Custom headers

.subheader {
    border-bottom: 1px solid $gray-150;
    margin-bottom: 1.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    align-items: flex-end;
    min-height: 37px;

    h3 {
        margin-bottom: 0.1rem;
        font-weight: 500;
        font-size: $font-size-lg;
        color: $darkBlueAlt;
    }

    .btn + .btn:not(.dropdown-toggle) {
        margin-left: 0.5rem;
    }
}

.subheaderV2 {
    margin-bottom: 2.5rem;

    h3 {
        margin-bottom: 0.85rem;
        font-weight: 500;
        font-size: $font-size-lg;
        color: $darkBlueAlt;
    }

    .btn-sm {
        font-size: $font-size-sm;
        margin-bottom: 0.65rem;
        border-radius: 5px;
    }
}

h3.page-header {
    font-weight: 600;
    font-family: $font-open-sans;
    font-size: 1.4rem;
    color: $darkBlueAlt;
    margin-bottom: 2.5rem;
}

h4.form-header {
    font-weight: 500;
    font-family: $font-open-sans;
    font-size: 1rem;
    color: $darkBlueAlt;
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
}

// NestedDropdownMenu
.nested-dropdown-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.nested-dropdown-btn-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 150px;
}

.nested-dropdown-menu {
    display: flex;
    flex-direction: column;
}

.nested-dropdown-item {
    position: absolute;
    padding: 10px;
    padding-top: 0;
    margin-left: 10px;

    background: whitesmoke;

    &:hover {
        background: #94b6d4;
    }
}

.nested-dropdown-submenu {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
}

// Tasks

.op-entry-label-tasks-container {
    .op-entry-label-task:first-child {
        margin-bottom: 10px;
    }
}

.op-entry-label-task {
    padding: 5px 10px 5px 10px;
    width: 11rem;
    height: 3rem;
    font-size: 0.75rem;

    border-radius: 5px !important;
    color: #3e3e3e;
}

.op-entry-label-processor-container {
    width: 10rem;
}

// op-list-settings-tasks

.op-list-settings-task-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    h4 {
        font-size: 1rem;
    }
}

.op-list-settings-new-field {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 10px;
    margin-bottom: 2.5rem;
}

.op-list-new-field-primary-banner {
    width: 100%;
    text-align: center;
    color: $bglight;
}

#primaryCorrectAnswer {
    &.error {
        * {
            color: red !important;
            border-color: red !important;
        }
    }
}

// Op-list-report
.op-list-report-table {
    // Remove default borders
    td,
    th {
        border: none !important;
        outline: none !important;
        color: $brightBlue !important; // Normal text-color
        font-size: 0.75rem !important;
    }

    // Add border to bottom row
    tr:last-child {
        td {
            border-top: 1px solid black !important;
            font-weight: bold;
        }
    }

    // Add border to most right column
    tr {
        th:last-child,
        td:last-child {
            border-left: 1px solid black !important;
            font-weight: bold;
        }
    }

    .spacing-header {
        width: 12.5rem;
    }
}

.op-list-report-download-button {
    font-size: 24px;
}

.op-list-report-graph-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.op-list-report-graph-wrapper {
    width: 70%;
}

.op-list-sidebar-wrapper {
    flex-wrap: nowrap;
}

.op-list-summary {
    background-color: $gray-50;
}

.op-list-entry-card-cancelled {
    background-color: $cancelled-op-entry-bg-color;
}

// Link

.link-underlined {
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.spinner-success > * {
    background-color: $green;
}

.disableable-a[disabled] {
    pointer-events: none;
}

.bg-deleted {
    background-color: $bgDeleted !important;
}

.bg-insert {
    background-color: $bgInsert !important;
}

// Variant select 'cards'

.block-variants-btn .uk-icon {
    color: $darkBlueAlt;
}

.variant-item {
    border: 1px solid rgba(#c7e6ff, 0.5);

    &:hover {
        background-color: rgba(#c7e6ff, 0.85);
        border-color: #c7e6ff;
    }

    &.active {
        background-color: rgba(#c7e6ff, 0.25);
        color: $body-color;
        border-color: #c7e6ff;

        &:hover {
            background-color: rgba(#c7e6ff, 0.5);
        }
    }

    &.disabled {
        opacity: 0.75;
        background-color: rgba(#ccc, 0.2);
        border-color: rgba(#ccc, 0.5);
    }

    &.disabled:hover {
        cursor: default;
    }
}

.variant-group {
    font-size: 1rem;
    font-weight: 300;
}

.variant-level-0 > .child-variants {
    margin-left: 12px;
}

.variant-level-1 > .child-variants {
    margin-left: 22px;
}

// Variant select button

.variant-view {
    flex-grow: 1;
    max-width: 280px;
}

.variant-view__control {
    min-height: 42px !important;
}

mark.highlight-search {
    padding: 0;
    background-color: yellow;

    &.highlight-search-selected {
        background-color: orange;
    }
}

.compose-actions {
    min-width: 60px;

    @include media-breakpoint-up(xl) {
        min-width: 120px;
    }
}

.full-screen-wrapper {
    //overflow: hidden;
    //height: 100%;
}

.full-screen-wrapper-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 59px;
    left: 0;
    overflow-y: scroll;
    //padding: 40px;
}

.modal-90w {
    width: 90%;
    max-width: none !important;
}

.form-modal-90h {
    .modal-body {
        min-height: calc(90vh - 8rem);
        max-height: calc(90vh);
    }
}

.modal-with-button-bar {
    .modal-body {
        min-height: calc(100vh - 175px);
        max-height: calc(100vh - 175px);
    }
}

.rendered-content {
    p {
        margin: 0;
    }
}

.area-edit-page {
    padding: 2rem 2.5rem;
    //box-shadow: 0 1px 3px 1px rgb(60 64 67 / 15%);
    height: 100%;

    .block-context-left-editor,
    .keuze-opties,
    .dr-block-table-left {
        margin-left: 20px !important;
    }

    .links a {
        color: $darkBlueAlt !important;
        text-decoration: underline;
    }
}

.uk-button-group > .uk-button:not(:first-child):after {
    content: unset !important;
}

.ReactModal__Overlay {
    z-index: 1200 !important;
}

.large {
    font-size: $font-size-lg;
}

.extra-large {
    font-size: 1.5rem;
}

.section-area-row {
    .drag-handle {
        transition: opacity 150ms linear;
        opacity: 0;
    }

    &.is-dragging {
        .drag-handle {
            opacity: 1;
        }

        .settings-icon {
            visibility: hidden;
        }
    }

    &.is-expanded,
    &.is-expanded-draggable {
        .drag-handle {
            opacity: 0 !important;
        }
    }
}

.edit-area-block {
    .block-drag-handle {
        transition: opacity 150ms linear;
        opacity: 0;
    }

    &:hover,
    &.is-dragging {
        > .block-drag-handle {
            opacity: 1;
        }
    }
}

.section-areas:not(.is-dragging-over) {
    .section-area-row:hover {
        .drag-handle {
            opacity: 1;
        }
    }
}

.border-radius {
    border-radius: $border-radius;
}

.extra-small {
    font-size: 0.6875rem;
}

#modal-banners {
    .modal-body {
        padding: 0;
    }

    .modal-content {
        background-color: transparent;
        border: none;
        box-shadow: none;
    }

    .popup-container {
        position: relative;
    }

    .banner-container {
        position: relative;
        display: inline-block;
    }

    .banner-image {
        width: 100%;
        height: auto;
    }

    .slick-dots {
        position: absolute;
        bottom: .5rem;
        left: 50%;
        transform: translateX(-50%);
        list-style: none;
        display: flex;
    }

    .dot {
        width: 10px;
        height: 10px;
        border: 1px solid #9d9d9d;
        border-radius: 50%;
        background-color: #ffffff;
        margin: 0 5px;
        cursor: pointer;
    }

    .dot.active {
        background-color: #ccc;
    }

    .button-container {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
    }
}

#login-bg-image {
    background: url('../../images/flyingpapers.jpg') no-repeat center center fixed;
    background-size: cover;
    opacity: .5;
    position: fixed;
    height: 100vh;
    width: 100vw;
    //top: 0;
    //left: 0;
    //right: 0;
    //bottom: 0;
}

.flex-center {
    display: flex !important;
    align-items: center !important;
}

.section-row-hover {
    .section-options {
        visibility: hidden;
    }

    &:hover {
        .section-options {
            visibility: visible;
        }
    }
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.icon-pulse-wrapper {
    border-radius: 9999px;
    min-width: 36px;
    min-height: 36px;
    position: relative;
    align-self: center;
    display: block;

    .icon-pulse {
        border-radius: 9999px;
        position: absolute;
        width: 100%;
        height: 100%;

        &.has-animation {
            animation: 2s ease 0s infinite normal none running animation-le125d;
        }
    }

    svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .icon-pulse-caret {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-width: 0px 5px 5px;
        border-style: solid;
        border-left-color: transparent;
        border-right-color: transparent;
        border-top-color: $dark;
        border-bottom-color: $dark;
    }

    &.icon-muted {
        background: $secondary;

        .icon-pulse {
            background: $secondary;
        }
    }

    &.icon-success {
        background: #3BD671;

        .icon-pulse {
            background: #3BD671;
        }
    }

    &.icon-invalid {
        background: #f42c2c;

        .icon-pulse {
            background: #f42c2c;
        }
    }
}

@keyframes animation-le125d {
    0% {
        opacity: 0.5;
    }
    70% {
        opacity: 0;
        transform: scale(1.6);
    }
    100% {
        opacity: 0;
    }
}

.last-result-bars {
    > div {
        width: 6px;
        margin-right: 3px;
        border-radius: 999px;
        line-height: 18px;

        &:hover {
            cursor: pointer;
        }
    }

    .bg-success {
        background-color: #3BD671 !important;

        &:hover {
            background-color: #2c7d53 !important;
        }
    }

    .bg-light-gray {
        background-color: #CBD5E0 !important;

        &:hover {
            background-color: #A0AEC0 !important;
        }
    }
}
