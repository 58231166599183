// Transform and transitions
.default-transition {
    -webkit-transition: all @transition-timer @transition-effect;
    -moz-transition: all @transition-timer @transition-effect;
    -ms-transition: all @transition-timer @transition-effect;
    -o-transition: all @transition-timer @transition-effect;
    transition: all @transition-timer @transition-effect;
}

// Colors
.color-text-and-icon(@color) {
    &, &[uk-icon], &.uk-text-color-inherit p {
        color: @color!important;
    }
}