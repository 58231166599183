.fixed-link {
    display: inline-block;
    padding-left: 3px;
    padding-right: 3px;
    height: 20px;
    background-color: @theme-color;
    border: 1px solid #eeeeee;
    color: @primary-color;
    text-align: center;
    text-decoration: underline;
    &.deleted {
        color: @danger-color
    }
}