.custom-control {
    z-index: unset;
}

.custom-switch {
    .custom-control-input:checked ~ .custom-control-label::before {
        text-align: left;
        font-weight: 400;
    }

    .custom-control-label {
        &::before {
            font-size: 10px;
            color: $darkBlueAlt;
            text-align: right;
            padding-left: 5px;
            padding-right: 11px;
            font-weight: 600;
        }
    }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
    content: attr(dataon);
}

.custom-switch .custom-control-label::before {
    content: attr(dataoff);
}

.custom-control-input:not(:disabled):not(:checked):hover ~ .custom-control-label::before {
    border-color: $custom-control-indicator-hover-border-color;
}

.login-form {
    .form-control {
        border-width: 2px;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        height: 46px;
    }

    label {
        font-size: 0.75rem;
        color: $darkBlueAlt;
        font-weight: bold;
        margin-bottom: 0;
        text-transform: uppercase;
        line-height: 1.1;
    }
}

.or-seperator {
    text-align: center;
    border-top: 1px solid #e0e0e0;

    strong {
        text-transform: uppercase;
        width: 40px;
        height: 40px;
        font-size: 14px;
        text-align: center;
        line-height: 40px;
        background: #fff;
        display: inline-block;
        border: 1px solid #e0e0e0;
        border-radius: 50%;
        position: relative;
        top: -22px;
        z-index: 1;
    }
}

.ipid-import-template-filters {
    label {
        min-width: 140px;
    }
}
