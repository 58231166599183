.dropdown-item {
    letter-spacing: normal;

    * {
        letter-spacing: normal;
    }

    &.active,
    &:active {
        background-color: $dropdown-bg;

        &:hover {
            @include gradient-bg($dropdown-link-hover-bg);
        }
    }

    &.text-danger.disabled {
        color: $dropdown-link-disabled-color !important;
    }
}

.history-dropdown {
    .dropdown-item.active {
        background-color: #c9dcf1;

        .small {
            color: #7e96aa !important;
        }
    }
}

.history-toggle.dropdown-toggle {
    background-color: #f7fbff;
    padding: 0.5rem 0.75rem;
    color: $primary;
    font-size: $font-size-base;
    cursor: pointer;
    border-radius: 6px;

    &:hover {
        background-color: #eef6ff;
    }

    &::after {
        vertical-align: 0.1em;
    }
}

.dropdown-hover {
    color: $bgDense;

    svg {
        font-size: $font-size-lg;
    }

    &:hover,
    &.show {
        color: $primary;
    }
}

.dropdown-menu,
.dropdown-menu .dropdown-item {
    color: $darkBlueAlt !important;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: $midBlueAlt !important;
    pointer-events: none;
    background-color: transparent;

    .text-danger {
        color: $muted !important;
    }
}

.avatar.dropdown {
    .dropdown-item.disabled {
        .current {
            font-weight: $font-weight-bold;
            color: $darkBlueAlt !important;
        }
    }
}

.dropdown {
    .more-actions-dropdown-toggle {
        padding: 5px 7px;
        border-radius: 3px;

        &:hover {
            color: $darkBlue;
            background-color: $lightBlueMid;
        }
    }

    &.show {
        .more-actions-dropdown-toggle {
            color: $darkBlue;
            background-color: $lightBlueMid;
        }
    }
}
